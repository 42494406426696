<app-rtcgrid *ngIf="show === 'grid'"
	(fullscreenEvent)="fullscreenStatus($event)"
	[showControls]="showControls" [settingsOverlay]="settingsOverlay">
</app-rtcgrid>

<app-rtcstripe *ngIf="show === 'stripe'"
	(fullscreenEvent)="fullscreenStatus($event)" [rtcButtons]="rtcButtons"
	[showControls]="showControls" [settingsOverlay]="settingsOverlay">
</app-rtcstripe>

<app-rtcuni *ngIf="show === 'uni'"
	(fullscreenEvent)="fullscreenStatus($event)"
	[showControls]="showControls" [settingsOverlay]="settingsOverlay"></app-rtcuni>