import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoUtil, UserLoginService } from './cognito.service';
import { ConfigService } from './config.service';
import { Key } from './key.service';
import { SystemBusService, MessageObserver } from './system-bus.service';
import { CryptoService } from './crypto.service';

/**
 * Created by Michael Stapleton
 */

declare var AWSIoTData: any;

export interface SocketListenerCallback {
    socketListenCallback(socket: Socket): void;
}

export interface SocketListener {
    socketOnRecieve(data: string, socket: Socket): void;
    socketRemotelyClosed(): void;
    socketClosed(): void;
}

export interface BroadcastListenerCallback {
    broadcastListenCallback(socket: BroadcastSocket): void;
}

export interface BroadcastListener {
    broadcastOnRecieve(data: any, socket: BroadcastSocket): void;
}

export interface BroadcastSocket {
    addListener(listener: BroadcastListener): void;
    removeListener(listener: BroadcastListener): void;
    onRecieve(data: any): void;
    send(data: any): void;
    getDestination(): string;
    ignore(): void;
}

export class BroadcastSocketImpl implements BroadcastSocket {

    private listeners: BroadcastListener[] = [];
    private ignoring = false;

    constructor(private serviceName: string, private srcID: string, private ourAddress: string, private service: NetworkService) {
    }

    getDestination(): string {
        return this.srcID;
    }
    ignore(): void {
        this.ignoring = true;
    }

    public addListener(listener: BroadcastListener): void {
        this.listeners.push(listener);
    }

    public removeListener(listener: BroadcastListener): void {
        const index = this.listeners.indexOf(listener);
        if (index !== -1) {
            console.log('Iot: Socket: removeListener: removing listener ');
            this.listeners.splice(index);
            if (this.listeners.length = 0) {
                console.log('Iot: Socket: removeListener: No more Listners');
            }
        }
    }

    public send(data: any): void {
        const payload = {
            data: data,
            srcID: this.ourAddress,
            svcName: this.serviceName
        }
        this.service.broadcast(JSON.stringify(payload), this.srcID);
    }

    public onRecieve(data: any) {
        if (!this.ignoring) {
            const message = data.data;
            if (this.listeners.length > 0) {
                try {
                    this.listeners.forEach((listener) => { listener.broadcastOnRecieve(message, this) });
                } catch (err) {
                    console.log('Error processing socket payload ', message)
                    console.log(err)
                }
            } else {
                console.error('Iot: Socket:  onRecieve: Warning: no listener on socket');
            }
        } else {
            console.warn('Ingoring', this);
        }
    }
}

export interface Socket {
    connectionID: string;
    srcID: string;
    send(data: any): void;
    connect(): void;
    getHeader(): string;
    setHeader(header: string): void;
    close(): void;
    getDestination(): string;
    getServiceName(): string;
    setTimeout(ms: number): void;
    onRecieve(payload: any): void;
    addListener(listener: SocketListener): void;
    removeListener(listener: SocketListener): void;
    accept(): void;
    ignore(): void;
}

export class SocketImpl implements Socket {
    lastPacketReceivedTime: number;
    private initiator = false;
    private accepted: boolean;
    private ignoring = false;
    private sendQueue: any[] = [];
    private outOfOrderMessages: any[] = [];
    private expectedMn = 1;
    private lastPacketSendTime = Date.now();
    private pingTimer: any;
    private TIMEOUT = 30000;
    private messageNumber = 0;
    connectionID: string;
    srcID: string;
    listeners: SocketListener[] = [];
    pingStarted = false;
    private closed = false;
    private header: string;
    constructor(public svcName: string, public destID: string, srcID: string, public service: NetworkService, header: string) {
        if (!srcID) {
            console.error('SocketImpl: new: srcID is undefined', this);
        }
        if (!destID) {
            console.error('SocketImpl: new: destID is undefined', this);
        }
        this.connectionID = uuid();
        this.srcID = srcID;
        console.log('NetworkService: Socket: new ' + svcName + ' socket to ' + destID + ' from ' + srcID);
        this.service.srcIDs.add(srcID);
        if (header) {
            this.header = header;
        }
    }


    /**
     * Called when we are receiving a new connection
     */
    init(payload: any, service: NetworkService) {
        console.log('NetworkSvc: SocketImpl: init', this);
        this.connectionID = payload.connectionID;
        this.svcName = payload.svcName;
        this.destID = payload.srcID; // swap ids for our side
        this.srcID = payload.destID; // swap ids for our side
        this.service = service;
        this.TIMEOUT = payload.timeout;
        this.header = payload.header;
        if (!payload.hello) {
            console.error('NetworkService: SocketImpl: init: inital packet not hello', payload);
            this.ignore();
        }

        if (!this.srcID) {
            console.error('SocketImpl: new: srcID is undefined', this);
        }
    }

    public setTimeout(ms: number) {
        this.TIMEOUT = ms;
    }

    private processPing() {
        if (!this.pingStarted) {
            this.startTimer();
        } else {
            this.lastPacketReceivedTime = Date.now();
        }
    }

    private ping() {
        // check if it has been too long since receiving last ping
        const now = Date.now();
        if (!this.accepted) {
            if (this.initiator) {
                this.closedRemotely();
                return;
            } else {
                this.ignore();
                return;
            }
        }
        if (now - this.lastPacketReceivedTime > this.TIMEOUT * 2) {
            console.error('Socket timeout');
            this.closedRemotely();
            return;
        }

        let next = (this.lastPacketSendTime + this.TIMEOUT) - now;

        if (next < 5000) {
            const payload = {
                connectionID: this.connectionID,
                mn: this.messageNumber++,
                svcName: this.svcName,
                destID: this.destID,
                srcID: this.srcID,
                data: 'ping',
                ping: true
            };
            this.lastPacketSendTime = Date.now();
            next = (this.lastPacketSendTime + this.TIMEOUT) - now;
            this.service.send(JSON.stringify(payload), this.destID);
        }
        this.pingTimer = setTimeout(() => this.ping(), next);
    }

    public getHeader() {
        return this.header;
    }

    public setHeader(header: string) {
        this.header = header;
    }

    public connect() {
        this.initiator = true
        console.log('NetworkSvc: SocketImpl: connect: ', this.header);
        const payload = {
            connectionID: this.connectionID,
            mn: this.messageNumber++,
            svcName: this.svcName,
            destID: this.destID,
            srcID: this.srcID,
            header: this.header,
            data: 'hello',
            hello: true,
            timeout: this.TIMEOUT
        };
        // console.log('NetworkSvc: SocketImpl:  payload ', payload);
        this.service.send(JSON.stringify(payload), this.destID);
        this.startTimer();
    }

    public async send(data: any) {
        if (!this.accepted) {
            // console.log('NetworkSvc: SocketImpl: send: not ready to send packet queuing message ', this);
            this.sendQueue.push(data);
        } else {
            this.localSend(data);
        }
    }

    private localSend(data: any) {
        //  console.log('NetworkSvc: SocketImpl:  localSend:', data);
        if (!this.closed) {
            const payload = {
                connectionID: this.connectionID,
                mn: this.messageNumber++,
                svcName: this.svcName,
                destID: this.destID,
                srcID: this.srcID,
                data: data
            };
            this.service.send(JSON.stringify(payload), this.destID);
            this.lastPacketSendTime = Date.now();
        } else {
            console.warn('NetworkSvc: SocketImpl:  send: socket closed, no send', this);
        }
    }

    private startTimer() {
        if (!this.pingStarted) {
            this.pingStarted = true;
            console.log('Ping interval ' + this.TIMEOUT);
            this.pingTimer = setTimeout(() => { this.ping() }, this.TIMEOUT);
        }
    }

    public accept() {
        // console.log('NetworkSvc: SocketImpl:  accept:', this)

        const payload = {
            connectionID: this.connectionID,
            mn: this.messageNumber++,
            svcName: this.svcName,
            destID: this.destID,
            srcID: this.srcID,
            accept: true
        };
        this.service.send(JSON.stringify(payload), this.destID);
        setTimeout(() => { this.accepted = true; this.sendQueuedMessages() }, 200);
        this.startTimer();
    }

    private sendQueuedMessages() {
        if (this.sendQueue.length > 0) {
            for (let data of this.sendQueue) {
                this.localSend(data)
            }
            this.sendQueue.length = 0;
        }
    }

    public ignore() {
        console.log('NetworkSvc: SocketImpl: : ignoring request');
        this.ignoring = true;
        this.closed = true;
        if (this.pingTimer) {
            clearInterval(this.pingTimer);
        }
    }

    private processMessage(message: any) {
        if (this.closed === true) {
            console.log('NetworkSvc: SocketImpl: processMessage: received packet after close \n' + JSON.stringify(message));
            return;
        }
        if (message.hello === true) {
            console.log('NetworkSvc: SocketImpl: processMessage:  got hello');
            return;
        }
        if (message.ping === true) {
            // console.log('NetworkSvc: SocketImpl: processMessage:  received ping');
            this.processPing();
            return;
        }
        if (message.close === true) {
            console.log('NetworkSvc: SocketImpl: processMessage: closed remotely');
            this.closedRemotely();
            return;
        }

        const data = message.data;
        if (!closed) {
            if (this.listeners.length > 0) {
                try {
                    this.listeners.forEach((listener) => { listener.socketOnRecieve(data, this) });
                } catch (err) {
                    console.error('NetworkSvc: SocketImpl: processMessage: Error processing socket payload ', message)
                    console.error(err)
                }
            } else {
                console.error('NetworkSvc: SocketImpl: processMessage: Warning: no listener on socket');
            }
        } else {
            console.log('NetworkSvc: SocketImpl: processMessage:  recieved packet after close');
        }
    }

    onRecieve(message: any) {
        this.lastPacketReceivedTime = Date.now();
        if (this.ignoring === true) {
            console.warn('NetworkSvc: SocketImp: onRecieve: Ignoring socket message ', message);
            return;
        } else if (message.accept === true) {
            console.log(' socket Accepted ', this);
            this.accepted = true;
            setTimeout(() => this.sendQueuedMessages(), 200);
            return;
        }

        let mn = message.mn;
        if (mn === this.expectedMn) {
            this.expectedMn = mn + 1;
            this.processMessage(message);
            this.checkMessageQueue();
        } else if (mn < this.expectedMn) {
            if (mn === 0 && message.close === true && !this.closed) {
                console.error('UN expected mn ', message);
                this.closedRemotely();
                return;
            } else {
                console.error('Got old message ', message);
            }
            //  this.processMessage(message);
            // this.checkMessageQueue();
        } else {
            this.outOfOrderMessages.push(message);
        }
        if (!this.accepted) {
            console.error('NetworkSvc: SocketImp: onRecieve: Not accpeted yet!!');
        }
    }

    private checkMessageQueue() {
        if (this.outOfOrderMessages.length > 0) {
            let idx = 0;
            for (let message of this.outOfOrderMessages) {
                if (message.mn === this.expectedMn) {
                    this.outOfOrderMessages.splice(idx, 1);
                    this.onRecieve(message);
                    break;
                }
                idx++;
            }
        }
    }

    addListener(listener: SocketListener) {
        this.listeners.push(listener);
    }

    removeListener(listener: SocketListener) {
        const index = this.listeners.indexOf(listener);
        if (index !== -1) {
            this.listeners.splice(index);
            if (this.listeners.length = 0) {
            }
        }
    }

    close() {
        if (!closed) {
            this.closed = true;
            this.service.close(this);

            if (!this.accepted) {
                console.log('NetworkSvc: SocketImp: close: Socket was not accepted', this);
            }
            const payload = {
                connectionID: this.connectionID,
                mn: this.messageNumber++,
                svcName: this.svcName,
                destID: this.destID,
                srcID: this.srcID,
                close: true
            };
            this.service.send(JSON.stringify(payload), this.destID);
            this.listeners.forEach((listener) => { listener.socketClosed() });
            if (this.pingTimer) {
                clearInterval(this.pingTimer);
            }
        }
    }

    closedRemotely() {
        if (!this.closed) {
            if (this.pingTimer) {
                clearInterval(this.pingTimer);
            }
            if (!this.accepted) {
                console.error('Socket was not accepted', this);
            }
            this.closed = true;
            this.service.close(this);
            this.listeners.forEach((listener) => { listener.socketRemotelyClosed() });
        }
    }

    getDestination(): string {
        return this.destID;
    }

    getServiceName(): string {
        return this.svcName;
    }
}

export function uuid() {
    let i, random;
    let result = '';

    for (i = 0; i < 32; i++) {
        random = Math.random() * 16 | 0;
        if (i === 8 || i === 12 || i === 16 || i === 20) {
            result += '-';
        }
        result += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random))
            .toString(16);
    }
    return result;
};

@Injectable()
export class NetworkService implements MessageObserver {

    public static mqttClient: any;
    public static connected = false;
    private static starting = false;
    private static trustedAddresses: string[] = [];
    public static keys: Key[] = [];
    public static keyCodes: string[] = [];
    private static blockList: string[] = [];
    public IOT_ENDPOINT = this.appConfig.iotEndpoint;
    public address: string;
    public srcIDs = new Set();
    public destroyed: boolean;
    private retryCount = -1;
    public online = true;
    public networkOnline = true;

    private sockets: { [socketID: string]: Socket } = {};
    private broadcastSockets: { [serviceNamesrcID: string]: BroadcastSocket } = {};
    private socketListeners: { [serviceName: string]: SocketListenerCallback } = {};
    private broadcastListeners: { [serviceName: string]: BroadcastListenerCallback } = {};
    private refreshingCreds: boolean;
    private helloQueue: any[] = [];
    private isReady = false;
    private storedMessages: any[] = [];

    constructor(public appConfig: ConfigService, public systemBus: SystemBusService, public router: Router, public cognitoUtil: CognitoUtil,
        public userService: UserLoginService, private cryptoSvc: CryptoService) {
        // console.log('NetworkSvc: init');
        this.systemBus.subscribe(this);
        //  this.userService.onceAuthenticated(this);
        window.addEventListener('offline', (e) => { console.log(e); this.setOnlineState(false) });
        window.addEventListener('online', (e) => { console.log(e); this.setOnlineState(true) });
    }

    private setOnlineState(isOnline: boolean) {
        console.log('isOnline ' + isOnline);
        this.networkOnline = isOnline;
        const msg = isOnline ? 'network/online ' : 'network/offline';
        this.systemBus.emit(isOnline, msg);
    }

    public addTrustedAddress(address: string): any {
        if (NetworkService.trustedAddresses.indexOf(address) === -1) {
            NetworkService.trustedAddresses.push(address);
        }
    }

    public addTrustedAddresses(addresses: string[]) {
        if (addresses && addresses.length > 0) {
            addresses.forEach((address: string) => {
                if (NetworkService.trustedAddresses.indexOf(address) === -1) {
                    NetworkService.trustedAddresses.push(address);
                }
            });
            this.cryptoSvc.addTrustedAddresses(addresses);
        }
    }

    public async addListenerKey(key: Key, presenceOnly: boolean) {
        key.presenceOnly = presenceOnly;
        if (NetworkService.keys.indexOf(key) < 0) {
            let now = Date.now();
            let keyDate = +key.expiryDate;
            let timeout = keyDate - now;
            if (now < key.expiryDate) {
                // set timer to remove connection when it times out
                if (timeout < 2147483648) {
                    setTimeout(() => { this.removeListenerKeyCode(key) }, timeout);
                }
                if (NetworkService.mqttClient != null) {
                    NetworkService.mqttClient.subscribe('criticall/' + key.keyCode + '/presence');
                    if (!presenceOnly) {
                        this.srcIDs.add(key.keyCode);
                        NetworkService.mqttClient.subscribe('criticall/' + key.keyCode + '/socket');
                        NetworkService.mqttClient.subscribe('criticall/' + key.keyCode + '/notice');
                        NetworkService.mqttClient.subscribe('criticall/' + key.keyCode + '/broadcast');
                    }
                }
                NetworkService.keys.push(key);
                NetworkService.keyCodes.push(key.keyCode);
            } else {
                console.warn('NetworkService: addListenerKey: ignoring expired key ', key);
            }
        }
    }

    public async removeListenerKeyCode(key: Key) {
        // TODO remove interval timer
        if (this.destroyed || !NetworkService.mqttClient) {
            return;
        }

        let idx = NetworkService.keys.map((e: Key) => { return e.keyCode; }).indexOf(key.keyCode);
        if (idx !== -1) {
            NetworkService.keys.splice(idx, 1);
            NetworkService.keyCodes.splice(NetworkService.keyCodes.indexOf(key.keyCode), 1);
        }

        NetworkService.mqttClient.unsubscribe('criticall/' + key.keyCode + '/socket');
        NetworkService.mqttClient.unsubscribe('criticall/' + key.keyCode + '/presence');
        NetworkService.mqttClient.unsubscribe('criticall/' + key.keyCode + '/notice');
        NetworkService.mqttClient.unsubscribe('criticall/' + key.keyCode + '/broadcast');
    }

    isConnected(): boolean {
        if (this.destroyed) {
            return false;
        }
        return NetworkService.connected;
    }

    async onBusMessage(message: any, type: string) {


        if (this.destroyed) {
            return;
        }
        if (type === 'contacts/gotlocalContact') {
            let contact = message;
            this.address = contact.destAddress;
            console.error('Starting newtork with address ' + this.address);
            this.start();
        } else if (type === 'user/loggedOut') {
            console.log('Logged out');
            this.destroy();
        } else if (type === 'user/loggedIn' && !NetworkService.mqttClient) {

        }

    }

    busMessageFilter(messageType: string): boolean {
        if (messageType.startsWith('user') || messageType === 'contacts/gotlocalContact') {
            return true;
        } else {
            return false;
        }
    };

    destroy() {
        this.destroyed = true;
        try {
            if (NetworkService.mqttClient != null) {
                NetworkService.mqttClient.end();
                NetworkService.mqttClient = null;
            }
        } catch (error) {
            console.error('NetworkSvc: destroy: ', error);
        }
        NetworkService.connected = false;
    }

    async start() {
        console.error('NetworkSvc: start: entered');
        if (NetworkService.connected || NetworkService.starting || this.destroyed) {
            return;
        }
        NetworkService.starting = true;
        if (NetworkService.mqttClient) {
            await this.refreshSecurityTokens();
            console.error('NetworkSvc: start: Connected');
            NetworkService.connected = true;
            NetworkService.starting = false;
            return;
        }
        const self = this;
        const credentials = this.cognitoUtil.getCredentials();
        if (credentials) {
            console.error('NetworkSvc: start: have Cedentials');
            let keyCode = this.userService.getKeyCode();
            let clientID: string;
            if (keyCode) {
                clientID = 'KEY:' + keyCode + ':' + credentials.identityId + '-' + Date.now();
            } else {
                clientID = this.address + '-' + Date.now();
            }
            console.error('NetworkSvc: start: creating mqttClient');
            NetworkService.mqttClient = AWSIoTData.device({
                region: this.appConfig.region,
                host: this.appConfig.iotEndpoint,
                clientId: clientID,
                protocol: 'wss',
                debug: true,
                accessKeyId: credentials.accessKeyId,
                secretKey: credentials.secretAccessKey,
                sessionToken: credentials.sessionToken
            });

            NetworkService.mqttClient.updateWebSocketCredentials(credentials.accessKeyId,
                credentials.secretAccessKey,
                credentials.sessionToken,
                credentials.expireTime);
            NetworkService.mqttClient.on('message', function (topic: any, rpayload: any) {
                try {
                    self.processMessage(topic, rpayload);
                } catch (err) {
                    console.error('IOT: Error processing message');
                    console.error(err);
                }
            });

            NetworkService.mqttClient.on('connect', () => {
                this.srcIDs.add(self.address);
                NetworkService.mqttClient.subscribe('criticall/' + self.address + '/socket');
                NetworkService.mqttClient.subscribe('criticall/' + self.address + '/presence');
                NetworkService.mqttClient.subscribe('criticall/' + self.address + '/notice');
                NetworkService.mqttClient.subscribe('criticall/' + self.address + '/broadcast');
                let d = new Date();
                let now = d.getTime();
                for (let key of NetworkService.keys) {
                    if (key.expiryDate < now) {
                        let idx = NetworkService.keys.map((e: Key) => { return e.keyCode; }).indexOf(key.keyCode);
                        if (idx !== -1) {
                            NetworkService.keys.splice(idx, 1);
                            NetworkService.keyCodes.splice(NetworkService.keyCodes.indexOf(key.keyCode), 1);
                        }
                        continue;
                    }
                    NetworkService.mqttClient.subscribe('criticall/' + key.keyCode + '/presence');
                    if (!key.presenceOnly) {
                        this.srcIDs.add(key.keyCode);
                        NetworkService.mqttClient.subscribe('criticall/' + key.keyCode + '/socket');
                        NetworkService.mqttClient.subscribe('criticall/' + key.keyCode + '/notice');
                        NetworkService.mqttClient.subscribe('criticall/' + key.keyCode + '/broadcast');
                    }
                }
                self.systemBus.emit('IOT: connection connected', 'iot/connection/connected');
                self.online = true;
                if (this.helloQueue.length > 0) {
                    console.error('NetworkSvc: start: processing queue');
                    setTimeout(() => {
                        this.helloQueue.forEach((data) => {
                            this.publish('criticall/' +
                                data.address + '/presence', JSON.stringify(data.data), data.address, self.address)
                        });
                        this.helloQueue.length = 0;

                    }, 1000);
                }
            });

            NetworkService.mqttClient.on('reconnect', function () {
                console.log('NetworkSvc: mqttClient: reconnect')
                // self.userService.onceAuthenticated(self);
                self.systemBus.emit('IOT: connection reconnect', 'iot/connection/reconnected');
            });

            NetworkService.mqttClient.on('error', function (err: any) {
                console.log('NetworkSvc: mqttClient: error', err)
                self.systemBus.emit('IOT: connection error', 'iot/connection/error')
                console.error('IOT error');
            });

            NetworkService.mqttClient.on('close', function () {
                console.log('NetworkSvc: mqttClient: close');
                self.systemBus.emit('IOT: connection closed', 'iot/connection/closed');
                self.refreshSecurityTokens();
                self.online = false;
            });

            NetworkService.mqttClient.on('disconnect', function () {
                console.error('NetworkSvc: mqttClient: disconnect')
                // self.userService.onceAuthenticated(self);
                self.systemBus.emit('IOT: connection disconnect', 'iot/connection/disconnected');
            });

            NetworkService.mqttClient.on('offline', function () {
                console.error('NetworkSvc: mqttClient: offline')
                // self.userService.onceAuthenticated(self);
                self.systemBus.emit('IOT: connection offline', 'iot/connection/offline');
            });

            NetworkService.mqttClient.on('end', function () {
                console.error('NetworkSvc: mqttClient: end')
                // self.userService.onceAuthenticated(self);
                self.systemBus.emit('IOT: connection end', 'iot/connection/end');
            });
            NetworkService.connected = true;
        } else {
            NetworkService.connected = false;
            self.systemBus.emit('IOT: connection error', 'iot/connection/error');
            console.error('NetworkSvc: mqttClient: Error retrieving identity ');
        }
        NetworkService.starting = false;
    }

    private async refreshSecurityTokens() {
        console.log('NetworkSvc: refreshSecurityTokens');
        try {
            if (this.destroyed) {
                console.error('NetworkSvc: We are Destroyed!');
                return;
            }
            if (this.refreshingCreds) {
                return;
            }
            this.refreshingCreds = true;
            await this.cognitoUtil.refresh(true).catch((err) => { console.error('NetworkSvc: refreshSecurityTokens:', err) });
            const credentials = this.cognitoUtil.getCredentials();
            credentials.get((err: any) => {
                if (err) {
                    this.refreshingCreds = false;
                    console.error('NetworkSvc: error refreshing security tokens', err);
                    if (!(err + '').startsWith('TimeoutError')) {
                        console.error('NetworkSvc: redirecting to login ');
                        this.router.navigate(['/home/login']);
                    }
                } else if ( NetworkService.mqttClient) {
                    NetworkService.mqttClient.updateWebSocketCredentials(credentials.accessKeyId,
                        credentials.secretAccessKey,
                        credentials.sessionToken,
                        credentials.expireTime);
                    this.refreshingCreds = false;
                }
            });
        } catch (err) {
            console.error(err);
        }
    }

    /**
    * This method is to sign and encript if nessecary
    */
    private async publish(topic: string, message: string, destID: string, _srcID: string) {
        const options = {
            qos: 1
        };
        let body = await this.cryptoSvc.encryptData(destID, message);
        if (!body) {
            console.error('NetworsSvc: Publish: nothing to send');
            return;
        }
        let data = JSON.stringify(body);
        NetworkService.mqttClient.publish(topic, data, options);
    }

    private async publishClear(topic: string, message: string) {

        const options = {
            qos: 1
        };
        let body = {
            d: JSON.parse(message),
        }
        let data = JSON.stringify(body);
        NetworkService.mqttClient.publish(topic, data, options);
    }

    /**
     * This method is to checks signature and decript if nessecary
     */
    private async validateMessage(topic: string, rpayload: any) {
        let payload;
        try {
            payload = JSON.parse(rpayload);
        } catch (err) {
            console.error(rpayload.toString());
            return null;
        }
        if (payload && payload.d) {
            // clear text
            return payload.d;
        }
        if (topic.endsWith('notice') || topic.endsWith('presence')) {
            if (!payload || !payload.id) {
                return payload;
            }
        }
        let data = await this.cryptoSvc.decryptData(payload);
        if (!data) {
            return null;
        }
        let address = data.address;

        if (NetworkService.trustedAddresses.indexOf(address) === -1) {
            let keyCode: string = JSON.parse(data.clearText).keyCode;
            console.log('keycode and trusted codes =', keyCode, NetworkService.keyCodes);
            if (keyCode && NetworkService.keyCodes.indexOf(keyCode) !== -1) {
                console.log('Allowing address as trusted because of keycode')
            } else {
                console.error('NetworkServive: validateMessage: Address not trusted: ' + address);
            }
        }
        return JSON.parse(data.clearText);
    }

    public async ready(ready: boolean) {
        //  console.log('NetworkService: got ready ' + ready);
        this.isReady = ready;
        if (ready) {
            let messages = this.storedMessages;
            this.storedMessages = [];
            if (messages.length > 0) {
                //  console.log('NetworkService: ready: replaying received messages');
                messages.forEach((entry: any) => { this.processMessage(entry.topic, entry.payload) });
            }
        }
    }

    private storeMessage(topic: string, payload: string) {
        this.storedMessages.push({ topic: topic, payload: payload })
    }

    private async processMessage(topic: string, rpayload: any) {
        if (!this.isReady) {
            this.storeMessage(topic, rpayload);
            console.log('NetworkService: processMessage: Storing message until ready');
            return;

        }
        if (this.destroyed) {
            return;
        }
        const payload = await this.validateMessage(topic, rpayload);
        if (!payload) {
            console.error('Error decrypting message ', topic, rpayload);
            return;
        }
        //  console.log('NetworkService: processMessage: validateMessage returned ', payload);

        let addr = payload.address;
        if (!addr) {
            addr = payload.srcID;
        }
        const blocked = this.blocked(addr);
        if (blocked) {
            console.error('Blocked');
            return;
        }
        //  console.log('NetworkSvc: processMessage: topic=' + topic + ' pay ', payload);
        try {
            const f = topic.indexOf('/') + 1;
            const l = topic.lastIndexOf('/');
            const topicaddr = topic.substring(f, l);

            if (topic.endsWith('socket')) {
                try {
                    // check if we are getting our own message back, ignore if so.
                    const srcID = payload.srcID;
                    if (this.srcIDs.has(srcID)) {
                        return;
                    }
                    const connectionID = payload.connectionID;
                    let socket: Socket = this.sockets[connectionID];
                    if (socket) {
                        socket.onRecieve(payload);
                    } else {
                        if (payload.close || !payload.hello) {
                            return;
                        }
                        const serviceName = payload.svcName;
                        const listener = this.socketListeners[serviceName];

                        if (listener) {
                            socket = new SocketImpl(serviceName, payload.destID, topicaddr, this, null);
                            (<SocketImpl>socket).init(payload, this);
                            this.sockets[connectionID] = socket;
                            if (payload.mn !== 0) {
                                // Ignore this socket, we are getting it after it was already established at some point.
                                console.warn('NetworkService: ProcessMessage: ignoring socket', socket);
                            } else {
                                listener.socketListenCallback(socket);
                            }
                        } else {
                            if (!blocked) {
                                console.error('NetworkService: processMessage: No Listener for this message ' + JSON.stringify(payload));
                            } else {
                                console.warn('NetworkService: processMessage: Blocked ' + JSON.stringify(payload));
                            }
                        }
                    }
                } catch (err) {
                    console.error('IOT: processMessage: Error processing message: ', err);
                }

            } else if (topic.endsWith('presence')) {
                try {
                    const address = payload.address;
                    if (!address) {
                        console.error('payload presence address undefined: payload=', payload);
                    }
                    if (this.srcIDs.has(address)) {
                        if (payload.state === 'disconnected') {
                            console.warn('Reconnecting because we got a message that we are offline');
                            this.systemBus.emit(address, 'iot/presence/otherDisconnected');
                        }
                        return;
                    }
                    console.log('Recieved iot presence' + address, payload);
                    this.systemBus.emit(payload, 'iot/presence/' + address);
                } catch (error) {
                    console.error('IOT: processMessage:  Error processing presence mesage', error);
                }

            } else if (topic.endsWith('notice')) {
                const message = payload.message;
                const type = payload.type;
                console.log('Network: recieved notice ', message)
                this.systemBus.emit(message, 'notice/' + type);

            } else if (topic.endsWith('broadcast')) {
                const serviceName = payload.svcName;
                const srcID = payload.srcID;

                let broadcastSocket: BroadcastSocket = this.broadcastSockets[serviceName + srcID];
                //       console.log(serviceName, srcID, broadcastSocket);
                //       console.log(this.broadcastSockets);
                if (broadcastSocket != null) {
                    broadcastSocket.onRecieve(payload);
                } else {
                    const listener = this.broadcastListeners[serviceName];
                    if (listener != null) {
                        let socket = new BroadcastSocketImpl(serviceName, srcID, topicaddr, this);
                        this.broadcastSockets[serviceName + srcID] = socket;
                        listener.broadcastListenCallback(socket);
                        socket.onRecieve(payload);
                    } else {
                        console.error('IOT: processMessage: error: no broadcaster listener for ' + serviceName, this.broadcastListeners);
                    }
                }
            } else {
                console.error('IOT: processMessage: recieved on invalid topic ' + topic);
            }
        } finally {
            console.groupEnd();
        }
    }

    private blocked(address: string) {
        //  console.warn('check blocked ' + address);
        return NetworkService.blockList.indexOf(address) !== -1;
    }

    public block(address: string, block: boolean) {
        //  console.warn('blocked ' + address);
        if (block) {
            NetworkService.blockList.push(address);
        } else {
            const idx = NetworkService.blockList.indexOf(address);
            NetworkService.blockList.splice(idx, 1);
        }
    }

    public async send(data: any, address: string) {

        if (this.destroyed) {
            return;
        }

        if (!NetworkService.connected) {
            await this.refreshSecurityTokens();
        }
        if (address.startsWith('proxyTopic')) {
            this.publishClear('criticall/' + address + '/socket', data);
        } else {
            this.publish('criticall/' + address + '/socket', data, address, this.address);
        }
    }

    public async broadcast(data: any, address: string) {
        //   console.log('NetworkSvc: broadcast', data, address);
        if (this.destroyed) {
            return;
        }
        if (!NetworkService.connected) {
            await this.refreshSecurityTokens();
        }

        this.publish('criticall/' + address + '/broadcast', data, address, this.address);
    }

    public async sendHello(address: string, data: any) {
        // console.log('NetworkSvc: sendHello: to ' + address, data);
        if (this.destroyed) {
            return;
        }

        if (!NetworkService.mqttClient) {
            this.helloQueue.push({ 'address': address, 'data': data });
        } else {
            this.publish('criticall/' + address + '/presence', JSON.stringify(data), address, this.address);
        }
    }

    public connectBroadcast(destAddress: string, ourAddress: string, serviceName: string, listener: BroadcastListener) {
        if (this.destroyed) {
            return;
        }

        console.log('NetworkSvc: connectBroadcast: dest=' + destAddress + ' src=' + ourAddress);
        if (!ourAddress) {
            console.error(new Error());
        }
        const socket = new BroadcastSocketImpl(serviceName, destAddress, ourAddress, this);
        socket.addListener(listener);
        this.broadcastSockets[serviceName + destAddress] = socket;
        //  console.log(this.broadcastSockets);
        return socket;
    }

    public connect(destAddress: string, srcAddress: string, serviceName: string, header: string): Socket {
        console.log('NetworkSvc: connectRawt: dest=' + destAddress + ' src=' + srcAddress);
        if (this.destroyed) {
            return;
        }
        const socket = new SocketImpl(serviceName, destAddress, srcAddress, this, header);

        this.sockets[socket.connectionID] = socket;
        return socket;
    }

    public listen(serviceName: string, callback: SocketListenerCallback) {
        this.socketListeners[serviceName] = callback;
    }

    public listenBroadcast(serviceName: string, handler: BroadcastListenerCallback) {
        this.broadcastListeners[serviceName] = handler;
    }

    close(socket: Socket) {
        console.log('NetworkSvc: close', socket);
        const that = this;
        setTimeout(() => { that.sockets[socket.connectionID] = null }, 300000);
        // TODO Properly remove property or find a better Map....
    }
}

