import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserRegistrationService, CognitoCallback } from '../../../service/cognito.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslationService } from '../../../service/translation.service';

export class RegistrationUser {
  userID: string;
  name: string;
  email: string;
  password: string;
}
/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 */
@Component({
  selector: 'app-criticall',
  templateUrl: './registration.html',
  styleUrls: ['../../../../assets/css/custom.css']

})
export class RegisterComponent implements CognitoCallback {
  model: any = {};
  registrationUser: RegistrationUser;
  router: Router;
  errorMessage: string;

  constructor(public userRegistration: UserRegistrationService,
    router: Router, private spinner: NgxSpinnerService, public tr: TranslationService) {
    this.router = router;
    this.onInit();
  }

  onInit() {
    this.registrationUser = new RegistrationUser();
  }

  onSubmit() {
    this.spinner.show();
    this.errorMessage = null;
    this.registrationUser.userID = this.model.userID.toLowerCase();
    this.registrationUser.name = this.model.displayName;
    this.registrationUser.email = this.model.email.toLowerCase();
    this.registrationUser.password = this.model.password;
    this.userRegistration.register(this.registrationUser, this);
  }

  cognitoCallback(error: any, result: any) {
    this.spinner.hide();
    if (error) { // error
      if (error.code === 'UsernameExistsException') {
        this.model.userID = null;
        this.errorMessage = this.tr.get('register', 'userID.Exists');
      } else {
        this.errorMessage = error.message;
      }
      console.log('result: ', error);
    } else { // success
      // move to the next step
      console.log('redirecting ', result);
      this.router.navigate(['/home/confirmRegistration', result.user.username]);
    }
  }
}
