import { MessageObserver, SystemBusService } from '../../../service/system-bus.service';
import {
  Directive, ElementRef, EventEmitter,
  HostListener, OnInit, Output, OnDestroy
} from '@angular/core';
import intlTelInput from 'intl-tel-input';
// import * as $ from 'jquery';
import { ConfigService } from '../../../service/config.service';

@Directive({
  selector: '[appTelInput]',
})
export class TelInputDirective implements OnInit, OnDestroy, MessageObserver {

  private telInputOptions = {
    initialCountry: 'auto',
    utilsScript: 'assets/scripts/utils.js',
    geoIpLookup: (callback: any) => {
      let cc = this.config.getItem('country_code');
      const cfg = this.config;
      if (cc) {
        callback(cc);
      } else {
        $.ajax({
          url: 'https://geolocation-db.com/jsonp/',
          jsonpCallback: 'callback',
          dataType: 'jsonp',
          success: function (location) {
            let c = location.country_code;
            if (c) {
              cfg.setItem('country_code', c);
            } else {
              c = 'CA';
            }
            callback(c);
          }
        });
      }
    },
    preferredCountries: ['au', 'ca', 'us']
  };

  @Output() isValidNumber: EventEmitter<boolean> = new EventEmitter();
  @Output() telNumberOutput: EventEmitter<any> = new EventEmitter();
  @Output() countryChange: EventEmitter<any> = new EventEmitter();
  @Output() intlTelInputObject: EventEmitter<any> = new EventEmitter();
  ngTelInput: any;

  constructor(private el: ElementRef, private systemBus: SystemBusService, private config: ConfigService) {
    this.systemBus.subscribe(this);
  }

  ngOnDestroy(): void {
    this.systemBus.unSubscribe(this);
  }

  ngOnInit() {
    //  this.ngTelInput = $(this.el.nativeElement);
    //  this.ngTelInput.intlTelInput(this.telInputOptions);

    this.ngTelInput = intlTelInput(this.el.nativeElement, this.telInputOptions);
    this.el.nativeElement.addEventListener('countrychange', () => {
      let cn = this.ngTelInput.getSelectedCountryData().iso2
      this.countryChange.emit(cn);
    });
    this.intlTelInputObject.emit(this.ngTelInput);
  }

  @HostListener('input') onKeyup() {
    this.check();
  }

  private check() {
    let isInputValid: boolean = this.isInputValid();
    if (isInputValid) {
      let telOutput = this.ngTelInput.getNumber();
      this.isValidNumber.emit(isInputValid);
      this.telNumberOutput.emit(telOutput);
    } else {
      this.isValidNumber.emit(isInputValid);
    }
  }


  isInputValid(): boolean {
    return this.ngTelInput.isValidNumber() ? true : false;
  }

  setCountry(country: any) {
    console.log('SetCountry', country);
    this.ngTelInput.setCountry(country);
  }

  onBusMessage(contact: any, _type: string): void {
    let c = contact.mailingAddress.country;
    if (c) {
      this.setCountry(c);
    }
    this.systemBus.unSubscribe(this);
  }

  busMessageFilter(messageType: string): boolean {
    return messageType === 'contacts/gotlocalContact';
  }

}
