<p class="mt-1">{{tr.get("account-profile","country")}}</p>
<select [id]="'gds-cr-'+ id" (change)="setCountry($event.target.value)"
	class="crs-country form-control form-control-lg input-sm gds-cr"
	[attr.data-region-id]="'gds-region-'+ id"
	[attr.data-default-value]="_currentCountry" data-value="shortcode"
	style="margin-top: -15px; margin-bottom: 0px">
</select>

<p class="mt-1">{{tr.get("account-profile","region")}}</p>
<select (change)="setRegion($event.target.value)"
	class=" form-control form-control-lg input-sm" [id]="'gds-region-'+ id"
	data-value="shortcode" [attr.data-default-value]="_currentRegion"
	style="margin-top: -15px; margin-bottom: 0px"></select>