import { EqcallapiService } from '../../../../service/eqcallapi.service';
import { SystemBusService } from '../../../../service/system-bus.service';
import { Component, Input } from '@angular/core';
import { TranslationService } from '../../../../service/translation.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-billing',
    templateUrl: './billing.component.html',
    styleUrls: ['../../../../../assets/css/custom.css']
})
export class BillingComponent {
    public invoice: any;
    public invoices: any;
    public calllog: any;
    public showactivity = false;
    public showbilling = false;
    public showbills = false;
    private _show = 'NONE';
    public next = 'NONE';
    public thisMonth: string;
    @Input()
    set show(show: string) {
        this._show = show
        this.setShow();
    }

    get show(): string { return this._show; }
    constructor(private api: EqcallapiService, private systemBus: SystemBusService,
        public tr: TranslationService, private spinner: NgxSpinnerService) {
        const date = new Date()
        const month = ('0' + (date.getMonth() + 1)).slice(-2)
        const year = date.getFullYear()
        this.thisMonth = `${year}-${month}`;
    }

    private setShow() {
        let parts = this._show.split(',');
        let us = parts[0];
        if (parts.length > 1) {
            this.next = this._show.substring(this._show.indexOf(',') + 1);
            console.log('next=', this.next);
        }
        if (us === 'CALLLOG') {
            (<any>$('#collapseCalllog')).collapse('show');
        } else if (us === 'STATEMENT') {
            (<any>$('#collapseStatement')).collapse('show');
        } else if (us === 'HISTORY') {
            (<any>$('#collapseHistory')).collapse('show');
        }
    }

    public async toggleActivity() {
        if (!this.showactivity) {
            const ym = this.convertYearMonthString(this.thisMonth);
            await this.getCallLog(ym.year, ym.month);
        }
        this.showactivity = !this.showactivity;
    }

    public callLogMonthSet(yearMonth: string) {
        console.log('Year Month=' + yearMonth);
        const ym = this.convertYearMonthString(yearMonth);
        this.getCallLog(ym.year, ym.month);
    }

    private convertYearMonthString(yearMonth: string) {
        let ym = yearMonth.split('-');
        return { year: Number(ym[0]), month: Number(ym[1]) }
    }

    public async toggleBilling() {
        if (!this.showbilling) {
            await this.getInvoice();
        }
        this.showbilling = !this.showbilling;
    }

    public async toggleBills() {
        if (!this.showbills) {
            await this.getPastInvoices();
        }
        this.showbills = !this.showbills;
    }

    public async getInvoice() {
        this.spinner.show();
        try {
            await this.api.getInvoice().then((res) => {
                console.log(res.data);
                let i = JSON.parse(res.data);
                if (i) {
                    this.invoice = i;
                }
                console.log(this.invoice);
            }).catch(err => {
                console.error(err);
                const mesg = {
                    type: 'info',
                    message: 'Error getting invoice: ' + err.data,
                    timeOut: 15,
                };
                this.systemBus.emit(mesg, 'warning');

            });
        } finally {
            this.spinner.hide();
        }
    }

    public async getPastInvoices() {
        this.spinner.show();
        try {
            await this.api.getInvoices(10).then((res) => {
                let i = JSON.parse(res.data);
                if (i) {
                    this.invoices = i;
                }
                console.log(this.invoices)
            }).catch(err => {
                const mesg = {
                    type: 'info',
                    message: 'Error getting invoices: ' + err.data,
                    timeOut: 15,
                };
                this.systemBus.emit(mesg, 'warning');
            });
        } finally {
            this.spinner.hide();
        }
    }

    private async getCallLog(year: number, month: number) {
        this.spinner.show();
        try {
            await this.api.getCallLog(year, month).then((res) => {
                let i = res.data;
                console.log(i);
                if (i && i.length > 0) {
                    this.calllog = i;
                } else {
                    this.calllog = null;
                }
            }).catch(err => {
                console.error(err);
                const mesg = {
                    type: 'info',
                    message: 'Error getting call log: ' + err.data,
                    timeOut: 15,
                };
                this.systemBus.emit(mesg, 'warning');
            });
        } finally {
            this.spinner.hide();
        }
    }
}
