import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { ContactsService, Contact } from '../../../service/contacts.service';
import { SystemBusService, MessageObserver } from '../../../service/system-bus.service';
import { TranslationService } from '../../../service/translation.service';
import { MessageService, Messenger } from '../../../service/message.service';

@Component({
    selector: 'app-messenger-list',
    templateUrl: './messenger-list.component.html',
    styleUrls: ['../../../../assets/css/custom.css']
})
export class MessengerListComponent implements OnInit, OnDestroy, MessageObserver {

    @Input() inCall: boolean;
    public onlineContacts: Messenger[] = [];
    public offlineContacts: Messenger[] = [];
    public blockedContacts: Messenger[] = [];
    public contacts: Messenger[] = [];
    public groups: Messenger[] = [];
    private selectedContacts: Messenger[] = [];
    /**
     * 0 = all
     * 1 = online,
     * 2 = offline,
     * 3 = blocked
     * 4 = sort az,
     * 5 = sort activity
     * 6 = groups
     */
    public display = 0;
    public listLength = 0;
    public status = 'All';
    public lists: {
        all: Contact[]; online: Contact[]; offline: Contact[];
        blocked: Contact[]; sortedAZ: Contact[]; sortedActivity: Contact[];
    };

    constructor(private changeDetectorRef: ChangeDetectorRef, private contactsService: ContactsService, private busSvc: SystemBusService,
        public tr: TranslationService, private msgSvc: MessageService) {
        this.lists = this.contactsService.contactsList.getLists();
        this.onlineContacts = this.lists.online;
        this.offlineContacts = this.lists.offline;
        this.blockedContacts = this.lists.blocked;
        this.groups = this.msgSvc.groups;
        this.busSvc.subscribe(this);
        this.update();
    }

    ngOnInit() {
        this.update();
    }

    ngOnDestroy(): void {
        this.contacts = null

        this.busSvc.unSubscribe(this);
    }

    onBusMessage(message: any, type: string): void {
        if (type === 'contactDisplay') {
            this.display = message;
            this.update();
        } else if (type.startsWith('iot/presence/')) {
            console.log('MessengerList presence');
            setTimeout(() => { this.changeDetectorRef.detectChanges()}, 500);
            this.changeDetectorRef.detectChanges();
        } else {
            this.updateSelected(message, type.endsWith('true'));
        }
    }

    busMessageFilter(messageType: string): boolean {
        return messageType === 'contactDisplay' ||
            messageType === 'contactListContactIsSelected-true' ||
            messageType === 'contactListContactIsSelected-false' ||
            messageType.startsWith('iot/presence/');
    }

    private update() {
        const d = this.display;
        if (d === 1) {
            this.contacts = this.lists.online;
            this.status = 'Online';
            this.listLength = this.onlineContacts.length;
        } else if (d === 2) {
            this.contacts = this.lists.offline;
            this.status = 'Offline or Away';
            this.listLength = this.offlineContacts.length;
        } else if (d === 3) {
            this.contacts = this.lists.blocked;
            this.status = 'Blocked';
            this.listLength = this.blockedContacts.length;
        } else if (d === 4) {
            this.contacts = this.lists.sortedAZ;
            this.status = 'A-Z';
            this.listLength = this.lists.sortedAZ.length;
        } else if (d === 5) {
            this.contacts = this.lists.sortedActivity;
            this.status = 'Recent Actvity';
            this.listLength = this.lists.sortedActivity.length;
        } else if (d === 6) {
            this.contacts = this.groups;
            this.status = 'Groups';
            this.listLength = this.groups.length;
        } else if (d === 0) {
            this.status = 'All';
            this.listLength = (this.lists.all.length) + (this.groups.length);
        } else if (d !== 0) {
            console.error('unknown display number ' + d)
        }
    }

    /**
     * @param idx  Used to set contact list style in Contact-List component
     */
    public setDisplay(idx: number) {
        this.busSvc.emit(idx, 'contactDisplay');
        $('#checkbox-toggle-sort').prop('checked', false);
    }

    public createGroup() {
        let group = this.msgSvc.createGroup(this.selectedContacts);
        this.busSvc.emit(group, 'showGroupMembershipModal');
    }

    public updateSelected(contact: Messenger, isSelected: boolean) {
        console.log('Contact selected ', isSelected, contact);
        let currentIndex = this.hasContact(contact);

        if (isSelected) {
            if (currentIndex === -1) {
                this.selectedContacts.push(contact);
            }
        } else {
            if (currentIndex !== -1) {
                this.selectedContacts.splice(currentIndex, 1);
            }
        }
    }
    private hasContact(contact: Messenger): number {
        let addr = contact.destAddress
        let index = -1;
        for (let i = 0; i < this.selectedContacts.length; i++) {
            if (addr === this.selectedContacts[i].destAddress) {
                index = i;
                break;
            }
        }
        console.log('index = ' + index);
        return index
    }

    public dropClicked() {
        this.selectedContacts = [];
        this.busSvc.emit(this, 'contactList/Selection/Cleared');
    }
}
