import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SystemBusService } from './system-bus.service';

@Injectable({
    providedIn: 'root'
})
export class TranslationService {
    private language: string;
    private langObject: any;
    public supportedLanguages = [
        {
            'desc': 'Afrikaans',
            'lang': 'af'
        },
        {
            'desc': 'Azərbaycan',
            'lang': 'az'
        },
        {
            'desc': 'Bahasa Indonesia',
            'lang': 'id'
        },
        {
            'desc': 'Bosanski',
            'lang': 'bs'
        },
        {
            'desc': 'Català',
            'lang': 'ca'
        },
        {
            'desc': 'Cebuano',
            'lang': 'ceb'
        },
        {
            'desc': 'Chichewa',
            'lang': 'ny'
        },
        {
            'desc': 'Corsu',
            'lang': 'co'
        },
        {
            'desc': 'Cymraeg',
            'lang': 'cy'
        },
        {
            'desc': 'Dansk',
            'lang': 'da'
        },
        {
            'desc': 'Deutsche',
            'lang': 'de'
        },
        {
            'desc': 'Eesti Keel',
            'lang': 'et'
        },
        {
            'desc': 'English',
            'lang': 'en'
        },
        {
            'desc': 'Español',
            'lang': 'es'
        },
        {
            'desc': 'Esperanto',
            'lang': 'eo'
        },
        {
            'desc': 'Euskal',
            'lang': 'eu'
        },
        {
            'desc': 'Filipino',
            'lang': 'tl'
        },
        {
            'desc': 'Français',
            'lang': 'fr'
        },
        {
            'desc': 'Frysk',
            'lang': 'fy'
        },
        {
            'desc': 'Gaeilge',
            'lang': 'ga'
        },
        {
            'desc': 'Galego',
            'lang': 'gl'
        },
        {
            'desc': 'Gàidhlig Na H-Alba',
            'lang': 'gd'
        },
        {
            'desc': 'Hausa',
            'lang': 'ha'
        },
        {
            'desc': 'Hmoob',
            'lang': 'hmn'
        },
        {
            'desc': 'Hrvatski',
            'lang': 'hr'
        },
        {
            'desc': 'Igbo',
            'lang': 'ig'
        },
        {
            'desc': 'IsiXhosa',
            'lang': 'xh'
        },
        {
            'desc': 'Italiano',
            'lang': 'it'
        },
        {
            'desc': 'Jawa',
            'lang': 'jw'
        },
        {
            'desc': 'Kiswahili',
            'lang': 'sw'
        },
        {
            'desc': 'Kreyòl Ayisyen',
            'lang': 'ht'
        },
        {
            'desc': 'Kurdî (Kurmancî)',
            'lang': 'ku'
        },
        {
            'desc': 'Latine',
            'lang': 'la'
        },
        {
            'desc': 'Latviešu Valoda',
            'lang': 'lv'
        },
        {
            'desc': 'Lietuvių',
            'lang': 'lt'
        },
        {
            'desc': 'Lëtzebuergesch',
            'lang': 'lb'
        },
        {
            'desc': 'Magyar',
            'lang': 'hu'
        },
        {
            'desc': 'Malagasy',
            'lang': 'mg'
        },
        {
            'desc': 'Malti',
            'lang': 'mt'
        },
        {
            'desc': 'Maori',
            'lang': 'mi'
        },
        {
            'desc': 'Melayu',
            'lang': 'ms'
        },
        {
            'desc': 'Nederlands',
            'lang': 'nl'
        },
        {
            'desc': 'Norsk',
            'lang': 'no'
        },
        {
            'desc': 'O\'zbek',
            'lang': 'uz'
        },
        {
            'desc': 'Polskie',
            'lang': 'pl'
        },
        {
            'desc': 'Português',
            'lang': 'pt'
        },
        {
            'desc': 'Română',
            'lang': 'ro'
        },
        {
            'desc': 'Samoa',
            'lang': 'sm'
        },
        {
            'desc': 'Sesotho',
            'lang': 'st'
        },
        {
            'desc': 'Shona',
            'lang': 'sn'
        },
        {
            'desc': 'Shqiptar',
            'lang': 'sq'
        },
        {
            'desc': 'Slovenský',
            'lang': 'sk'
        },
        {
            'desc': 'Slovenšĝina',
            'lang': 'sl'
        },
        {
            'desc': 'Somali',
            'lang': 'so'
        },
        {
            'desc': 'Sunda',
            'lang': 'su'
        },
        {
            'desc': 'Suomalainen',
            'lang': 'fi'
        },
        {
            'desc': 'Svenska',
            'lang': 'sv'
        },
        {
            'desc': 'Tiếng Việt',
            'lang': 'vi'
        },
        {
            'desc': 'Türk',
            'lang': 'tr'
        },
        {
            'desc': 'Yiddish',
            'lang': 'yi'
        },
        {
            'desc': 'Yorùbá',
            'lang': 'yo'
        },
        {
            'desc': 'Zulu',
            'lang': 'zu'
        },
        {
            'desc': 'Ýslensku',
            'lang': 'is'
        },
        {
            'desc': 'Čeština',
            'lang': 'cs'
        },
        {
            'desc': 'Ōlelo Hawaiʻi',
            'lang': 'haw'
        },
        {
            'desc': 'Ελληνικά',
            'lang': 'el'
        },
        {
            'desc': 'Беларуѝкі',
            'lang': 'be'
        },
        {
            'desc': 'Българѝки',
            'lang': 'bg'
        },
        {
            'desc': 'Кыргызча',
            'lang': 'ky'
        },
        {
            'desc': 'Македонѝки',
            'lang': 'mk'
        },
        {
            'desc': 'Монгол Хѝл Дѝѝр',
            'lang': 'mn'
        },
        {
            'desc': 'Руѝѝкий',
            'lang': 'ru'
        },
        {
            'desc': 'Српѝки',
            'lang': 'sr'
        },
        {
            'desc': 'Тоҷикӣ',
            'lang': 'tg'
        },
        {
            'desc': 'Українѝька',
            'lang': 'uk'
        },
        {
            'desc': 'Қазақша',
            'lang': 'kk'
        },
        {
            'desc': 'Հայերեն',
            'lang': 'hy'
        },
        {
            'desc': 'עברית',
            'lang': 'iw'
        },
        {
            'desc': 'اردو',
            'lang': 'ur'
        },
        {
            'desc': 'سنڌي',
            'lang': 'sd'
        },
        {
            'desc': 'عربى',
            'lang': 'ar'
        },
        {
            'desc': 'ٝارسی',
            'lang': 'fa'
        },
        {
            'desc': 'پښتو',
            'lang': 'ps'
        },
        {
            'desc': 'नेपाली',
            'lang': 'ne'
        },
        {
            'desc': 'मराठी',
            'lang': 'mr'
        },
        {
            'desc': 'हिंदी',
            'lang': 'hi'
        },
        {
            'desc': 'বাঙালি',
            'lang': 'bn'
        },
        {
            'desc': 'ਪੰਜਾਬੀ',
            'lang': 'pa'
        },
        {
            'desc': 'ગ૝જરાતી',
            'lang': 'gu'
        },
        {
            'desc': 'தமிழ௝',
            'lang': 'ta'
        },
        {
            'desc': 'తెలౝగౝ',
            'lang': 'te'
        },
        {
            'desc': 'ಕನೝನಡ',
            'lang': 'kn'
        },
        {
            'desc': 'മലയാളം',
            'lang': 'ml'
        },
        {
            'desc': 'සිංහල',
            'lang': 'si'
        },
        {
            'desc': 'ไทย',
            'lang': 'th'
        },
        {
            'desc': 'ລາວ',
            'lang': 'lo'
        },
        {
            'desc': 'မြန်မာ (ဗမာ)',
            'lang': 'my'
        },
        {
            'desc': 'ქორთული',
            'lang': 'ka'
        },
        {
            'desc': 'አማርኛ',
            'lang': 'am'
        },
        {
            'desc': 'ភាសាឝ្មែរ',
            'lang': 'km'
        },
        {
            'desc': '中國傳統的）',
            'lang': 'zh-TW'
        },
        {
            'desc': '日本人',
            'lang': 'ja'
        },
        {
            'desc': '简体中文）',
            'lang': 'zh'
        },
        {
            'desc': '한국어',
            'lang': 'ko'
        }

    ];
    public selectedLanguage = 'en';
    config: any;

    constructor(private http: HttpClient, private busSvc: SystemBusService) {
        this.language = navigator.language;

        let selected: string = localStorage.getItem('language');
        if (!selected) {
            selected = this.language;
        }
    }

    public async init(config: any) {
        this.config = config;
        if (!this.selectedLanguage) {
            let selected: string = config.getItem('language');
            if (!selected) {
                selected = this.language;
            }
            this.setLang(selected);
        }

        this.langObject = await this.http.get('./assets/locale/' + this.selectedLanguage + '.json', { responseType: 'json' }).toPromise();
    }

    public get(module: string, key: string) {
        if (this.langObject[module]) {
            let value = this.langObject[module][key];
            if (!value) {
                value = module + '.' + key + ':missing';
            }
            return this.langObject[module][key];
        } else {
            return module + '-' + key + ':missing';
        }
    }

    private setLang(lang: string) {
        if (this.hasLang(lang) === true) {
            this.selectedLanguage = lang;
        } else {
            let idx = lang.indexOf('-');
            if (idx !== -1) {
                let top = lang.slice(0, idx);
                console.log('Top language ', top);
                if (this.hasLang(top) === true) {
                    this.selectedLanguage = top;
                }
            }
        }
    }

    private hasLang(lang: string): boolean {
        let ret = false;
        this.supportedLanguages.forEach((element) => { if (element.lang === lang) { ret = true } });
        return ret;
    }

    public setLanguage(lang: string) {

        this.setLang(lang);
        if (this.config) {
            this.config.setItem('language', this.selectedLanguage);
        } else {
            localStorage.setItem('language', this.selectedLanguage);
        }
        this.init(this.config);
        this.busSvc.emit(lang, 'language/changed');
    }
}
