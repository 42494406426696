<!-- Begin eqCall App -->
<div class="app">
	<header class="app-header">
		<div id="collapseLogout" class="panel-collapse"
			[ngClass]="{'collapse':selectedSideBar!=1}" aria-expanded="true">
			<div class="d-flex align-items-center justify-content-end mod">
				<a href="https://eqcall.com"
					class="navbar-brand d-none d-sm-block ml-5 mt-2 pl-3"> <img
					src="./assets/img/logo-light.png" alt="eqCall-logo">
				</a>
				<div class="d-flex align-items-center">
					<div class="p-2 d-none d-inline mt-3">
						<app-language> </app-language>
					</div>
					<div class="p-2 d-none d-inline mb-1" *ngFor="let u of user">
						<a href="" data-toggle="modal" data-target="#logoutModal"
							id="logout" class="nav-link">Logout, {{ u.nickname }}</a>
					</div>
				</div>
			</div>
			<!-- Header Main Page Wrapper -->
			<div class="header-main">
				<div class="header-main-inner" *ngFor="let u of user">
					<h1 *ngIf="!inCall">Hello {{ u.nickname }}</h1>
					<h1 *ngIf="inCall" style="color: #e74c3c">
						You are in Call<span class="d">.</span><span class="d d-2">.</span><span
							class="d d-3">.</span>
					</h1>
					<div class="d-flex justify-content-center mt-3">
						<button *ngIf="!inCall"
							class="btn-rtc btn-outline-light btn-lg header-right-menu p-2 mr-2"
							style="border: 1px solid #fff" (click)="buttonClicked($event,2)">
							<span [ngClass]="{'blink_contacts': blinkContacts === true }">Contacts</span>
						</button>
						<button *ngIf="inCall"
							class="btn-rtc btn-outline-light btn-lg header-right-menu p-2 mr-2"
							style="border: 1px solid #e74c3c"
							(click)="buttonClicked($event,1)">
							<span [ngClass]="{'blink_contacts': blinkContacts === true }">Go
								to call</span>
						</button>
						<button
							class="btn-rtc btn-outline-light btn-lg header-right-menu p-2 mr-2"
							style="border: 1px solid #fff" (click)="buttonClicked($event,4)">Settings</button>
						<button
							class="btn-rtc btn-outline-light btn-lg header-right-menu p-2 mr-2"
							style="border: 1px solid #fff" (click)="buttonClicked($event,5)">Account</button>
					</div>
				</div>
			</div>
			<!-- Skip Option -->
			<div class="skip-menu">
				<div class="d-flex flex-row-reverse">
					<div class="p-2 ">
						<a href="" data-toggle="modal" data-target="#homeModal"
							id="collapseHomeOptions">Home Screen Options</a>
					</div>
					<div class="p-2 mr-auto">
						<span *ngIf="isOnline" style="color: #2ecc71">Online</span><span
							*ngIf="isAway" style="color: #f39c12">Away</span><span
							*ngIf="isDND" class="blink_me" style="color: #e74c3c">Don't
							Disturb</span><span *ngIf="isOffline">Offline</span> | Balance:
						{{user[0] ? user[0].twilioBalance : 0 | currency}}
					</div>
				</div>
			</div>
			<!-- Utilites Menu -->
			<div class="utilities-menu">
				<button class="btn-rtc btn-outline-light btn-lg header-right-menu"
					data-toggle="modal" data-target="#feedbackModal" id="feedback"
					data-placement="top" [title]="tr.get('criticall','rating.title')">
					<i class="material-icons pt-1 d-sm-none"> feedback</i>
					<div class="d-none d-none d-sm-block">Feedback</div>
				</button>
				<button class="btn-rtc btn-outline-light btn-lg header-right-menu"
					data-toggle="modal" data-target="#supportModal" id="support"
					data-placement="top" [title]="tr.get('criticall','rating.title')">
					<i class="material-icons pt-1 d-sm-none"> help</i>
					<div class="d-none d-sm-block">Support</div>
				</button>
				<button class="btn-rtc btn-outline-light btn-lg header-right-menu"
					id="howto" data-toggle="dropdown" aria-haspopup="true"
					aria-expanded="false" [title]="tr.get('criticall','rating.title')">
					<i class="material-icons pt-1 d-sm-none"> info</i>
					<div class="d-none d-sm-block">How to</div>
				</button>
				<div class="dropdown-menu" aria-labelledby="dropdownHow">
					<button class="dropdown-item" type="button" id="call">Add
						a Contact</button>
					<button class="dropdown-item" type="button" id="vcall">Make
						a Call</button>
					<button class="dropdown-item" type="button" data-toggle="modal"
						data-target="#deleteModal" id="delete">Get a Number</button>
					<button class="dropdown-item" type="button" data-toggle="modal"
						data-target="#blockModal" id="block">Add Credit</button>
					<button class="dropdown-item" type="button" data-toggle="modal"
						data-target="#unblockModal" id="unblock">Dial Phones</button>
					<button class="dropdown-item" type="button" data-toggle="modal"
						data-target="#editModal" id="edit">Configure Devices</button>
					<div class="dropdown-divider"></div>
					<button class="dropdown-item" type="button">Do Advanced
						Stuff</button>
				</div>
				<button class="btn-rtc btn-outline-light btn-lg header-right-menu"
					data-toggle="modal" data-target="#pricingModal" id="rates"
					data-placement="top" [title]="tr.get('criticall','rating.title')">
					<i class="material-icons pt-1 d-sm-none"> monetization_on</i>
					<div class="d-none d-sm-block">Rates</div>
				</button>
				<button (click)="setFullScreen()"
					class="btn-rtc btn-outline-light btn-lg header-right-menu-fs"
					id="fullscreen" data-placement="top"
					[title]="tr.get('criticall','rating.title')">
					<i class="material-icons pt-1">fullscreen</i>
				</button>
				<div class="dropdown">
					<button
						class="btn-rtc btn-outline-light btn-lg header-right-menu-fs dropdown-toggle"
						type="button" id="dropdownMenuButton" data-toggle="dropdown"
						aria-haspopup="true" aria-expanded="false"
						style="margin-right: -10px; padding-right: 5px;">
						<i *ngIf="isOnline" class="material-icons status-menu-main"
							style="color: #2ecc71">lens</i> <i *ngIf="isAway"
							class="material-icons status-menu-main" style="color: #f39c12">lens</i>
						<i *ngIf="isDND" class="material-icons status-menu-main"
							style="color: #e74c3c">lens</i> <i *ngIf="isOffline"
							class="material-icons status-menu-main" style="color: #1f272f">lens</i>
					</button>
					<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
						<a (click)="goOnline()" class="dropdown-item"
							style="cursor: default;">
							<div class="row">
								<div class="col-8">Online</div>
								<div class="col-4">
									<i class="material-icons status-menu" style="color: #2ecc71">lens</i>
								</div>
							</div>
						</a> <a (click)="goAway()" class="dropdown-item"
							style="cursor: default;">
							<div class="row">
								<div class="col-8">Away</div>
								<div class="col-4">
									<i class="material-icons status-menu" style="color: #f39c12">lens</i>
								</div>
							</div>
						</a> <a class="dropdown-item" data-toggle="modal"
							data-target="#dndModal" style="cursor: default;">
							<div class="row">
								<div class="col-8">Don't Disturb</div>
								<div class="col-4">
									<i class="material-icons status-menu" style="color: #e74c3c">lens</i>
								</div>
							</div>
						</a> <a (click)="goOffline()" class="dropdown-item"
							style="cursor: default;">
							<div class="row">
								<div class="col-8">Offline</div>
								<div class="col-4">
									<i class="material-icons status-menu" style="color: #1f272f">lens</i>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</header>
	<div class="app-body">
		<!-- Left Sidebar eqMenu -->
		<nav class="app-nav" (mouseover)="closeVolume()" *ngIf="showControls">
			<div class="eqMenu mr-2">
				<i *ngIf="blinkContacts" class="material-icons warning-menu"
					[ngClass]="{'blink_me': blinkContacts === true }">lens</i>
				<h1>
					<i class="material-icons"
						[ngClass]="{'blink_me_phone': inCall === true && selectedSideBar === 1 || inCall === true && selectedSideBar === 2 || inCall === true && selectedSideBar === 4 || inCall === true && selectedSideBar === 5 }">menu</i>
				</h1>
				<ul>
					<li (click)="buttonClicked($event,1)"
						[ngClass]="{'active':selectedSideBar === 1}"><i
						class="material-icons">home</i></li>
					<li (click)="buttonClicked($event,2)"
						[ngClass]="{'active':selectedSideBar === 2}"><i
						class="material-icons">group</i><i *ngIf="blinkContacts"
						class="material-icons chat-menu"
						[ngClass]="{'blink_me': blinkContacts === true}">chat_bubble_outline</i></li>
					<li *ngIf="inCall" (click)="buttonClicked($event,0)"><i
						class="material-icons blink_me_phone">phone_in_talk</i></li>
					<li (click)="buttonClicked($event,4)"
						[ngClass]="{'active':selectedSideBar === 4}"><i
						class="material-icons">settings</i></li>
					<li (click)="buttonClicked($event,5)"
						[ngClass]="{'active':selectedSideBar === 5}"><i
						class="material-icons">more_horiz</i></li>
				</ul>
			</div>
		</nav>
		<main class="app-content">
			<!-- Close Side Menu Items -->
			<div
				*ngIf="inCall && selectedSideBar===2 && showControls || inCall && selectedSideBar===3&& showControls || inCall && selectedSideBar===4 && showControls|| inCall && selectedSideBar===5 && showControls"
				(click)="buttonClicked($event,0)"
				style="width: 46px; position: fixed; top: 25px; left: 1px; z-index: 7; cursor: pointer;">
				<h1>
					<i class="material-icons"
						[ngClass]="{'blink_me_phone': inCall === true && selectedSideBar === 1 || inCall === true && selectedSideBar === 2 || inCall === true && selectedSideBar === 4 || inCall === true && selectedSideBar === 5 }">menu</i>
				</h1>
			</div>
			<!-- Notifications -->
			<app-popup-controller> </app-popup-controller>
			<app-warning></app-warning>
			<!-- Contact Menu Overlay -->
			<app-messenger *ngIf="selectedMessenger && !openChat && showControls"
				[messenger]="selectedMessenger" [inCall]="inCall"
				[rtcButtons]="rtcButtons" (deviceonEvent)="deviceonStatus($event)"
				(deviceoffEvent)="deviceoffStatus($event)"
				(saEvent)="saStatus($event)"> </app-messenger>
			<!-- In Call Screen -->
			<div class="row no-gutters rtc-connection-container">
				<div
					[ngClass]="{'local-video-optimised': rtcConnections.length == 1 || rtcFullscreen }">
					<app-videocanvas [hidden]="!inCall" [rtcFullscreen]="rtcFullscreen"
						[rtcSwitchview]="rtcSwitchview" [showControls]="showControls"
						(activeEvent)="activeStatus($event)"
						(controlsEvent)="controlStatus($event)"
						(overlayEvent)="overlayStatus($event)"></app-videocanvas>
				</div>
				<span *ngIf="overlayCropping"
					style="position: absolute; z-index: 1; width: 100%; height: 100%; margin: 0; background: linear-gradient(rgba(169, 219, 253, 0.8) 0%, rgb(0, 0, 0) 100%);"></span>
				<app-rtccontainer (click)="showControl()"
					[showControls]="showControls" [rtcButtons]="rtcButtons"
					[settingsOverlay]="settingsOverlay"
					(fullscreenEvent)="fullscreenStatus($event)"
					(switchviewEvent)="switchviewStatus($event)"
					[ngStyle]="{'height':rtcConnections.length == 1?  '100%' : '100%', 'pointer-events':overlayCropping?  'none' : 'auto'}"
					style="width: 100%; margin: 0;"></app-rtccontainer>
			</div>
			<!-- Slide Out eqMenu Item Contacts -->
			<input type="radio" id="eqMenutoggler-Contacts"
				[checked]="selectedSideBar===2" name="sidebarRadios" value="option2">
			<div class="page-wrap" *ngIf="showControls">
				<div class="eqMenu-container"
					[ngClass]="{'incall': inCall === true && !menuExtended }">
					<a><i *ngIf="inCall && !menuExtended" (click)="extendMenu()"
						class="material-icons btn-rtc btn-outline-light d-none d-sm-block"
						style="position: absolute; z-index: 1; top: 0px; left: 320px; box-shadow: none; outline: none; border: none; background: transparent; cursor: pointer;">skip_next</i></a>
					<a><i *ngIf="inCall && menuExtended" (click)="extendMenu()"
						class="material-icons btn-rtc btn-outline-light d-none d-sm-block"
						style="position: absolute; z-index: 1; top: 0px; right: 0px; box-shadow: none; outline: none; border: none; background: transparent; cursor: pointer;">skip_previous</i></a>
					<div class="eqMenu-content"
						[ngStyle]="{'height':inCall && rtcButtons?  'calc(100vh - 40px)' : 'calc(100vh - 0px)'}">
						<ul class="nav justify-content-center mt-2" id="contactsTab"
							role="tablist">
							<li class="nav-item mt-2"><a
								class="nav-link btn-rtc btn-outline-primary active" id="add-tab"
								data-toggle="tab" href="#add" role="tab" aria-controls="add"
								aria-selected="true" style="border: 1px solid #2c3e50"><i
									class="material-icons">person</i></a></li>
							<li class="nav-item mt-2"><a
								class="nav-link btn-rtc btn-outline-primary" id="links-tab"
								data-toggle="tab" href="#links" role="tab" aria-controls="links"
								aria-selected="false" style="border: 1px solid #2c3e50"><i
									class="material-icons">share</i></a></li>
							<li class="nav-item mt-2"><a (click)="dialerSlider()"
								class="nav-link btn-rtc btn-outline-primary" id="dialpad-tab"
								style="border: 1px solid #2c3e50"><i class="material-icons">phone</i></a>
							</li>
						</ul>
						<!-- Slide Out Dialer Panel -->
						<div [ngClass]="{'panel-wrap': !dialerSlide }" *ngIf="dialerSlide">
							<div [ngClass]="{'panel': dialerSlide }"
								[ngStyle]="{'height':dialerActive?  'auto' : '372px'}">
								<div class="heading">
									<p>Dial-Pad</p>
								</div>
								<div class="d-flex justify-content-between"
									style="margin-top: -48px;">
									<div class="p-2">
										<div *ngIf="user[0]">
											<div class="dropdown">
												<button type="button" id="dropdownDialpad"
													data-toggle="dropdown" aria-haspopup="true"
													aria-expanded="false"
													style="box-shadow: none; outline: none; border: none; background: transparent; color: #f8f9fa">
													<i class="p-2 fa fa-ellipsis-h"></i>
												</button>
												<div class="dropdown-menu dm-style"
													aria-labelledby="dropdownDialpad">
													<p class="dropdown-item-disabled whitespace-normal">Balance:
														{{user[0].twilioBalance | currency}}</p>
													<p class="dropdown-item-disabled">Local Number:
														{{user[0].callerID}}</p>
													<div class="dropdown-divider"></div>
													<button
														(click)="accountPath='PAYMENT,RECHARGE'; buttonClicked($event,5)"
														class="dropdown-item" type="button">Buy Credit</button>
												</div>
											</div>
										</div>
									</div>
									<div class="p-2">
										<a (click)="dialerSlider()"><i
											class="p-2 fa fa-long-arrow-right"></i></a>
									</div>
								</div>
								<div *ngIf="user[0] && user[0].twilioBalance === 0"
									id="collapseDialPadOnboarding" class="collapse show">
									<div class="d-flex justify-content-center mb-4">
										<div class="onboarding">
											<div class="modal-header onboarding-header">
												<h5 class="modal-title mt-1">Dial Pad Activation</h5>
												<button type="button" class="close" data-toggle="collapse"
													data-target="#collapseDialPadOnboarding"
													aria-expanded="true"
													aria-controls="collapseDialPadOnboarding">
													<span aria-hidden="true">&times;</span>
												</button>
											</div>
											<div class="slider">
												<div class="slide">
													<div>
														<button
															(click)="accountPath='ADDONS,DIALOUT'; buttonClicked($event,5)"
															class="btn-rtc btn-outline-light btn-lg header-right-menu-fs mt-4"
															type="button" style="height: 60px">
															<i class="material-icons mt-1">more_horiz</i>
														</button>
													</div>
													<p>EqCall can be used to call out globally to over 60+
														Countries or buy a local dial in number.</p>
													<p>You'll need to add credit before dialing out or
														buying numbers, just click the Account Icon in the left
														hand menu.</p>
													<small>EqCall should not be used for emergency
														calling</small>
												</div>
												<div class="slide"></div>
												<div class="slide"></div>
											</div>
										</div>
									</div>
								</div>
								<app-dialer *ngIf="user[0] && user[0].twilioBalance > 0"
									[dialerStyle]="'full'" (inCallEvent)="inCallStatus($event)"></app-dialer>
							</div>
						</div>
						<!-- Contacts-->
						<div *ngIf="selectedSideBar===2" class="tab-content contacts"
							id="addContact">
							<div class="tab-pane fade show active" id="add" role="tabpanel"
								aria-labelledby="add-tab">
								<app-messenger-list [inCall]="inCall"></app-messenger-list>
								<div *ngIf="noContacts" id="collapseContactsOnboarding"
									class="collapse show">
									<div class="d-flex justify-content-center">
										<div class="onboarding">
											<div class="modal-header onboarding-header">
												<h5 class="modal-title mt-1">Manage Contact and Groups
												</h5>
												<button type="button" class="close" data-toggle="collapse"
													data-target="#collapseContactsOnboarding"
													aria-expanded="true"
													aria-controls="collapseContactsOnboarding">
													<span aria-hidden="true">&times;</span>
												</button>
											</div>
											<div class="slider">
												<div class="slide">
													<h1>Add Contacts & Connect</h1>
													<div>
														<button class="btn btn-primary" type="button"
															data-toggle="modal" data-target="#addModal" id="add">
															<i class="material-icons">person_add</i>
														</button>
													</div>
													<p>You can use a contact's email address or a key link
														if they've shared one with you.</p>
													<p>Like to test eqCall or easily invite someone via a
														web link? Just click share icon at the top menu to spin up
														a Key.</p>
												</div>
												<div class="slide"></div>
												<div class="slide"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane fade" id="links" role="tabpanel"
								aria-labelledby="links-tab">
								<!-- Keys -->
								<app-keylinks>{{tr.get('criticall','keys')}} </app-keylinks>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="showControls">
				<!-- Slide Out eqMenu Item Settings -->
				<input type="radio" [checked]="selectedSideBar===4"
					id="eqMenutoggler-Settings" name="sidebarRadios" value="option4">
				<div class="page-wrap">
					<div class="eqMenu-container"
						[ngClass]="{'incall': inCall === true && !menuExtended }">
						<a><i *ngIf="inCall && !menuExtended" (click)="extendMenu()"
							class="material-icons btn-rtc btn-outline-light"
							style="position: absolute; z-index: 1; top: 0px; left: 320px; box-shadow: none; outline: none; border: none; background: transparent; cursor: pointer;">skip_next</i></a>
						<a><i *ngIf="inCall && menuExtended" (click)="extendMenu()"
							class="material-icons btn-rtc btn-outline-light"
							style="position: absolute; z-index: 1; top: 0px; right: 0px; box-shadow: none; outline: none; border: none; background: transparent; cursor: pointer;">skip_previous</i></a>
						<div class="eqMenu-content" id="Settings"
							[ngStyle]="{'height':inCall && rtcButtons ?  'calc(100vh - 40px)' : 'calc(100vh - 0px)'}">
							<app-media-selector *ngIf="selectedSideBar===4"></app-media-selector>
						</div>
					</div>
				</div>
				<!-- Slide Out eqMenu Item Account -->
				<input type="radio" [checked]="selectedSideBar===5"
					id="eqMenutoggler-Account" name="sidebarRadios" value="option5">
				<div class="page-wrap">
					<div class="eqMenu-container"
						[ngClass]="{'incall': inCall === true && !menuExtended }">
						<a><i *ngIf="inCall && !menuExtended" (click)="extendMenu()"
							class="material-icons btn-rtc btn-outline-light"
							style="position: absolute; z-index: 1; top: 0px; left: 320px; box-shadow: none; outline: none; border: none; background: transparent; cursor: pointer;">skip_next</i></a>
						<a><i *ngIf="inCall && menuExtended" (click)="extendMenu()"
							class="material-icons btn-rtc btn-outline-light"
							style="position: absolute; z-index: 1; top: 0px; right: 0px; box-shadow: none; outline: none; border: none; background: transparent; cursor: pointer;">skip_previous</i></a>
						<div class="eqMenu-content account" id="Account"
							[ngStyle]="{'height':inCall && rtcButtons?  'calc(100vh - 40px)' : 'calc(100vh - 0px)'}">
							<app-account [show]="accountPath" *ngIf="selectedSideBar===5"></app-account>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
	<!-- In Call Presentation View Overlay -->
	<div *ngIf="inCall" id="collapseStripView" class="collapse">
		<!-- In Call Presentation/Screen Sharing Area -->
		<div class="presentation-area"
			style="position: absolute; height: calc(80% - 43px); width: 100%; border: 1px solid #fff; top: 0px; left: 0px; background: rgba(76, 119, 154, 0.74);">
			<h3 style="color: white;">Presentation Area - Show Video of
				Clicked Strip Item</h3>
		</div>
		<!-- In Call Video Film Strip -->
		<div class="film-strip-area"
			style="position: absolute; height: 20%; width: 100%; border: 1px solid #fff; bottom: 44px; overflow-x: scroll; background: rgba(76, 119, 154, 0.74);">
			<h3 style="color: white;">Film Strip RTC Area - Show RTC with
				own Flex setting</h3>
		</div>
	</div>
	<footer *ngIf="inCall && showControls" class="app-footer">
		<div
			style="position: absolute; bottom: 0px; width: 100%; background: rgba(76, 119, 154, 0.74);">
			<app-mastercontrol [chatStatus]="chatStatus"
				[showVolume]="showVolume" [deviceStatus]="deviceStatus"
				(controlEvent)="controlStatus($event)"
				(deviceonEvent)="deviceonStatus($event)"
				(deviceoffEvent)="deviceoffStatus($event)"
				(soverlayEvent)="soverlayStatus($event)"
				(messageEvent)="buttonsStatus($event)"
				(volumeEvent)="volumeStatus($event)"></app-mastercontrol>
			<input type="checkbox" id="eqMenutoggler-GlobalChat"
				[(ngModel)]="openChat" (ngModelChange)="openChatWindow($event)">
			<div *ngIf="openChat && rtcButtons" class="eqMenu-GlobalChat"
				[ngClass]="{'incall': inCall === true }"
				[ngStyle]="{'z-index':layerChat ?  '6' : '5', 'height':rtcButtons ?  'calc(100vh - 40px);' : 'calc(100vh - 0px);'}">
				<app-globalchat></app-globalchat>
			</div>
			<input type="checkbox" id="eqMenutoggler-Mixer"
				[(ngModel)]="showMixer" (ngModelChange)="openMixerWindow($event)">
			<div *ngIf="showMixer && rtcButtons" class="eqMenu-Mixer">
				<app-mixer [showVolume]="showVolume"></app-mixer>
			</div>
		</div>
	</footer>
</div>
<!-- End eqCall App -->
<!-- Logout Modal -->
<div class="modal fade" id="logoutModal" tabindex="-1" role="dialog"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Logout of eqCall?</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div *ngFor="let u of user" class="modal-body">
				<p>Hey {{u.nickname}}, you're about to log out of eqCall so you
					won't get calls or messages until you log back in. See you soon!</p>
			</div>
			<div class="modal-footer d-flex justify-content-center">
				<div class="d-flex">
					<div class="p-2">
						<button (click)="logout()" type="button" class="btn btn-warning"
							data-dismiss="modal">Logout</button>
					</div>
					<div class="p-2">
						<button type="button" class="btn btn-primary" data-dismiss="modal">Cancel</button>
					</div>
					<div class="ml-auto p-2" style="margin-top: 5px">
						<input type="checkbox" id="clearSaved" name="clearSaved"
							[checked]="clearSaved" (change)="clearSaved = !clearSaved"
							value="" class="checkbox-template">
					</div>
					<div class="ml-auto p-2" style="margin-top: 5px">
						<p>Clear Cache</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Home Modal -->
<div class="modal fade" id="homeModal" tabindex="-1" role="dialog"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Home Screen Options</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div *ngFor="let u of user" class="modal-body">
				<div>
					<form role="form" action="">
						<div class="row">
							<div class="col-auto">
								<input id="id_auto_recharge1" type="checkbox" value=""
									class="checkbox-template">
							</div>
							<div class="col">Skipe home Screen on Login</div>
						</div>
						<div class="row">
							<div class="col-auto">
								<input id="id_auto_recharge3" type="checkbox" value=""
									class="checkbox-template">
							</div>
							<div class="col">Remove Background Image</div>
						</div>
						<hr>
						<a href="" data-toggle="collapse" data-parent="#accordion"
							data-target="#collapseHomeOptions" aria-expanded="false"
							data-placement="top">Remove eqCall Branding</a>
						<div id="collapseHomeOptions" class="panel-collapse collapse"
							aria-expanded="false">
							<button type="button"
								class="collapsed btn btn-danger btn-lg btn-block mt-2"
								data-toggle="modal" data-target="#homeModal" id="homeModal"
								data-placement="top"
								[title]="tr.get('account-profile','account-closet')">Feature
								in Development</button>
						</div>
					</form>
				</div>
			</div>
			<div class="modal-footer d-flex justify-content-center">
				<button (click)="logout()" type="button" class="btn btn-warning"
					data-dismiss="modal">Save</button>
				<button type="button" class="btn btn-primary" data-dismiss="modal">Cancel</button>
			</div>
		</div>
	</div>
</div>
<!-- Feedback Modal -->
<div class="modal fade" id="feedbackModal" tabindex="-1" role="dialog"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">We want to be better, help us get
					there.</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="fb-form">
					<form action="#" class="form-group">
						<input class="form-control" placeholder="Name" type="text">
						<input class="form-control" placeholder="Email (Optional)"
							type="text">
						<textarea class="form-control" id="fb-comment" name=""
							[placeholder]="tr.get('criticall','rateing.title')"></textarea>
						<div class="rating">
							<i class="fa fa-star"></i> <i class="fa fa-star"></i> <i
								class="fa fa-star"></i> <i class="fa fa-star"></i> <i
								class="fa fa-star"></i>
						</div>
						<input class="form-control btn btn-primary" type="submit">
						<button type="button" class="form-control btn btn-warning"
							data-dismiss="modal">Maybe later</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Support Modal -->
<div class="modal fade" id="supportModal" tabindex="-1" role="dialog"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">We're here to help. Tell us what went
					wrong.</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="fb-form">
					<form action="#" class="form-group">
						<input class="form-control" placeholder="Name" type="text">
						<input class="form-control" placeholder="Email" type="text">
						<textarea class="form-control" id="fb-comment" name=""
							placeholder="Please tell us what you need help with and our Support Team will get back to you asap and within 24 hours"></textarea>
						<input class="form-control btn btn-primary" type="submit">
						<button type="button" class="form-control btn btn-warning"
							data-dismiss="modal">Cancel</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Pricing Modal -->
<div class="modal fade" id="pricingModal" tabindex="-1" role="dialog"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Using eqCall Phone Services?</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="fb-form">
					<form action="#" class="form-group">
						<p>Please enter the destination to see applicable rates.</p>
						<input class="form-control"
							placeholder="Please start typing a destination or country code"
							type="text">
						<p>
							Like to see our plans? Please visit our website <a
								href="https://eqcall.com">here.</a>
						</p>
						<button type="button" class="form-control btn btn-warning"
							data-dismiss="modal">Cancel</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Add Contact Modal -->
<div class="modal fade" id="addModal" tabindex="-1" role="dialog"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Add a Contact to eqCall</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div [ngClass]="{'show': noContacts }">
					<div class="row no-gutters mb-4">
						<div class="col-sm-10">
							<input #addCnt type="text"
								class="form-control form-control-lg input-sm"
								placeholder="Add a Contact with their email address or Key code">
						</div>
						<div class="col-sm-2">
							<button class="btn-send btn-warning btn-lg btn-block"
								(click)="addContact(addCnt.value); addCnt.value=''"
								data-dismiss="modal">{{tr.get('criticall','add')}}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Bulk Delete Modal -->
<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Bulk Delete - Heads Up!</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div *ngFor="let u of user" class="modal-body">
				<p>Hey {{u.nickname}}, you're about to bulk delete Key Links!
					Are you sure you'd like to do this?</p>
			</div>
			<div class="modal-footer d-flex justify-content-center">
				<button type="button" class="btn btn-warning" data-dismiss="modal">Yes,
					delete them.</button>
				<button type="button" class="btn btn-primary" data-dismiss="modal">No,
					don't delete.</button>
			</div>
		</div>
	</div>
</div>
<!-- Select Devices Modal -->
<div class="modal fade" id="sdModal" data-backdrop="false" tabindex="-1"
	role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Device Settings | Notifications</h5>
				<button (click)="toggleActive()" type="button" class="close"
					data-dismiss="modal" aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<app-media-selector *ngIf="showAll"></app-media-selector>
			</div>
			<div class="modal-footer d-flex justify-content-center"></div>
		</div>
	</div>
</div>
<!-- Select Cam Modal -->
<div class="modal fade" id="camonlyModal" data-backdrop="false"
	tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Camera Broadcast Options</h5>
				<button (click)="toggleActive()" type="button" class="close"
					data-dismiss="modal" aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<app-media-selector *ngIf="camActive" [camActive]="camActive"></app-media-selector>
			</div>
			<div class="modal-footer d-flex justify-content-center"></div>
		</div>
	</div>
</div>



<!-- Delete Account Modal -->
<div class="modal fade" id="deleteAccountModal" tabindex="-1"
	role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">{{tr.get("account-profile","account-closeq")}}
				</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>Are you sure you would like to delete your eqCall account?
					This action cannot be undone.</p>
			</div>
			<div class="modal-footer d-flex justify-content-center">
				<button (click)="logout()" type="button" class="btn btn-warning"
					data-dismiss="modal">Delete it!</button>
				<button type="button" class="btn btn-primary" data-dismiss="modal">Cancel
					Delete</button>
			</div>
		</div>
	</div>
</div>
<!-- Do Not Disturb Modal -->
<div class="modal fade" id="dndModal" tabindex="-1" role="dialog"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Do Not Disturb Mode</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>Are you sure you would like to activate Do Not Disurb? You
					will not recieve any audio notifications for incomming calls or
					messages.</p>
			</div>
			<div class="modal-footer d-flex justify-content-center">
				<button (click)="goDND()" type="button" class="btn btn-warning"
					data-dismiss="modal">Turn On</button>
				<button type="button" class="btn btn-primary" data-dismiss="modal">Cancel</button>
			</div>
		</div>
	</div>
</div>
<!-- Info AC Modal -->
<div class="modal fade" id="infoacModal" tabindex="-1" role="dialog"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Individual Audio Controls</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div *ngFor="let u of user" class="modal-body">
				<p>Control individual Volume, Bass and Treble with the Equaliser
					slider, filter out background noise and echoes with the Silence
					Slider.</p>
				<p>Keep the Silence grey bar just below the green volume
					threshold level when the guest is not speaking.</p>
				<small>You can also use the inbuilt Mixer to control
					everyone's audio as well.</small>
			</div>
			<div class="modal-footer d-flex justify-content-center">
				<button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>
			</div>
		</div>
	</div>
</div>

