import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserRegistrationService } from '../../../service/cognito.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslationService } from '../../../service/translation.service';

@Component({
  selector: 'app-criticall',
  templateUrl: './confirmRegistration.html',
  styleUrls: ['../../../../assets/css/custom.css']

})
export class RegistrationConfirmationComponent implements OnInit, OnDestroy {
  confirmationCode: string;
  userID: string;
  errorMessage: string;
  private sub: any;

  constructor(private regService: UserRegistrationService, private router: Router,
    private route: ActivatedRoute, private  spinner: NgxSpinnerService, public tr: TranslationService) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params: any) => {
      this.userID = params['username'];
    });
    this.errorMessage = null;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onConfirmRegistration() {
    this.errorMessage = null;
    this.spinner.show();
    this.regService.confirmRegistration(this.userID, this.confirmationCode, this);
  }

  cognitoCallback(message: string, _result: any) {
    this.spinner.hide();
    if (message) { // error
      this.errorMessage = message;
      console.log('message: ' + this.errorMessage);
    } else { // success
      // move to the next step
      console.log('Moving to login');
      // this.configs.curUser = result.user;
      this.router.navigate(['/home/login']);
    }
  }
}
