<div *ngIf="messenger.type==='request'">
	<div>
		<div class="dropdown">
			<button type="button"
				class="collapsed btn btn-warning btn-lg btn-block contact-whitespace"
				data-toggle="dropdown" aria-expanded="false" data-placement="top"
				[title]="tr.get('contact.list','c.request')"
				style="color: #ecf0f1; background-color: #37cfdc57; border-color: #37cfdc;">
				<span class="blink_me contact-limit-width"
					style="display: inline-block;">{{tr.get("contact.list","c.request")}}:
					{{name}}</span>
			</button>
			<div class="dropdown-menu dm-style" aria-labelledby="dropdownTZ">
				<p class="dropdown-item-disabled whitespace-normal"
					style="color: #37cfdc">Contact Request</p>
				<div class="dropdown-divider"></div>
				<p class="dropdown-item-disabled" style="color: #95a5a6">
					<span style="color: #ecf0f1">{{name}} would like to add you
						as a contact.</span>
				</p>
				<div class="dropdown-divider"></div>
				<button (click)="acceptRequest(true)" class="dropdown-item"
					[title]="tr.get('contact.list','c.requesty')"
					title="Approve the Contact Request" style="color: #2ecc71">Accept
					Request</button>
				<button (click)="acceptRequest(false)" class="dropdown-item"
					[title]="tr.get('contact.list','c.requestn')"
					style="color: #e74c3c">Decline Request</button>
			</div>
		</div>
		<div class="dropdown">
			<button type="button" class="btn-sm gdd-button" id="dropdownTZ"
				data-toggle="dropdown"
				style="box-shadow: none; outline: none; border: none; right: 16px"
				aria-haspopup="true" aria-expanded="false">
				<i class="fa fa-ellipsis-v" style="color: #ecf0f1"></i>
			</button>
			<div class="dropdown-menu dm-style" aria-labelledby="dropdownTZ">
				<p class="dropdown-item-disabled whitespace-normal"
					style="color: #37cfdc">Contact Request</p>
				<div class="dropdown-divider"></div>
				<p class="dropdown-item-disabled" style="color: #95a5a6">
					<span style="color: #ecf0f1">{{name}} would like to add you
						as a contact.</span>
				</p>
				<div class="dropdown-divider"></div>
				<button (click)="acceptRequest(true)" class="dropdown-item"
					[title]="tr.get('contact.list','c.requesty')"
					title="Approve the Contact Request" style="color: #2ecc71">Accept
					Request</button>
				<button (click)="acceptRequest(false)" class="dropdown-item"
					[title]="tr.get('contact.list','c.requestn')"
					style="color: #e74c3c">Decline Request</button>
			</div>
		</div>
	</div>
</div>

<div *ngIf="messenger.type==='requester'">
	<div>
		<div class="dropdown">
			<button type="button"
				class="collapsed btn btn-warning btn-lg btn-block contact-whitespace"
				data-toggle="dropdown" aria-expanded="false" data-placement="top"
				[title]="tr.get('contact.list','c.requester')"
				style="color: #ecf0f1; background-color: green; border-color: #37cfdc;">
				<span class="blink_me contact-limit-width"
					style="display: inline-block;">{{tr.get("contact.list","c.requester")}}:
					{{name}}</span>
			</button>
		</div>
	</div>
</div>

<div *ngIf="messenger.type==='contact'">
	<div *ngIf="messenger.isBlocked">
		<button (click)="clicked()" type="button"
			class="btn btn-lg btn-danger btn-block contact-whitespace"
			[title]="tr.get('contact.list','blocked.menu')"
			style="background-color: #dc35457a">
			<span
				[ngClass]="{'contact-limit-width-incall':inCall, 'contact-limit-width':!inCall }"
				style="display: inline-block;">{{name}}</span>
		</button>
		<div class="dropdown">
			<button type="button" class="btn-sm gdd-button" id="dropdownTZ"
				data-toggle="dropdown"
				style="box-shadow: none; outline: none; border: none; right: 16px"
				aria-haspopup="true" aria-expanded="false">
				<i class="fa fa-ellipsis-h" style="color: #ecf0f1"></i>
			</button>
			<div class="dropdown-menu dm-style" aria-labelledby="dropdownTZ">
				<p class="dropdown-item-disabled whitespace-normal"
					style="color: #e74c3c">Contact Blocked</p>
				<div class="dropdown-divider"></div>
				<p class="dropdown-item-disabled" style="color: #95a5a6">
					<span style="color: #ecf0f1">{{name}} is currently blocked.</span>
				</p>
				<div class="dropdown-divider"></div>
				<button (click)="clicked()" class="dropdown-item">More
					Options</button>
			</div>
		</div>
	</div>
	<div *ngIf="!messenger.isBlocked">
		<div *ngIf="messenger.state[0]==='connected'">
			<div class="tab-content">
				<div attr.id="{{'CName' + messenger.nickname}}"
					class="tab-pane fade show active">
					<div class="row no-gutters">
						<div class="col">
							<button *ngIf="!isGroup" (click)="clicked()" type="button"
								class="btn btn-lg btn-block contact-whitespace"
								[ngClass]="{'btn-primary-contact':!messenger.isAnon,'btn-info':messenger.isAnon}"
								title="Open Online Contact or Group Menu"
								[ngStyle]="{'background-color':isGroup ?  'transparent' : '#244e6b', 'border':isGroup ?  '1px solid #3498db' : '1px solid #3498db', 'color':isGroup ?  '#ffffffa6' : '#fff' }">
								<span
									[ngClass]="{'contact-limit-width-incall':inCall, 'contact-limit-width':!inCall }"
									style="display: inline-block;">{{messenger.nickname}}</span> <a
									*ngIf="messenger.unreadMessageCount > 0" id="chat-head"><i>{{messenger.unreadMessageCount}}</i>
								</a>
							</button>
							<button *ngIf="isGroup" (click)="clicked()" type="button"
								class="btn btn-lg btn-block contact-whitespace"
								[ngClass]="{'btn-primary-contact':!messenger.isAnon,'btn-info':messenger.isAnon}"
								title="Open Online Contact or Group Menu"
								[ngStyle]="{'background-color':isGroup ?  'transparent' : '#244e6b', 'border':isGroup ?  '1px solid #37cfdcc2' : '1px solid #3498db', 'color':isGroup ?  '#ffffffa6' : '#fff' }">
								<span
									[ngClass]="{'contact-limit-width-incall-group':inCall, 'contact-limit-width':!inCall }"
									style="display: inline-block;"> <span
									style="color: #37cfdcc2; display: inline-block;"> Group:</span>
									{{messenger.nickname}}
								</span> <a *ngIf="messenger.unreadMessageCount > 0" id="chat-head"><i>{{messenger.unreadMessageCount}}</i>
								</a>
							</button>
							<div *ngIf="!isGroup" class="dropdown">
								<button type="button" class="btn-sm tz-button" id="dropdownTZ"
									data-toggle="dropdown"
									style="box-shadow: none; outline: none; border: none;"
									aria-haspopup="true" aria-expanded="false">
									<i *ngIf="messenger.unreadMessageCount === 0"
										class="material-icons warning-contact-menu"
										style="color: #2ecc71">lens</i> <i
										*ngIf="messenger.unreadMessageCount > 0"
										class="material-icons warning-contact-menu blink_me"
										style="color: #2ecc71">lens</i>
								</button>
								<div class="dropdown-menu dm-style" aria-labelledby="dropdownTZ">
									<p class="dropdown-item-disabled whitespace-normal"
										style="color: #2ecc71">{{name}} is Online</p>
									<div class="dropdown-divider"></div>
									<p *ngIf="messenger.dateTime !== '-'"
										class="dropdown-item-disabled" style="color: #95a5a6">
										Local Time:&nbsp;<br /> <span style="color: #ecf0f1">
											{{messenger.dateTime}}</span>
									</p>
									<p *ngIf="messenger.dateTime == '-'"
										class="dropdown-item-disabled" style="color: #95a5a6">
										Local Time:&nbsp;<br /> <span style="color: #ecf0f1">
											Same local time as you</span>
									</p>
									<div class="dropdown-divider"></div>
									<button (click)="clicked()" class="dropdown-item">More
										Options</button>
								</div>
							</div>
							<div *ngIf="isGroup" class="dropdown">
								<button type="button" class="btn-sm gdd-button" id="dropdownTZ"
									data-toggle="dropdown"
									style="box-shadow: none; outline: none; border: none;"
									aria-haspopup="true" aria-expanded="false">
									<i *ngIf="messenger.unreadMessageCount === 0"
										class="material-icons group-contact-menu"
										style="color: #37cfdcc2">people<sup>+</sup></i> <i
										*ngIf="messenger.unreadMessageCount > 0"
										class="material-icons group-contact-menu blink_me"
										style="color: #37cfdcc2">people<sup>+</sup></i>
								</button>
								<div class="dropdown-menu dm-style" aria-labelledby="dropdownTZ">
									<p class="dropdown-item-disabled whitespace-normal"
										style="color: #2ecc71">Private Group</p>
									<div class="dropdown-divider"></div>
									<p *ngIf="owner" class="dropdown-item-disabled"
										style="color: #95a5a6">
										Group Owner:&nbsp;<br />
										<span style="color: #ecf0f1">{{owner.nickname}}</span>
									</p>
									<p class="dropdown-item-disabled" style="color: #95a5a6">
										Group Members:&nbsp;<br />
										<span *ngFor="let messenger of members" style="color: #ecf0f1">{{messenger.nickname}},&nbsp;</span>
									</p>
									<div class="dropdown-divider"></div>
									<button (click)="clicked()" class="dropdown-item">More
										Options</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="messenger.state[0]!=='connected'">
			<div *ngIf="messenger.awayable">
				<div class="row no-gutters">
					<div class="col">
						<button (click)="clicked()" type="button"
							class="btn btn-lg btn-block contact-whitespace"
							[ngClass]="{'btn-primary-contact':!messenger.isAnon,'btn-info':messenger.isAnon}"
							title="Open Online Contact or Group Menu"
							[ngStyle]="{'background-color':isGroup ?  'transparent' : '#244e6b', 'border':isGroup ?  '1px solid #3498db' : '1px solid #3498db' }"
							[title]="tr.get('contact.list','away')">
							<span
								[ngClass]="{'contact-limit-width-incall':inCall, 'contact-limit-width':!inCall }"
								style="display: inline-block;">{{name}}</span> <span
								*ngIf="messenger.unreadMessageCount > 0" class="badge">
								{{messenger.unreadMessageCount}} </span>
						</button>
						<div class="dropdown">
							<button type="button" class="btn-sm tz-button" id="dropdownTZ"
								data-toggle="dropdown"
								style="box-shadow: none; outline: none; border: none;"
								aria-haspopup="true" aria-expanded="false">
								<i *ngIf="messenger.unreadMessageCount === 0"
									class="material-icons warning-contact-menu"
									style="color: #f39c12">lens</i> <i
									*ngIf="messenger.unreadMessageCount > 0"
									class="material-icons warning-contact-menu blink_me"
									style="color: #f39c12">lens</i>
							</button>
							<div class="dropdown-menu dm-style" aria-labelledby="dropdownTZ">
								<p class="dropdown-item-disabled whitespace-normal"
									style="color: #95a5a6">{{name}} is Away</p>
								<div class="dropdown-divider"></div>
								<p *ngIf="messenger.dateTime !== '-'"
									class="dropdown-item-disabled" style="color: #95a5a6">
									Local Time:&nbsp;<br /> <span style="color: #ecf0f1">
										{{messenger.dateTime}}</span>
								</p>
								<p *ngIf="messenger.dateTime == '-'"
									class="dropdown-item-disabled" style="color: #95a5a6">
									Local Time:&nbsp;<br /> <span style="color: #ecf0f1">
										Same local time as you</span>
								</p>
								<div class="dropdown-divider"></div>
								<button (click)="clicked()" class="dropdown-item">More
									Options</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="!messenger.awayable">
				<div class="row no-gutters">
					<div class="col">
						<button (click)="clicked()" type="button"
							class="btn-outline-contact btn-lg btn-block contact-whitespace"
							[title]="tr.get('contact.list','offline.c')">
							<span
								[ngClass]="{'contact-limit-width-incall':inCall, 'contact-limit-width':!inCall }"
								style="display: inline-block;">{{name}}</span> <span
								*ngIf="messenger.unreadMessageCount > 0" class="badge">
								{{messenger.unreadMessageCount}} </span>
						</button>
						<div class="dropdown">
							<button type="button" class="btn-sm tz-button" id="dropdownTZ"
								data-toggle="dropdown"
								style="box-shadow: none; outline: none; border: none;"
								aria-haspopup="true" aria-expanded="false">
								<i class="material-icons warning-contact-menu"
									style="color: #2c3e50">lens</i>
							</button>
							<div class="dropdown-menu dm-style" aria-labelledby="dropdownTZ">
								<p class="dropdown-item-disabled whitespace-normal"
									style="color: #05080a7d">{{name}} is Offline</p>
								<div class="dropdown-divider"></div>
								<p *ngIf="messenger.dateTime !== '-'"
									class="dropdown-item-disabled" style="color: #95a5a6">
									Local Time:&nbsp;<br />
									<span style="color: #ecf0f1"> {{messenger.dateTime}}</span>
								</p>
								<p *ngIf="messenger.dateTime == '-'"
									class="dropdown-item-disabled" style="color: #95a5a6">
									Local Time:&nbsp;<br />
									<span style="color: #ecf0f1"> Not available</span>
								</p>
								<div class="dropdown-divider"></div>
								<button (click)="clicked()" class="dropdown-item">More
									Options</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div>