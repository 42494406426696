import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toast',
  template: '<div (animationend)="animationDone($event)"  class="notify bottom-left do-show" data-notification-status="notice">{{message}}</div>',
  styleUrls: ['toast.component.css']
})
export class ToastComponent {
  @Input() message = '';
  @Output() afterClose = new EventEmitter();
  constructor() { }

  animationDone(event: AnimationEvent) {
    if (event.animationName === 'fade-out') {
      this.afterClose.emit(true)
    }
  }
}
