import { Injectable } from '@angular/core';
import { Socket, NetworkService, SocketListener } from './network.service';
import { EqcallapiService } from './eqcallapi.service';
import {
  AudioAnalyser, NoAudioAnalyserImpl, AudioAnalyserImpl, AudioAnalyserCtlr,
  StreamhandlerService, EqualizerValues
} from './streamhandler.service';


export interface RtcProxyConnection {

  disposed: boolean;
  processRtcReceivedConnectionPacket(packet: any): void;
  getState(): string;
  addListener(arg0: any): void;
  hold(hold: boolean): void;
  close(): void;
  masterMuteLocalAudio(arg0: boolean): void;
  masterMuteLocalVideo(mute: boolean): void;
  setBandwidth(bw: number): void;
  sendChatMessage(connectionID: string, message: string): void;
  getAnalyser(): AudioAnalyser;
}

class Pkt {
  constructor(public id: string, public cmd: string, public data: string) {
  }
}

// Adds logic because proxy is shared with all recipients
class AnalyserWrapper implements AudioAnalyser {

  constructor(private analyser: AudioAnalyser) {
  }

  dispose(): void {
    // ignored
  }
  proxyDispose() {
    this.analyser.dispose();
  }
  connect(input: MediaStreamTrack[], output: AudioNode): void {
    this.analyser.connect(input, output);
  }

  reConnect(_input: MediaStreamTrack[]): void {
    // ignored
  }

  proxyReConnect(input: MediaStreamTrack[]): void {
    console.error('ProxySvc: ProxyWrapper: proxyReConnect', input);
    this.analyser.reConnect(input);
  }

  getLocalLevels(): EqualizerValues {
    return this.analyser.getLocalLevels();
  }
  setVolume(volume: number): void {
    this.analyser.setVolume(volume);
  }
  setRemoteEqValues(values: EqualizerValues): void {
    this.analyser.setRemoteEqValues(values);
  }
  getRemoteEqValues(): EqualizerValues {
    return this.analyser.getRemoteEqValues();
  }
  setEqValue(type: string, level: number): void {
    this.analyser.setEqValue(type, level);
  }
  localMute(mute: boolean): void {
    this.analyser.localMute(mute);
  }
  localHold(hold: boolean): void {
    this.analyser.localHold(hold);
  }
  noiseGateLevel(level: number): void {
    this.analyser.noiseGateLevel(level);
  }
  startAnalyser(): void {
    this.analyser.startAnalyser();
  }
  stopAnalyser(): void {
    this.analyser.stopAnalyser();
  }
  sendLevelData(send: boolean): void {
    this.analyser.sendLevelData(send);
  }

}


class MainConnection implements AudioAnalyserCtlr {
  private messageQueue: string[] = [];
  private dataChannelOpen = false;
  private lastDataMessage: string;
  private id = 'Main';
  private pc: RTCPeerConnection;
  public mediaStream: MediaStream;
  private senders = new Map<string, RTCRtpSender>();
  private cfg: string;
  isConnected = false;
  closed: boolean;
  private negotiating = false;
  public analyser: AnalyserWrapper;
  private dataChannel: RTCDataChannel;
  constructor(public stream: MediaStream, private proxySvc: RtcproxyService,
    public bandwidthKb: number, private streamHandler: StreamhandlerService) {
    console.log('ProxySvc: MainConnection constructor called');

    const vtracks = stream.getVideoTracks();
    if (vtracks.length > 0) {
      this.mediaStream = new MediaStream(vtracks);
    }

    const atracks = stream.getAudioTracks();
    if (atracks.length > 0) {

      this.analyser = new AnalyserWrapper(new AudioAnalyserImpl(this.streamHandler.audioContext,
        this, 'proxy', this.streamHandler));
      let audioLine = this.streamHandler.audioContext.createMediaStreamDestination();
      this.analyser.connect(atracks, audioLine);
      if (!this.mediaStream) {
        this.mediaStream = audioLine.stream;
      } else {
        this.mediaStream.addTrack(audioLine.stream.getAudioTracks()[0]);
      }
    } else {
      console.warn('RtcConnection: initGetUserMedia: no audio');
      this.analyser = new AnalyserWrapper(new NoAudioAnalyserImpl());
    }
    if (!this.mediaStream) {
      this.mediaStream = stream;
    }
  }

  sendRawMessage(data: any, _arg1: string): void {
    this.send(data);
  }

  getDestinationID(): string {
    return 'proxy';
  }

  sendEQValues(values: EqualizerValues): void {
    const data = {
      type: 'levels', id: 'proxy',
      eq: {
        values: values
      }
    };
    this.send(JSON.stringify(data));
  }


  private send(data: string) {
    if (this.dataChannelOpen) {
      if (data === this.lastDataMessage) {
        console.log('ProxySvc: send: skipping duplicate', data);
        return;
      }
      this.lastDataMessage = data;
      this.dataChannel.send(data);
    } else {
      this.messageQueue.push(data);
      console.log('ProxySvc: send: queued message ', data);
    }
  }

  private sendMessageQueue() {
    this.messageQueue.forEach((message: string) => { this.send(message) });
    console.log('ProxySvc: sendMessageQueue: sent ', this.messageQueue);
    this.messageQueue.length = 0;
  }

  setBandwidth(kb: number) {
    console.log('Proxy: set Bandwidth ' + kb)
    this.bandwidthKb = kb;
    this.updateBandwidth();
  }

  private updateBandwidth() {
    if (this.pc) {
      this.pc.getSenders().forEach((sender) => {
        const parameters = sender.getParameters();
        if (!parameters.encodings) {
          parameters.encodings = [{}];
        }

        parameters.encodings.forEach((encoding) => { encoding.maxBitrate = this.bandwidthKb * 512 });
        sender.setParameters(parameters)
      });
    }
  }

  async init(cfg: string) {
    if (this.negotiating) {
      return;
    }
    this.negotiating = true;

    if (cfg) {
      this.cfg = cfg;
    } else {
      cfg = this.cfg;
    }

    try {
      let config = {
        iceServers: [
          JSON.parse(cfg)
        ],
        sdpSemantics: 'unified-plan'
      }
      console.error('Using PCConfig=', config);
      this.pc = new RTCPeerConnection();
      this.pc.setConfiguration(config);
    //  let config = await this.proxySvc.apiSvc.getIceConfiguration();
    //  console.log('Got ice config ', config);
    //  this.pc = new RTCPeerConnection(config);

      this.pc.ondatachannel = event => {
        console.error('Datachannel created', event);
      };

      this.dataChannel =
        this.pc.createDataChannel('proxy');

      this.dataChannel.onerror = (error) => {
        console.log('Data Channel Error:', error);
      };

      this.dataChannel.onmessage = (event) => {
        this.proxySvc.processDataMessage(event);
      };

      this.dataChannel.onopen = () => {
        console.log('ProxySvc: The Data Channel is Open');
        this.dataChannelOpen = true;
        this.sendMessageQueue();
      };

      this.dataChannel.onclose = () => {
        console.log('ProxySvc: The Data Channel is Closed');
        // this.dataChannelOpen = false;
        //  this.dataChannel = null;
      };
      this.closed = false;

      this.mediaStream.getTracks().forEach(track => {
        let kind = track.kind;
          console.log('Proxy: adding track ', track);
        if ((kind === 'video' && !this.proxySvc.videoMuted) || (kind === 'audio' && !this.proxySvc.audioMuted)) {
          this.pc.addTransceiver(
            track,
            { direction: 'sendonly' });

        }
      });

      this.pc.addEventListener('icecandidate', event => {
        if (event.candidate) {
          this.proxySvc.sendPktToProxySvr({ cmd: 'ice', id: this.id, data: JSON.stringify(event.candidate) });
        }
      });

      this.pc.addEventListener('connectionstatechange', _event => {
        console.error('********************* proxy main connection state ' + this.pc.connectionState);
        const state = this.pc.connectionState;
        if (state === 'connected') {
          this.connected();
        } else if (state === 'failed' || state === 'disconnected' || state === 'closed') {
          this.disconnected();
        }
      });

      this.senders.clear();
      this.updateBandwidth();
      await this.sendOffer();
    } catch (err) {
      console.error('Error initializing Proxy', err);
      this.closed = false;
      this.close();
    }
  }

  private async sendOffer() {

    try {
      const offer = await this.pc.createOffer();
      console.log('offer=', offer);
      await this.pc.setLocalDescription(offer);
      this.proxySvc.sendPktToProxySvr({ cmd: 'offer', id: this.id, data: offer.sdp });
      this.negotiating = false;
      setTimeout(() => {
        if (!this.isConnected) {
          console.log('Proxy connection timeout');
          this.close();
        }
      }, 60000);
    } catch (err) {
      console.error('Error initializing Proxy', err);
      this.closed = false;
      this.close();
    }
  }

  public muteAudio(mute: boolean) {
    let tracks = this.mediaStream.getAudioTracks();
    if (tracks.length > 0) {
      this.mute(mute, tracks[0]);
    }
  }

  public muteVideo(mute: boolean) {
    let tracks = this.mediaStream.getVideoTracks();
    if (tracks.length > 0) {
      this.mute(mute, tracks[0]);
    }
  }

  private mute(mute: boolean, track: MediaStreamTrack) {
    let kind = track.kind;
    console.log('ProxySvc: Main: mute ', mute, track);
    if (mute) {
      track = null;
    }
    let sender = this.getSender(kind);
    if (sender) {
      sender.replaceTrack(track);
    } else {
      if (!mute) {
        this.pc.addTrack(track);
      }
    }
  }

  private getSender(kind: string): RTCRtpSender {
    let sender = this.senders.get(kind);
    if (!sender) {
      this.pc.getSenders().forEach(s => {
        if (s.track && s.track.kind === kind) {
          sender = s;
          this.senders.set(kind, s);
        }
      });
    }
    return sender;
  }

  public async streamHandlerOnStreamChangeEvent(stream: MediaStream) {
    // console.error(new Error());
    // todo refactor
    console.log('Proxy media chnage new Stream ', stream);
    console.log('Proxy media chnage orig stream ', this.mediaStream);

     console.log('Proxy media chnage tracks new ', stream.getTracks());
      console.log('Proxy media change tracks orig ', this.mediaStream.getTracks());
    let nvtrack: MediaStreamTrack = null;
    let ovtrack: MediaStreamTrack = null;
    let nvid: string = null;
    let ovid: string = null;
    let vtracks = stream.getVideoTracks();
    if (vtracks && vtracks.length > 0) {
      nvtrack = vtracks[0];
      if (nvtrack.readyState === 'live') {
        nvid = nvtrack.id;
      } else {
        nvtrack = null;
      }
    }
    vtracks = this.mediaStream.getVideoTracks();
    if (vtracks && vtracks.length > 0) {
      ovtrack = vtracks[0];
      ovid = ovtrack.id;
    }

    if (ovid !== nvid) {
           console.log('ProxySvc: Main: changing video stream ', ovtrack, nvtrack);
      let sndr = this.getSender('video');
      if (nvtrack) {
        if (sndr) {
          sndr.replaceTrack(nvtrack);
        } else {
                console.error('Proxy, adding video track');
          this.mediaStream.getTracks().forEach(track => { this.mediaStream.removeTrack(track) });
          stream.getTracks().forEach(track => { this.mediaStream.addTrack(track) });
          this.dataChannelOpen = false;
          this.dataChannel = null;
          let oldPC = this.pc;
          this.init(null);
          oldPC.close();
          this.updateBandwidth();

        }
      } else {
        if (sndr) {
                   console.error('***********************************Proxy: removing vidio track from pc');
          sndr.replaceTrack(null);
          // this.pc.removeTrack(sndr);
        } else {
          console.warn(' we do not have a video sender');
        }
      }
      if (ovtrack) {
        this.mediaStream.removeTrack(ovtrack);
      }
    }

    // now audio
    let natrack: MediaStreamTrack = null;
    let oatrack: MediaStreamTrack = null;
    let naid: string = null;
    let oaid: string = null;
    let atracks = stream.getAudioTracks();
    if (atracks && atracks.length > 0) {
      natrack = atracks[0];
      if (natrack.readyState === 'live') {
        naid = natrack.id;
      } else {
        console.log('Seeting audio track to null because not live')
        natrack = null;
      }
    }
    atracks = this.mediaStream.getAudioTracks();
    if (atracks && atracks.length > 0) {
      oatrack = atracks[0];
      oaid = oatrack.id;
    }

    console.error('New audio track', natrack);
    console.error('Old audio track', oatrack);

    if (oaid !== naid) {
            console.log('ProxySvc: Main: changing audio stream ', oatrack, natrack);
      let sndr = this.getSender('audio');
      if (natrack) {
        if (sndr) {
          sndr.replaceTrack(natrack);
        } else {
                 console.error('Proxy, adding audio track');
          this.mediaStream.getTracks().forEach(track => { this.mediaStream.removeTrack(track) });
          stream.getTracks().forEach(track => { this.mediaStream.addTrack(track) });
          let oldPC = this.pc;
          this.init(null);
          oldPC.close();
          this.updateBandwidth();

        }
      } else {
        if (sndr) {
                  console.error('***********************************Proxy: removing audio track from pc');
          sndr.replaceTrack(null);
          // this.pc.removeTrack(sndr);
        } else {
          console.warn(' we do not have a audio sender');
        }
      }

      if (natrack) {
        this.mediaStream.addTrack(natrack);
        this.analyser.proxyReConnect([natrack]);
      }
      if (oatrack) {
        this.mediaStream.removeTrack(oatrack);
      }
    }
  }


  public close() {
    if (this.closed) {
      console.warn('Allready closed');
      return;
    }
    this.closed = true;
    try {
      if (this.pc) {
        this.pc.close();
      }
      this.pc = null;
      this.senders.clear();
      // this.mediaStream.getTracks().forEach(track => { track.stop() });
      this.analyser.proxyDispose();
      this.analyser = null;
    } catch (err) {
      console.error(err);
    } finally {
      this.negotiating = false;
      this.proxySvc.mainClosed();
    }
    this.dataChannel = null;
    this.messageQueue = null;
  }

  private connected() {
    this.isConnected = true;
    console.log('8 8 8 8 8 8 Mainproxy connection: Peers connected!');
    this.proxySvc.initReady();
  }

  private disconnected() {
    console.log('8 8 8 8 8 8 Mainproxy connection: Peers disconnected!');
    this.closed = false;
    this.proxySvc.initFail();
    this.close();
  }

  async processProxyCmd(pkt: Pkt) {
    console.log('MainConnection: processProxyCmd', pkt);
    switch (pkt.cmd) {
      case 'answer':
        const remoteDesc = { sdp: <string>pkt.data, type: <RTCSdpType>'answer' };
        await this.pc.setRemoteDescription(remoteDesc);
        this.negotiating = false;
        break;
      case 'ice':
        await this.pc.addIceCandidate(JSON.parse(pkt.data));
        break;
      case 'turnurl':
        this.init(pkt.data);
        break;
      default:
        console.log('MainConnection: ProcessProxyCmd: unknown cmd ' + pkt.cmd, pkt);
    }


  }

  socketRemotelyClosed(): void {
    console.log('Method not implemented.');
  }
  socketClosed(): void {
    console.log('Method not implemented.');
  }

}

class RtcProxyConnectionImp implements RtcProxyConnection {

  public disposed = false;
  private listeners: any[] = [];
  public connectionID: string;
  private state = 'connecting';
  private closed: boolean;


  constructor(private socket: Socket, private index: number, private proxySvc: RtcproxyService) {
    this.connectionID = socket.connectionID;
  }

  private offer() {
    let pkt = new Pkt(this.connectionID, 'start', undefined);
    this.proxySvc.sendPktToProxySvr(pkt);
  }

  processRtcReceivedConnectionPacket(packet: any) {
    //  console.log('ProxySvc: RtcProxyConnectionImp: sending to proxy server', packet);
    if (packet.index === this.index) {
      let type = packet.type;
      let pkt: Pkt;
      switch (type) {
        case 'accept':
          console.error('ProxySvc: RtcProxyConnectionImp: ProcessRecievedConnectionPAcket: accept');
          this.offer();
          break;
        case 'sdpAnswer':
          console.error('ProxySvc: RtcProxyConnectionImp: ProcessRecievedConnectionPAcket: answer');
          pkt = new Pkt(this.connectionID, 'answer', packet.data.answer.sdp);
          this.proxySvc.sendPktToProxySvr(pkt);
          break;
        case 'ice':
          pkt = new Pkt(this.connectionID, 'ice', JSON.stringify(packet.data));
          this.proxySvc.sendPktToProxySvr(pkt);
          break;
        case 'offer':
          pkt = new Pkt(this.connectionID, 'offer', packet.data.offer.sdp);
          this.proxySvc.sendPktToProxySvr(pkt);
          break;
        default:
          console.error('ProxySvc: RtcProxyConnectionImp: ProcessRecievedConnectionPAcket: unknown type ' + type, packet);

      }
    }
  }

  processProxyCmd(pkt: Pkt) {
    console.log('ProxySvc: RtcProxyConnectionImp: processProxyCmd: sending to socket ' + this.index, pkt);
    let cmd = pkt.cmd;
    switch (cmd) {
      case 'offer':
        let offer = {
          index: this.index,
          type: 'offer',
          data: {
            bw: 2000,
            offer: {
              type: 'offer',
              sdp: pkt.data
            }
          }
        }
        this.socket.send(JSON.stringify(offer));
        break;

      case 'answer':
        let answer = {
          index: this.index,
          type: 'sdpAnswer',
          data: {
            bw: 2000,
            answer: {
              type: 'answer',
              sdp: pkt.data
            }
          }
        }
        this.socket.send(JSON.stringify(answer));
        break;

      case 'ice':
        let ice = {
          index: this.index,
          type: 'ice',
          data: JSON.parse(pkt.data)
        }
        this.socket.send(JSON.stringify(ice));
        break;
      case 'state':
        this.state = pkt.data;
        this.listeners.forEach(listener => {
          listener.rtcStateChange(this.state);
        });
        break;
      default:
        console.error('ProxySvc: RtcProxyConnectionImp: processProxyCmd: invalid cmd ' + cmd);
    }
  }

  processDataMessage(event: any, type: string) {
    let data = JSON.parse(event.data);
    if (data.hasOwnProperty('videoMute')) {
      console.error('Video mute!!!!!!!!!!!!');
      this.masterMuteLocalVideo(data.videoMute);
    } else if (data.hasOwnProperty('audioMute')) {
      console.error('Audio mute!!!!!!!!!!!!');
      this.masterMuteLocalAudio(data.audioMute);
    }
    this.listeners.forEach(listener => {
      listener.processProxyDataMessage(event, type);
    });
  }


  getState(): string {
    return this.state;
  }

  getAnalyser() {
    return this.proxySvc.main.analyser;
  }

  addListener(listener: any): void {
    this.listeners.push(listener);
  }

  hold(_hold: boolean): void {
    console.log('hold Method not implemented.');
  }

  sendChatMessage(_connectionID: string, _message: string): void {
    throw new Error('Method not implemented.');
  }

  close(): void {
    if (this.closed) {
      return;
    }
    this.closed = true;
    try {
      this.state = 'closed';
      this.listeners.forEach((listener: any) => {
        listener.rtcClosed(this);
      });

      let pkt = new Pkt(this.connectionID, 'close', undefined);
      this.proxySvc.sendPktToProxySvr(pkt);

      this.listeners.length = 0;
    } catch (err) {
      console.error(err);
    } finally {
      this.proxySvc.closeProxyConnection(this);
    }
  }

  masterMuteLocalAudio(mute: boolean): void {
    let pkt = new Pkt(this.connectionID, 'muteAudio', mute ? 'true' : 'false');
    this.proxySvc.sendPktToProxySvr(pkt);
  }

  masterMuteLocalVideo(mute: boolean): void {
    let pkt = new Pkt(this.connectionID, 'muteVideo', mute ? 'true' : 'false');
    this.proxySvc.sendPktToProxySvr(pkt);
  }

  setBandwidth(bw: number): void {
    let pkt = new Pkt(this.connectionID, 'bw', '' + bw);
    this.proxySvc.sendPktToProxySvr(pkt);
  }

}

@Injectable({
  providedIn: 'root'
})
export class RtcproxyService implements SocketListener {
  public connections: Map<string, RtcProxyConnectionImp> = new Map(); // key is destination
  public main: MainConnection = null;
  proxySocket: Socket = null;
  private resolvers: ((value?: unknown) => void)[] = [];
  private rejecters: ((reason?: any) => void)[] = [];
  closed = true;
  videoMuted: boolean;
  audioMuted: boolean;
  private rtcService: any;
  private pktqueue: Pkt[] = [];
  private sendingPKts = false;
  private initRan = false;
  mediaStream: MediaStream;

  constructor(public apiSvc: EqcallapiService, private netSvc: NetworkService, private streamHandler: StreamhandlerService) {
  }

  public muteVideo(mute: boolean) {
    if (this.videoMuted !== mute) {
      this.videoMuted = mute;
      if (!this.closed && this.main) {
        this.main.muteVideo(mute);
      }
    }
  }
  public muteAudio(mute: boolean) {
    if (this.audioMuted !== mute) {
      this.audioMuted = mute;
      if (!this.closed && this.main) {
        this.main.muteAudio(mute);
      }
    }
  }

  public async getProxy(socket: Socket, index: number, service: any, audioMuted: boolean, videoMuted: boolean, proxyData: any) {
    this.muteAudio(audioMuted);
    this.muteVideo(videoMuted);
    if (!this.initRan) {
      await this.init(service, proxyData);
    }
    let dest = socket.connectionID;
    let proxy = new RtcProxyConnectionImp(socket, index, this);
    this.connections.set(dest, proxy);
    return proxy
  }

  private async init(service: any, proxyData: any) {
    this.initRan = true;
    this.closed = false;
    this.rtcService = service;
    if (!proxyData) {
      proxyData = await this.apiSvc.getProxy();
      if (!proxyData) {
        this.initRan = false;
        throw new Error('error geting proxy config');
      }
    }
    console.log('ProxySvc: init: got proxy data', proxyData);

    if (!this.mediaStream) { // might have a race
      this.mediaStream = <MediaStream>await service.getUserMedia();

      console.log('Proxy Media stream', this.mediaStream);
      this.main = new MainConnection(this.mediaStream, this, service.bandwidthKb, this.streamHandler);
      this.opensocket(proxyData);
      this.sendPktToProxySvr({ cmd: 'turnurl', id: 'Main', data: undefined });
    }
    return new Promise((res, reject) => {
      this.resolvers.push(res);
      this.rejecters.push(reject);
    });
  }

  public setBandwidth(kb: number) {
    if (this.main) {
      this.main.setBandwidth(kb);
    }
  }

  public isProxying(): boolean {
    return this.initRan;
  }

  initReady() {
    this.closed = false;
    if (this.resolvers.length > 0) {
      this.resolvers.forEach((res: any) => { res() });
    }
    this.resolvers.length = 0;
    this.rejecters.length = 0;
  }

  initFail() {
    this.closed = false;
    if (this.rejecters.length > 0) {
      this.rejecters.forEach((res: any) => { res() });
    }
    this.resolvers.length = 0;
    this.rejecters.length = 0;
  }

  processDataMessage(event: MessageEvent) {
    console.log('Got Data Channel Message:', event);
    let data = JSON.parse(event.data);
    let id = data.id;
    console.log('ProxySvc: datachannel: id ' + id);
    let connection = this.connections.get(id);
    if (connection) {
      connection.processDataMessage(event, 'messages');
    } else {
      console.error('ProxySvc: processDataMessage: unknown connection id', event);
    }
  }

  private opensocket(key: any) {
    //  console.log('Proxy open socket ', key);
    let serveraddr = key.serverAddress;
    this.proxySocket = this.netSvc.connect(serveraddr, key.userAddress, 'proxy', JSON.stringify(key));
    this.proxySocket.setTimeout(20000);
    this.proxySocket.addListener(this);
    this.proxySocket.connect();
    return this.proxySocket;
  }



  async sendPktToProxySvr(pkt: Pkt) {
    // console.error('ProxtSvc: sendPktTpProxySvr: ', pkt);
    if (!this.proxySocket) {
      this.pktqueue.push(pkt);
    } else {
      if (!this.sendingPKts && this.pktqueue.length > 0) {
        this.sendingPKts = true;
        console.log('Sending pkt queue');
        this.pktqueue.forEach(p => { this.proxySocket.send(JSON.stringify(p)); });
        this.pktqueue.length = 0;
        this.sendingPKts = false;
      }
      this.proxySocket.send(JSON.stringify(pkt));
    }
  }

  closeProxyConnection(client: RtcProxyConnectionImp) {
    console.log('Closing procy client');
    let id = client.connectionID;
    this.connections.delete(id);
    if (this.connections.size === 0 && this.main) {
      this.main.close();
    }
  }

  mainClosed() {
    if (this.closed) {
      console.warn('Already closed');
      return;
    }
    this.closed = true;
    console.log('ProxySvc: this.mainClosed');
    this.connections.forEach((client) => { client.close() });
    this.connections.clear();
    if (this.proxySocket) {
      this.proxySocket.close();
      this.proxySocket = null;
    }
    this.rtcService.streamHandler.returnMediaStream(this.main.stream);
    this.mediaStream = null;
    this.initRan = false;
    this.main = null;
  }

  streamHandlerOnStreamChangeEvent(stream: MediaStream) {
    if (this.closed) {
      return;
    }
    if (this.main) {
      this.main.streamHandlerOnStreamChangeEvent(stream);
    }
  }

  socketOnRecieve(data: string, _socket: Socket): void {
    let pkt = JSON.parse(data);
    let id = pkt.id;
    if (id === 'Main') {
      this.main.processProxyCmd(pkt);
    } else {
      let connection = this.connections.get(id);
      if (connection) {
        connection.processProxyCmd(pkt);
      } else {
        console.error(' Socket.OnReceive: No client for pkt', pkt);
      }
    }
  }

  socketRemotelyClosed(): void {
    console.log('Proxy: Socket closed remotely');
    this.initFail();
    this.main.close();
  }

  socketClosed(): void {
    console.log('Proxy: socket closed locally');
    this.socketRemotelyClosed();
  }
}
