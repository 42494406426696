import { Component, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { TranslationService } from '../../../../service/translation.service';
@Component({
  selector: 'app-countryregion',
  templateUrl: './countryregion.component.html',
  styleUrls: ['../../../../../assets/css/custom.css']
})
export class CountryregionComponent implements AfterViewInit {
  static loaded = false;
  static loading: any;
  public id = Date.now();
  public _currentCountry: string;
  public _currentRegion: string;

  @Output() countryChange: EventEmitter<string> = new EventEmitter();
  @Output() regionChange: EventEmitter<string> = new EventEmitter();

  @Input()
  set currentRegion(region: string) {
    console.log('SetRegion', region);
    if (region) {
      this._currentRegion = region.toUpperCase();
      if ((<any>window).crs) {
        (<any>window).crs.init();
      }
    }
  }

  @Input()
  set curentCountry(country: string) {
    console.log('SetCountry', country);
    if (country) {
      this._currentCountry = country.toUpperCase();
      if ((<any>window).crs) {
        (<any>window).crs.init();
      }
    }
  }

  constructor(public tr: TranslationService) {
  }

  ngAfterViewInit(): void {
    this.load();
  }

  setCountry(cn: string) {
    console.log(cn);
    this.countryChange.emit(cn);
  }

  setRegion(re: string) {
    console.log(re);
    this.regionChange.emit(re);
  }

  private async load() {
    let scriptElement: HTMLScriptElement;
    if (CountryregionComponent.loaded) {
      return;
    }
    if (CountryregionComponent.loading) {
      await new Promise(res => setTimeout(res, 250));
      await this.load();
      return;
    }
    CountryregionComponent.loading = true;

    await new Promise(resolve => {
      scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src = 'assets/js/crs.min.js'
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    });
    (<any>window).crs.init();
  }
}
