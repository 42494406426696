<!-- Group Options Modal -->
<div #addReomveModal class="modal fade" id="groupAddReomveModal"
	tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">{{tr.get("contact","editg")}}:
					{{nickname}}</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form>
					<div class="form-group" style="margin-top: -10px;">
						<div *ngIf="isOwner">
							<div class="d-flex flex-row">
								<div class="p-2">
									<label for="group-name" class="col-form-label">Group
										Name:</label>
								</div>
							</div>
							<input [(ngModel)]="nickname" type="text" class="form-control"
								id="group-name" name="groupName" [placeholder]="namePlaceholder"
								style="margin-top: -5px; margin-bottom: -10px;">
						</div>
						<div class="d-flex flex-row-reverse">
							<div class="p-2">
								<div *ngIf="isOwner">
									<label for="isOwner" class="col-form-label"
										style="padding-right: 10px">I am the Group
										Administrator:</label> <input id="isOwner" [(ngModel)]="isOwner"
										type="checkbox" class="checkbox-template mt-3"
										data-placement="top" title="Owner" name="isOwner">
								</div>
								<div *ngIf="!isOwner">
									<label class="col-form-label" style="padding-right: 10px">{{type}}</label>
								</div>
							</div>
						</div>
					</div>
					<hr style="margin-top: -15px">
					<!-- remove elements when not showing -->
					<div *ngIf="addRemoveModal" style="margin-top: -25px;">
						<div class="form-group">
							<div class="d-flex flex-row">
								<div class="p-2">
									<label for="group-name" class="col-form-label"
										style="margin-bottom: -5px;">Current Members:</label>
								</div>
							</div>
							<div class="d-flex flex-row">
								<div class="p-2" *ngIf="members < 1">
									<label for="group-name" class="col-form-label"
										style="margin-bottom: -5px;"><small
										style="color: #fff">This Group has no Members yet.</small></label>
								</div>
							</div>
							<div *ngFor="let member of members">
								<app-group-members-element [canEdit]="canEdit"
									[messenger]="member" [group]="group">
								</app-group-members-element>
							</div>
							<div *ngIf="canEdit">
								<div class="d-flex flex-row">
									<div class="p-2">
										<label for="group-name" class="col-form-label"
											style="margin-bottom: -5px;">Available Members:</label>
									</div>
								</div>
								<div class="d-flex flex-row">
									<div class="p-2" *ngIf="notmembers < 1">
										<label for="group-name" class="col-form-label"
											style="margin-bottom: -5px;"><small
											style="color: #fff">You do not have any Contacts yet
												to add to a Group.</small></label>
									</div>
								</div>
								<div *ngFor="let member of notMembers">
									<app-group-members-element [canEdit]="canEdit"
										[messenger]="member" [group]="group">
									</app-group-members-element>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div *ngIf="canEdit"
				class="modal-footer d-flex justify-content-center">
				<button (click)="save()" type="button" class="btn btn-warning"
					data-dismiss="modal" data-placement="top"
					[title]="tr.get('contact','unblockn')">{{tr.get("button","save")}}</button>
			</div>
		</div>
	</div>
</div>
