import { CognitoUtil, UserLoginService } from './cognito.service';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
declare var apigClientFactory: any;

export class Member {
    public address: string;
    public nickname: string;
    constructor(nickname: string, address: string) {
        this.address = address;
        this.nickname = nickname;
    }
}

export class Group {
    id: string;
    owner: Member;
    name: string;
    members: Member[];
}

export class QueueMsg {
    type: string;
    date: string;
    message: string;
}

export class PushRegistration {
    subscription: PushSubscription;
    registrationType = 'web';
    registrationId = '-';
    constructor(public deviceID: string) {
    }
}


@Injectable()
export class EqcallapiService {
    private queueMessagesCache: QueueMsg[];
    private apigClient: any;
    constructor(private cognitoUtil: CognitoUtil, private userService: UserLoginService, private configSvc: ConfigService) {
    }

    public getApigClient(): any {
        if (!this.apigClient) {
            this.apigClient = apigClientFactory.newClient({ 'invokeUrl': this.configSvc.apiInvokeUrl });
        }
        return this.apigClient;
    }

    private async getAuthToken() {
        return this.cognitoUtil.getIdTokenPromise();
    }
    public async updateMailingAddress(body: any) {
        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().currentuserMailingaddressPost(params, body, additionalParams);
        });
    }

    public async setPermissions(permissions: string[]) {
        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().currentuserPermissionsPost(params, permissions, additionalParams);
        });
    }

    public async getPermissions() {
        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().currentuserPermissionsGet(params, body, additionalParams);
        });
    }

    public async getCallLog(year: number, month: number) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                year: year,
                month: month
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().accountCallsYearMonthGet(params, body, additionalParams);
        });
    }

    public async getTwilioToken() {
        const keyCode = this.userService.getKeyCode();

        if (keyCode == null) {

            return this.getAuthToken().then((idToken) => {
                const params = {

                };

                const body = {};

                const additionalParams = {
                    headers: {
                        Authorization: idToken
                    }
                };

                return this.getApigClient().twilioTokenGet(params, body, additionalParams);
            });
        } else {
            const params = {
                keycode: keyCode
            };

            const body = {};

            const additionalParams = {
            };

            return this.getApigClient().twilioTokenKeycodeGet(params, body, additionalParams);
        }
    }

    public async transferToHostedCall(callSid: string, phoneNumber: string) {
        const body = {
            callSid: callSid,
            phoneNumber: phoneNumber
        };
        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().twilioTransfercallPost(params, body, additionalParams);
        });
    }

    public async makeHostedCall(userName: string, keyCode: string, phoneNumber: string, token: any) {
        const body = {
            userName: userName,
            keyCode: keyCode,
            phoneNumber: phoneNumber,
            token: token
        };

        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().hostedcallsPhonePut(params, body, additionalParams);
        });
    }

    public async getKeys() {
        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().keysGet(params, body, additionalParams);
        });
    }

    public async getKey(keyCode: string) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                id: keyCode
            };
            const body = {};
            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().keysIdGet(params, body, additionalParams);
        });

    }

    public async getKeyCodeByDomainName(domainName: string) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                dn: domainName
            };
            const body = {};
            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().keysFqdnDnGet(params, body, additionalParams);
        }).then((data) => { return data.data });
    }

    public async deleteKey(keyCode: string) {
        console.log('delete key ' + keyCode);
        return this.getAuthToken().then((idToken) => {
            const params = {
                id: keyCode
            };
            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };
            return this.getApigClient().keysIdDelete(params, body, additionalParams);
        });
    }

    public async deleteFQDN(domainName: string) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                dn: domainName
            };
            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };
            return this.getApigClient().keysFqdnDnDelete(params, body, additionalParams);
        });
    }

    public async createKey(hours: number) {
        return this.getAuthToken().then((idToken) => {
            const params = {
            };
            const body = {
                hours: hours
            };
            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };
            return this.getApigClient().keysPost(params, body, additionalParams);
        });
    }

    public async updateKey(key: any) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                id: key.keyCode
            };
            const body = JSON.stringify(key)
            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };
            return this.getApigClient().keysIdPut(params, body, additionalParams);
        });
    }

    public async setFQDN(key: any, domainName: string) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                dn: domainName
            };
            const body = JSON.stringify(key)
            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };
            return this.getApigClient().keysFqdnDnPut(params, body, additionalParams);
        });
    }

    public async createFQDN(domainName: string) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                dn: domainName
            };
            const body = {};
            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };
            return this.getApigClient().keysFqdnDnPost(params, body, additionalParams);
        });
    }

    public async getDomains() {
        const ret = await this.getAuthToken().then((idToken) => {
            const params = {
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().keysFqdnGet(params, body, additionalParams);
        });

        return ret.data;
    }

    public async getContacts() {
        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().contactsGet(params, body, additionalParams);
        });
    }

    public async requestContact(email: string) {
        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const body = {
                emailAddress: email
            };

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().contactsPost(params, body, additionalParams);
        });
    }

    public async acceptContact(address: string) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                id: address
            };

            const body = {
            };

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().contactsIdPut(params, body, additionalParams);
        });
    }

    public async removeContact(address: string) {
        console.log('API: removing contact ' + address)
        return this.getAuthToken().then((idToken) => {
            const params = {
                id: address
            };

            const body = {
            };

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().contactsIdDelete(params, body, additionalParams);
        });
    }

    public async getCurrentUser() {
        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().currentuserGet(params, body, additionalParams);
        });
    }

    public async updatePolicy(cognitoID: string) {
        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const body = {
                'principal': cognitoID
            };

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().updatepolicyPost(params, body, additionalParams);
        });
    }

    public async deleteAccount() {
        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const body = {
            };

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().updatepolicyPost(params, body, additionalParams);
        });
    }

    public async addCreditCard(stripeToken: any) {
        console.log('adding card', stripeToken);
        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const body = stripeToken.id

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().accountCreditcardsPost(params, body, additionalParams);
        });
    }

    public async getCreditCards() {
        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().accountCreditcardsGet(params, body, additionalParams);
        });
    }

    public async deleteCard(cardID: string) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                id: cardID
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().accountCreditcardsIdDelete(params, body, additionalParams);
        });
    }

    public async setDefaultCard(cardID: string) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                id: cardID,
                type: 'default',
                expDate: '0',
                cvc: '0'
            };

            const body = {};

            const additionalParams = {

                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().accountCreditcardsIdPut(params, body, additionalParams);
        });
    }

    public async updateCard(cardID: string, expDate: string, cvc: string) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                id: cardID,
                type: 'update',
                expDate: expDate,
                cvc: cvc
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().accountCreditcardsIdPut(params, body, additionalParams);
        });
    }

    public async validateNumber(phonenumber: string) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                phonenumber: phonenumber
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().twilioValidateNumberPhonenumberGet(params, body, additionalParams);
        });
    }

    public async getIceConfiguration() {
        // check for key, if so, get unauthenticated turn info
        const keyCode = this.userService.getKeyCode();
        console.warn('KeyCode=', keyCode);
        if (!keyCode) {
            return this.getAuthToken().then((idToken) => {
                const params = {
                };

                const body = {};

                const additionalParams = {
                    headers: {
                        Authorization: idToken
                    }
                };

                return this.getApigClient().turnAuthenticatedGet(params, body, additionalParams);
            });
        } else {
            const params = {
                keyCode: keyCode
            };

            const body = {};

            const additionalParams = {};

            return this.getApigClient().turnUnauthenticatedGet(params, body, additionalParams);
        }
    }

    public async getInvoice() {
        console.log('getInvoice');
        return this.getAuthToken().then((idToken) => {
            const params = {
                count: 1
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().accountInvoiceGet(params, body, additionalParams);
        });
    }

    public async getInvoices(count: number) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                count: count
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().accountInvoiceGet(params, body, additionalParams);
        });
    }

    public async rechargeAccount(paymentMethodID: string, amountInCents: string) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                amount: amountInCents,
                paymentMethodID: paymentMethodID
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().accountInvoicePut(params, body, additionalParams);
        });
    }

    public async setAutoRecharge(amount: number, level: number) {

        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const body = {
                amount: amount,
                level: level
            };

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().accountAutorechargePost(params, body, additionalParams);
        });
    }

    public async getAutoRecharge() {
        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const body = {

            };

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().accountAutorechargeGet(params, body, additionalParams);
        });
    }

    public async setPushSubscription(sub: PushRegistration) {
        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const body = JSON.stringify(sub);

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().pushPost(params, body, additionalParams);
        });
    }

    public async sendPushMessage(contactID: string, title: string, message: string, actions: any) {
        console.log('Pushing message ' + message + ' to ' + contactID, actions);
        return this.getAuthToken().then((idToken) => {
            const params = {
                contactID: contactID
            };

            let body;

            if (actions) {
                body = {
                    title: title,
                    body: message,
                    action: actions
                }
            } else {
                body = {
                    title: title,
                    body: message
                }
            }
            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().pushContactIDPost(params, body, additionalParams);
        });
    }

    public async pushUnsubscribe(deviceID: string) {
        console.log('Deleting pushSubscription ', deviceID);
        return this.getAuthToken().then((idToken) => {
            const params = {
                contactID: deviceID
            };

            const body = {
            }

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().pushContactIDDelete(params, body, additionalParams);
        });
    }

    public async setOnlineState(state: String) {
        console.log('Setting online state');
        return this.getAuthToken().then((idToken) => {
            const params = {
                state: state
            };

            const body = {
            }

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };
            return this.getApigClient().currentuserOnlinestatePut(params, body, additionalParams);
        });
    }

    public async callPolicyUpdate(cognitoID: string) {
        return this.getAuthToken().then((idToken) => {
            const params = {
            };
            const body = {
                'principal': cognitoID
            };
            const additionalParams = {
                headers: {
                    Authorization: idToken
                },
            };
            this.getApigClient().updatepolicyPost(params, body, additionalParams)
                .then(function (_result: any) {
                    console.log('callPolicyUpdate: Policy updated');
                }).catch(function (result: any) {
                    console.warn('callPolicyUpdate: errr :', result);
                });
        }, null);
    }

    public async updateContact(contact: any) {

        return this.getAuthToken().then((idToken) => {
            const params = {
                id: contact.destAddress
            };

            const body = {
                nickname: contact.nickname,
                isBlocked: contact.isBlocked
            };

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().contactsIdPost(params, body, additionalParams);
        });
    }

    public async deleteGroup(groupID: string) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                groupID: groupID
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().groupGroupIDDelete(params, body, additionalParams).then((data: any) => { return data.data });
        });
    }

    public async getGroup(groupID: string) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                groupID: groupID
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().groupGroupIDGet(params, body, additionalParams).then((data: any) => { return data.data });
        });
    }

    public async getGroups() {
        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().groupGet(params, body, additionalParams).
                catch((err: any) => { console.warn(err) }).then((data: any) => { if (data) { return data.data } else { return null } });
        });
    }

    public async updateGroup(group: Group) {

        return this.getAuthToken().then((idToken) => {
            const params = {
                groupID: group.id
            };

            const body = JSON.stringify(group);

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().groupGroupIDPut(params, body, additionalParams);
        });
    }

    public async createGroup(group: Group) {

        return this.getAuthToken().then((idToken) => {
            const params = {
                groupID: group.id
            };

            const body = JSON.stringify(group);

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().groupPost(params, body, additionalParams);
        });
    }

    public async getKeyPair() {
        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().cryptokeyGet(params, body, additionalParams).then((data: any) => { return data.data });
        });
    }

    postKeyCryptoKeys(keyPair: any, keyCode: string): any {
        return this.getAuthToken().then((idToken) => {
            const params = {
                keyid: keyCode
            };

            const body = JSON.stringify(keyPair);

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().keycryptokeysKeyidPost(params, body, additionalParams).then((data: any) => { return data.data });
        });
    }

    postnewKeyPair(keyPair: any): any {
        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const body = JSON.stringify(keyPair);

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().cryptokeyPost(params, body, additionalParams).then((data: any) => { return data.data });
        });
    }

    /**
     *
     * @param ID
     * @param idType 'keyID' or 'address' to define what ID is;
     *
     * returns KeyPair without private key for the supplied ID or User Address
     */
    public async getCryptoKeyByID(ID: string, idType: string) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                id: ID,
                idtype: idType
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().cryptokeyIdGet(params, body, additionalParams).then((data: any) => { return data.data });
        });
    }

    public async getCryptoKeysByAddresses(addresses: string[]) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                ids: addresses,
                idtype: 'address'
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().cryptokeysGet(params, body, additionalParams).then((data: any) => { return data.data });
        });

    }



    public async getQueueMessages(): Promise<QueueMsg[]> {
        if (this.queueMessagesCache) {
            return this.queueMessagesCache;
        }
        this.queueMessagesCache = await this.getAuthToken().then((idToken) => {
            const params = {
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().currentuserQueueGet(params, body, additionalParams).then((data: any) => { return data.data });
        });
        setTimeout(() => { this.queueMessagesCache = null }, 5000);
        return this.queueMessagesCache;
    }

    public async delteQueueMessage(id: string) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                id: id,
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().currentuserQueueIdDelete(params, body, additionalParams).then((data: any) => { return data.data });
        });
    }

    public async updateUser(map: any) {
        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const body = map;

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().currentuserPatch(params, body, additionalParams).then((data: any) => { return data.data });
        });
    }

    public async getProxy() {
        return this.getAuthToken().then((idToken) => {
            const params = {
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().proxyGet(params, body, additionalParams).then((data: any) => { return data.data });
        });
    }


    public async getcontactProxy(address: string) {
        return this.getAuthToken().then((idToken) => {
            const params = {
                address: address
            };

            const body = {};

            const additionalParams = {
                headers: {
                    Authorization: idToken
                }
            };

            return this.getApigClient().proxyAddressGet(params, body, additionalParams).then((data: any) => { return data.data });
        });
    }

    public async changeConfirmationAddress(userName: string, address: string, type: string) {
        const params = {
            userName: userName,
            address: address,
            type: type,
        };

        const body = {};

        const additionalParams = {
            // headers: {
            //     Authorization: idToken
            // }
        };

        return this.getApigClient().confirmationaddressPut(params, body, additionalParams).then((data: any) => { return data.data });
    }
}


