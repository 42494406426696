import { UserLoginService } from '../../../../service/cognito.service';
import { Contact, ContactsService } from '../../../../service/contacts.service';
import { EqcallapiService } from '../../../../service/eqcallapi.service';
import { SystemBusService, MessageObserver } from '../../../../service/system-bus.service';
import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core';
import 'intl-tel-input';
import { TranslationService } from '../../../../service/translation.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['../../../../../assets/css/custom.css']
})
export class ProfileComponent implements OnInit, OnDestroy, AfterViewInit, MessageObserver {

    private intelTel: any;
    public showpersonal = false;
    public showbusiness = false;
    public showacc = false;
    public showsecurity = false;
    public contact: Contact;
    public callerID: string;
    public countrydata: any[] = []
    private _show = 'NONE';
    public next = 'NONE';
    @Input()
    set show(show: string) {
        this._show = show
    }

    ngAfterViewInit(): void {
        if (this._show !== 'NONE') {
            this.setShow();
        }

    }

    get show(): string { return this._show; }
    constructor(private contactSvc: ContactsService, private userSvc: UserLoginService,
        private api: EqcallapiService, private systemBus: SystemBusService, public tr: TranslationService) {
        this.systemBus.subscribe(this);
    }

    ngOnInit() {
        this.contact = this.contactSvc.localContact[0];
    }

    ngOnDestroy(): void {
        this.contact = null;
        this.countrydata = null;
        this.systemBus.unSubscribe(this);
    }

    private setShow() {

        let parts = this._show.split(',');
        let us = parts[0];
        if (parts.length > 1) {
            this.next = this._show.substring(this._show.indexOf(',') + 1);
        }

        if (us === 'PERSONAL') {
            (<any>$('#collapsePersonal')).collapse('show');
        } else if (us === 'BUSINESS') {
            (<any>$('#collapseBusiness')).collapse('show');
        } else if (us === 'ACCOUNT') {
            (<any>$('#collapseAccount')).collapse('show');
        } else if (us === 'SECURITY') {
            (<any>$('#collapsePassword')).collapse('show');
        }
    }

    public onBusMessage(message: any, _type: string): void {
        this.contact = message;
    }

    public busMessageFilter(messageType: string): boolean {
        return (messageType === 'contacts/gotlocalContact');
    }

    public togglePersonal() {
        this.showpersonal = !this.showpersonal;
    }

    public toggleBusiness() {
        this.showbusiness = !this.showbusiness;
    }
    public toggleAcc() {
        this.showacc = !this.showacc;
    }

    public toggleSecurity() {
        this.showsecurity = !this.showsecurity;
    }

    public async submitPersonal() {
        const result = await this.userSvc.updateUserEmail(this.contact.email);
        console.error('Change Email result', result);
        $('body').css('cursor', 'progress');
        this.api.updateMailingAddress(JSON.stringify(this.contact.mailingAddress)).
            then(() => { $('body').css('cursor', 'default') }).catch(err => {
                $('body').css('cursor', 'default');
                this.sendMessage('Error updating address: ' + err.data);

            });
        if (result === 'NOCHANGE') {
            this.api.updateUser({ nickname: this.contact.nickname });
        } else {
            this.api.updateUser({ nickname: this.contact.nickname, email: this.contact.email });
        }
    }

    public submitBusiness() {
        console.error('bsubmit')
    }

    public submitAccount() {
        console.error('asubmit')
    }

    public submitSecurity(currentPass: string, pass: string, confirm: string) {
        if (pass !== confirm) {
            const mesg = {
                type: 'info',
                message: 'Passwords do not match',
                timeOut: 15,
            };
            this.systemBus.emit(mesg, 'warning');
        } else {
            this.userSvc.changePassword(currentPass, pass).then(() => {
                const mesg = {
                    type: 'info',
                    message: 'Password Changed',
                    timeOut: 15,
                };
                this.systemBus.emit(mesg, 'warning');
            }).catch((err) => {
                console.warn(err);
                const mesg = {
                    type: 'info',
                    message: 'Error changing password: ' + err.message,
                    timeOut: 15,
                };
                this.systemBus.emit(mesg, 'warning');
            });
        }
    }
    public setNumber(callerID: string) {
        this.callerID = callerID;
    }

    public setIntelTel(intelTel: any) {

        this.intelTel = intelTel;
        if (this.contact.callerID) {
            this.intelTel.setNumber(this.contact.callerID);
        }
        setTimeout(() => { this.countrydata = window.intlTelInputGlobals.getCountryData(); }, 500)
    }

    public validate() {
        $('body').css('cursor', 'progress');
        this.api.validateNumber(this.callerID).then(
            result => {
                this.contact.callerID = this.callerID;
                console.log(result);
                const msg = { type: 'info', message: 'Your code is ' + result.data.code };
                this.systemBus.emit(msg, 'warning');
                $('body').css('cursor', 'default');
            }).catch(err => {
                console.error(err); const msg = { type: 'info', message: err.data, timeOut: 15 };
                this.systemBus.emit(msg, 'warning');
                this.callerID = '';
                this.intelTel.setNumber('');
                $('body').css('cursor', 'default');
            });
    }

    public countryChange(country: string) {
        console.log('Profile: ', country);
        this.contact.mailingAddress.country = country;
    }
    public regionChange(region: string) {
        console.log('Profile: ', region);
        this.contact.mailingAddress.region = region;
    }

    private sendMessage(message: string) {
        const mesg = {
            type: 'info',
            message: message,
            timeOut: 15,
        };
        this.systemBus.emit(mesg, 'warning');
    }

}
