<app-ringer></app-ringer>
<!-- Test Devices Modal -->
<app-audiotest></app-audiotest>
<!-- Remote Control Request Modal -->
<div class="modal fade" id="remoteControlRequest" tabindex="-1"
	role="dialog" aria-labelledby="remoteControlRequestTitle"
	aria-hidden="true" style="pointer-events: none">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document"
		style="z-index: 100">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="remoteControlRequestLongTitle">{{tr.get("popup","remoteReq.title")}}</h5>
				<button (click)="remoteRequestResponse(false)" type="button"
					class="close" data-dismiss="modal" aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>{{tr.get("popup","remoteReq.from")}}
					{{remoteRequest.connection.remoteNickName}}.</p>

				<p>{{tr.get("popup","remoteReq.msg")}}</p>
				<p>{{remoteRequest.message}}</p>
			</div>
			<div class="modal-footer">
				<button type="button" (click)="remoteRequestResponse(false)"
					class="btn btn-secondary" data-dismiss="modal">{{tr.get("button","no")}}</button>
				<button type="button" (click)="remoteRequestResponse(true)"
					class="btn btn-primary">{{tr.get("button","yes")}}</button>
			</div>
		</div>
	</div>
</div>

<!-- Permissions Request Modal -->
<div class="modal fade" id="permissionsRequest" tabindex="-1"
	role="dialog" aria-labelledby="permissionsRequestTitle"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document"
		style="z-index: 101">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="permissionsRequestLongTitle">{{tr.get("popup","perm.title")}}</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>{{tr.get("popup","perm.msg")}}</p>
			</div>
			<div class="modal-footer">
				<button type="button" (click)="devicePerms()"
					class="btn btn-primary">{{tr.get("button","ok")}}</button>
			</div>
		</div>
	</div>
</div>

<!-- Device access denied Modal -->
<div class="modal fade" id="permissionsNotice" tabindex="-1"
	role="dialog" aria-labelledby="permissionsNoticeTitle"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document"
		style="z-index: 102">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="permissionsNoticeLongTitle">{{tr.get("popup","perm.title")}}</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>{{tr.get("popup","accessdenied.msg")}}</p>
			</div>
			<div class="modal-footer">
				<button type="button" (click)="showPermsSite()"
					class="btn btn-primary">{{tr.get("button","ok")}}</button>
			</div>
		</div>
	</div>
</div>

<!-- Install new version Request Modal -->
<div class="modal fade" id="newVersionRequest" tabindex="-1"
	role="dialog" aria-labelledby="newVersionRequestTitle"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document"
		style="z-index: 103">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="newVersionRequestLongTitle">{{tr.get("popup","newVer.title")}}</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>{{tr.get("popup","newVer.msg")}}</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-dismiss="modal">{{tr.get("popup","newVer.later")}}</button>
				<button type="button" (click)="reload()" class="btn btn-primary">{{tr.get("popup","newVer.now")}}</button>
			</div>
		</div>
	</div>
</div>

<!-- Push Notification Request Modal -->
<div class="modal fade" id="notificationRequest" tabindex="-1"
	role="dialog" aria-labelledby="notificationRequestTitle"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document"
		style="z-index: 104">
		<div class="modal-content ">
			<div class="modal-header">
				<h5 class="modal-title" id="notificationRequestLongTitle">{{tr.get("popup","notif.title")}}</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>{{tr.get("popup","notif.msg")}}</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-dismiss="modal">{{tr.get("button","no")}}</button>
				<button type="button" (click)="notificationSubscribe()"
					class="btn btn-primary">{{tr.get("button","yes")}}</button>
			</div>
		</div>
	</div>
</div>

<!-- PWA Install Request Modal -->
<div class="modal fade" id="installPWARequest" tabindex="-1"
	role="dialog" aria-labelledby="installRequestTitle" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document"
		style="z-index: 105">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="installRequestLongTitle">{{tr.get("popup","install.title")}}</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>{{tr.get("popup","install.msg")}}</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-dismiss="modal">{{tr.get("button","no")}}</button>
				<button type="button" (click)="installPWA()" class="btn btn-primary">{{tr.get("button","yes")}}</button>
			</div>
		</div>
	</div>
</div>

<!-- Create account Request Modal -->
<div class="modal fade" id="registerRequest" tabindex="-1" role="dialog"
	aria-labelledby="createAccountTitle" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document"
		style="z-index: 106">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="createAccountLongTitle">{{tr.get("popup","create.title")}}</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>{{tr.get("popup","create.msg")}}</p>
			</div>
			<div class="modal-footer">
				<button type="button" (click)="logout()" class="btn btn-secondary"
					data-dismiss="modal">{{tr.get("button","no")}}</button>
				<button type="button" (click)="register()" class="btn btn-primary">{{tr.get("button","yes")}}</button>
			</div>
		</div>
	</div>
</div>

<!-- get user event to start video -->
<div class="modal fade" id="videoStartRequest" tabindex="-1"
	role="dialog" aria-labelledby="videoRequestTitle" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document"
		style="z-index: 101">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="videoStartRequestLongTitle">{{tr.get("popup","perm.title")}}</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>Start video</p>
			</div>
			<div class="modal-footer">
				<button type="button" (click)="startVideo()" class="btn btn-primary">{{tr.get("button","ok")}}</button>
			</div>
		</div>
	</div>
</div>

<!-- Add Remove Members Modal -->
<app-group-members [group]="group"></app-group-members>

<!-- Delete Account Modal -->
<div class="modal fade" id="deleteAccountModal" tabindex="-1"
	role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">{{tr.get("account-profile","account-closeq")}}
				</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>Are you sure you would like to delete your eqCall account?
					This action cannot be undone.</p>
			</div>
			<div class="modal-footer d-flex justify-content-center">
				<button (click)="deleteAccount()" type="button"
					class="btn btn-warning" data-dismiss="modal">Delete it!</button>
				<button type="button" class="btn btn-primary" data-dismiss="modal">Cancel
					Delete</button>
			</div>
		</div>
	</div>
</div>

<!--  Request input Modal -->
<div class="modal fade" id="inputRequestModel" tabindex="-1"
	role="dialog" aria-labelledby="inputRequestTitle" aria-hidden="true"
	style="pointer-events: none">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document"
		style="z-index: 109">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="inputRequestLongTitle">{{inputRequest.title}}</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No" (click)="inputRequestSubmit(null)">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>{{inputRequest.message}}</p>
				<div class="fb-form">
					<form action="#" class="form-group">
						<input #inputRequestInput class="form-control" type="text">
						<button type="button" class="form-control btn btn-warning"
							data-dismiss="modal"
							(click)="inputRequestSubmit(inputRequestInput.value)">{{inputRequest.buttonTxt}}</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>