import { Component, OnInit, Input } from '@angular/core';
import { TranslationService } from '../../../../../service/translation.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['../../../../../../assets/css/custom.css']
})
export class InvoiceComponent implements OnInit {
 @Input() invoice: any;
  constructor( public tr: TranslationService) { }

  ngOnInit(): void {
  }

}
