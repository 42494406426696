import { Component, Output, EventEmitter, OnDestroy, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { RtcConnection } from '../../../../service/rtc.service';
import { TranslationService } from '../../../../service/translation.service';
import { MixerService } from '../mixerService/mixer.service';
import { ChannelElementComponent } from '../channel-element/channel-element.component';
import { CropEvent, CropState, VideocropperctlDirective } from '../../videocropperctl.directive';
import { CropSettings } from '../../../../service/streamhandler.service';
import { SystemBusService } from '../../../../service/system-bus.service';

@Component({
    selector: 'app-channel-controller',
    templateUrl: './channel-controller.component.html',
    styleUrls: ['../mixer.component.css']
})
export class ChannelControllerComponent implements OnDestroy, AfterViewInit {

    @Output() controlEvent = new EventEmitter();
    @ViewChildren(VideocropperctlDirective) videoCroppers!: QueryList<VideocropperctlDirective>;
    videoCropper: VideocropperctlDirective = null;

    public connection: RtcConnection = null;
    public label = '';
    private sanal = false;
    public controlled = false;
    public requestAnswer = false;
    videoHeight: number;
    videoWidth: number;
    private mixer: ChannelElementComponent;
    cropping = false;
    videoShowing = false;
    private cropSettings: CropSettings;

    constructor(private mixerSvc: MixerService, public tr: TranslationService, private sysBus: SystemBusService) {
        this.mixerSvc.registerController(this);
    }
    ngAfterViewInit(): void {
        this.videoCroppers.changes.subscribe((r) => {
            console.error('Sub fired', r);
            this.videoCropper = r.first;
            this.initializeCropping();
        });
    }

    ngOnDestroy(): void {
        this.showAnalyser(false);
        this.showVideo(false);
        this.connection = null;
        this.mixerSvc.registerController(null);
    }

    private showVideo(show: boolean) {
        this.videoShowing = show;
        if (this.connection) {
            const video = <HTMLVideoElement>document.getElementById('mixerAnalyserVideo');
            if (show) {
                video.srcObject = this.connection.getOutputStream();
                this.videoHeight = video.height;
                this.videoWidth = video.width;
                this.showAnalyser(this.sanal);
            } else if (video) {
                video.srcObject = null;
            }
        }
    }

    private showAnalyser(show: boolean) {
        if (this.connection) {
            const canvas = <HTMLCanvasElement>document.getElementById('mixerAnalyserVisualizer');
            if (canvas) {
                if (show) {
                    this.connection.getVisualizer().setCanvas(canvas);
                    this.connection.startRemoteVisualizer(true);
                } else {
                    console.log('Stopping Analyser');
                    this.connection.startRemoteVisualizer(false);
                    let ctx = canvas.getContext('2d');
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                }
            }
            this.sanal = show;
        }
    }

    public videoClick() {
        this.showVideo(!this.videoShowing);
    }

    public analyserClick(event: any) {
        const el = $(event.currentTarget);
        if (el.hasClass('on')) {
            el.removeClass('on');
            this.showAnalyser(false);
        } else {
            this.showAnalyser(true);
            el.addClass('on');
        }
    }

    public cropClick() {
        this.cropping = !this.cropping;
        if (!this.videoShowing) {
            this.showVideo(true);
        }

        if (!this.controlled) {
            this.controlDevices();
        }
        this.videoCropper.resetCropping();
        if (this.requestAnswer) {
            this.videoCropper.enableCropping(this.cropping);
        }
    }

    private setRtcConnection(connection: RtcConnection) {
        if (this.connection) {
            if (this.connection === connection) {
                return;
            }
            this.connection.startRemoteVisualizer(false);
        }

        console.log('old Connection = ', this.connection);
        console.log('new connection ', connection);

        this.connection = connection;
        if (connection) {
            this.label = connection.getRemoteNickName();
            this.showVideo(this.videoShowing);
            this.showAnalyser(this.sanal);
            this.controlled = connection.getAreRemoteControlling();
            this.cropSettings = connection.getIsRemoteVideoCropping()
            this.cropping = this.cropSettings ? true : false;

            this.requestAnswer = this.controlled;
            console.log('Set controlling ', this.controlled);
        } else {
            this.controlled = false;
            this.requestAnswer = false;
            this.cropping = false;
            this.label = 'None Selected';
        }

        if (this.videoCropper) {
            this.initializeCropping();
        } else {
            console.error('No video cropper yet');
        }
    }

    private initializeCropping() {
        if (this.cropping && this.requestAnswer) {
            this.showVideo(true);
            if (this.cropSettings) {
                this.videoCropper.setCropping(this.cropSettings);
            } else {
                this.videoCropper.enableCropping(this.cropping);
            }
        }
    }

    public setSelectedMixer(mixer: ChannelElementComponent) {
        console.log('setting mixer', mixer);

        if (!mixer) {
            this.setRtcConnection(null);
            this.mixer = null;
            this.controlled = false;
            this.requestAnswer = false;
        } else {
            this.setRtcConnection(mixer.rtcConnection);
            this.mixer = mixer;
        }
    }

    // called by mixerSvc when a mixer leaves
    public mixerRemoved(mixer: ChannelElementComponent) {
        if (mixer === this.mixer) {
            this.setSelectedMixer(null);
        }
    }

    public controlDevices() {
        this.controlled = !this.controlled;
        this.controlEvent.emit(this.controlled);
    }

    public requestStatus(answer: any) {
        this.requestAnswer = answer;
        console.error('RequestStatus called', this.requestAnswer);
        if (!this.requestAnswer) {
            this.controlled = false;
            this.cropping = false;
            const message = {
                type: 'warning',
                message: 'Controll request denied',
                timeOut: 20,
            }
            this.sysBus.emit(message, 'warning');
        } else {
            this.initializeCropping();
        }
    }

    videoPlaying(event: any) {
        this.videoWidth = event.target.videoWidth;
        this.videoHeight = event.target.videoHeight;
    }

    // events from cropping directive
    cropEvent(event: CropEvent) {
        console.log('CropEvent called', event);
        if (event.state === CropState.CROPPING) {
            console.log('Cropping');
            this.connection.setRemoteVideoCrop(event.settings);
        } else {
            console.log('Done cropping');
            //  this.cropping = false;
        }
    }
}
