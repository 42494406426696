import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TranslationService } from './translation.service';
import { SystemBusService } from './system-bus.service';

@Injectable({
    providedIn: 'root'
})

export class ConfigService {
    public region = environment.region;
    public identityPoolId = environment.identityPoolId;
    public userPoolId = environment.userPoolId;
    public appId = environment.appId;
    public vapidKey = environment.vapidKey;
    public iotEndpoint = environment.iotEndpoint;
    public apiInvokeUrl = environment.apiInvokeUrl;
    public build_version = 'Development';
    public stripeKey = environment.stripeKey;
    public redirectUriSI = environment.redirectUriSI;
    public redirectUriSO = environment.redirectUriSO;
    public oauthDomain = environment.oauthDomain;
    public id: string;
    private config = new Map<string, string>();

    constructor(private http: HttpClient, private tr: TranslationService, private sysBus: SystemBusService) {

    }

    public setID(id: string) {
        this.id = id;
        this.load();
    }

    async init(data: any) {
        // console.log('config init called', data);
        if (!data) {
            let js = localStorage.getItem('EQCfg');
            if (js) {
                data = JSON.parse(js);
            } else {
                await this.tr.init(this);
                return;
            }
        }

        if (data) {
            //  console.log('Configuration loaded: ', data);
            this.region = data.region;
            this.identityPoolId = data.identityPoolId;
            this.userPoolId = data.userPoolId;
            this.appId = data.appId;
            this.vapidKey = data.vapidKey;
            this.iotEndpoint = data.iotEndpoint;
            this.apiInvokeUrl = data.apiInvokeUrl;
            this.stripeKey = data.stripeKey
            this.redirectUriSI = data.redirectUriSI;
            this.redirectUriSO = data.redirectUriSO;
            this.oauthDomain = data.oauthDomain;
            localStorage.setItem('EQCfg', JSON.stringify(data));
        }
        this.http.get('./build_version.txt', { responseType: 'text' }).toPromise().then(vdata => {
            // console.log('Build=', vdata);
            this.build_version = vdata
        }).catch(err => { console.error(err) });
        await this.tr.init(this);
    }

    private load() {
        if (!this.id) {
            console.error('Config: ID not set ');
            this.id = 'EQCALL';
        }
        const cfg = localStorage.getItem(this.id);
        if (cfg) {
            this.config = new Map(JSON.parse(cfg));
        } else {
            console.log('Config: load: No configuration found ' + this.id);
        }

        this.sysBus.emit(this, 'config/init');
    }

    private store() {
        if (!this.id) {
            console.error('Config: ID not set ');
            this.id = 'EQCALL';
        }
        let str = JSON.stringify(Array.from(this.config.entries()));
        localStorage.setItem(this.id, str);
    }

    public getItem(key: string): string {
        if (!this.id) {
            console.error('Config: ID not set ' + key, new Error());
        }
        return this.config.get(key);
    }

    public async setItem(key: string, value: string) {
        if (!this.id) {
            console.error('Config: ID not set ' + key, new Error());
        }
        this.config.set(key, value);
        this.store();
    }

    public clear() {
        if (!this.id) {
            console.error('Config: ID not set ', new Error());
            return;
        }
        this.config.clear();
        this.store()
    }

    public async removeItem(key: string) {
        this.config.delete(key);
        this.store();
    }

    private uuid() {
        let i, random;
        let result = '';

        for (i = 0; i < 32; i++) {
            random = Math.random() * 16 | 0;
            if (i === 8 || i === 12 || i === 16 || i === 20) {
                result += '-';
            }
            result += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random))
                .toString(16);
        }
        return result;
    };
}
