import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RtcConnection } from '../../../../../service/rtc.service';
import { TranslationService } from '../../../../../service/translation.service';
import { MixerService } from '../../mixerService/mixer.service';
import { ConfigService } from '../../../../../service/config.service';

export class Device {
    constructor(public text: string, public id: string) {
    }
}

@Component({
    selector: 'app-device-panel',
    templateUrl: './device-panel.component.html',
    styleUrls: ['./device-panel.component.css', '../../../../../../assets/css/custom.css']
})
export class DevicePanelComponent implements OnInit {
    private connection: RtcConnection = null;

    public showVideo = false;
    public useHQAudio = false;
    public fullScreen = false;
    public buttonText = 'Start';
    public videoInputs: Device[] = [];
    public audioInputs: Device[] = [];
    public audioOutputs: Device[] = [];
    public resolutions = [
        { desc: 'Default', width: 0, height: 0, idx: 0 },
        { desc: 'Small 4:3', width: 320, height: 240, idx: 1 },
        { desc: 'Medium 4:3', width: 640, height: 480, idx: 2 },
        { desc: 'Small 16:9', width: 640, height: 360, idx: 3 },
        { desc: 'Medium 16:9', width: 896, height: 504, idx: 4 },
        { desc: '720p', width: 1280, height: 720, idx: 5 },
        { desc: 'HD', width: 1920, height: 1080, idx: 6 }];
    public height = 0;
    public requestPending = false;
    public requestUnsent = false;
    public beingControlled = false;
    private videoDeviceID: any;
    private audioDeviceID: any;
    private audioOutDeviceID: any;
    videoResolution: { desc: string; width: number; height: number; idx: number; };
    requestMessage: string;

    @Output() requestEvent = new EventEmitter();

    @Input()
    set rtcConnection(rtcConnection: RtcConnection) {
        this.connection = rtcConnection;
        if (!rtcConnection) {
            return;
        }
        if (rtcConnection.getAreRemoteControlling()) {
            this.init();
        } else {
            this.requestControl();
        }
    }

    get rtcConnection(): RtcConnection {
        return this.connection;
    }

    constructor(public tr: TranslationService, private mixerSvc: MixerService, private config: ConfigService) { }

    ngOnInit() {
    }

    public requestControl() {
        console.error('Requext Control ', this);
        let reqMsg: string;
        if (typeof (Storage) !== 'undefined') {
            reqMsg = this.config.getItem('remoteCtlMsg');
        }
        if (!reqMsg) {
            reqMsg = 'Please let me manage your devices for this call';
        }
        this.requestMessage = reqMsg;

        (<any>$('#remoteControlRequestMsg')).appendTo('body').modal('show');
    }

    public sendRequest(_request: boolean) {
        console.error('Send Request Control ', this);
        (<any>$('#remoteControlRequestMsg')).modal('hide');
        this.requestPending = true;
        if (this.requestMessage) {
            this.connection.requestRemoteControl(this.requestMessage).
                then((answer: boolean) => { this.requestControlAnswer(answer) }).
                catch(err => { console.error(err) });
            this.config.setItem('remoteCtlMsg', this.requestMessage);
        }
    }

    private async requestControlAnswer(answer: boolean) {
        console.error('got answer ' + answer)
        if (answer) {
            this.init();
            this.requestPending = false;
            this.beingControlled = true;
             this.requestEvent.emit(this.beingControlled);
        } else {
            this.mixerSvc.selectionChanged(null);
             this.requestEvent.emit(false);
        }
    }

    private async init() {
        await this.rtcConnection.listDevices().
            then((devinfo) => this.setInfo(devinfo))
    }

    private setInfo(devinfo: any) {
        this.updateSelectors(devinfo);
    }

    public async updateSelectors(dev: any) {
        console.log('DeviceSelector: Devices=' + JSON.stringify(dev));
        this.audioDeviceID = dev.audioDeviceID;
        this.audioOutDeviceID = dev.audioOutDeviceID;
        this.videoDeviceID = dev.videoDeviceID;
        this.useHQAudio = dev.hqAudio;
        this.height = dev.videoHeight;
        this.resolutions.forEach((res) => { if (res.height === dev.videoHeight) { this.videoResolution = res } });
        for (let i = 0; i !== dev.devicesInfos.length; ++i) {
            const deviceInfo = dev.devicesInfos[i];

            if (deviceInfo.kind === 'audioinput') {
                let device = new Device(deviceInfo.label ||
                    'microphone ' + (this.audioInputs.length + 1), deviceInfo.deviceId)
                if (this.audioDeviceID === deviceInfo.deviceId) {
                    console.log('got current audio In device');
                    this.audioInputs.splice(0, 0, device);
                } else {
                    this.audioInputs.push(device);
                }
            } else if (deviceInfo.kind === 'audiooutput') {

                let device = new Device(deviceInfo.label ||
                    'speaker ' + (this.audioOutputs.length + 1), deviceInfo.deviceId);
                if (this.audioOutDeviceID === deviceInfo.deviceId) {
                    console.log('got current audio Out device');
                    this.audioOutputs.splice(0, 0, device);
                } else {
                    this.audioOutputs.push(device);
                }
            } else if (deviceInfo.kind === 'videoinput') {
                let device = new Device(deviceInfo.label ||
                    'camera ' + (this.videoInputs.length + 1), deviceInfo.deviceId);
                if (this.videoDeviceID === deviceInfo.deviceId) {
                    console.log('got current video device');
                    this.videoInputs.splice(0, 0, device);
                } else {
                    this.videoInputs.push(device);
                }
            } else {
                console.log('Some other kind of source/device: ', deviceInfo);
            }
        }
    }

    public async videoInChange(id: string) {
        this.videoDeviceID = id;
        this.update();
    }

    public videoRes(idx: number) {
        this.videoResolution = this.resolutions[idx];
        console.log('Res=', this.videoResolution)
        this.update();
    }

    public audioInChange(id: string) {
        this.audioDeviceID = id;
        this.update();
    }

    public audioOutChange(sinkId: string) {
        this.audioOutDeviceID = sinkId;
        this.update();
    }

    public useHQAudioSettings(_event: any) {
        this.update();
    }

    private update() {
        let ret = {
            videoDeviceID: this.videoDeviceID,
            audioDeviceID: this.audioDeviceID,
            audioOutDeviceID: this.audioOutDeviceID,
            hqAudio: this.useHQAudio,
            videoRes: { width: this.videoResolution.width, height: this.videoResolution.height }
        }
        console.warn(ret);
        this.connection.setRemoteDeviceSettings(ret);
    }

    public requestCancelled() {
        this.requestUnsent = true;
        this.requestEvent.emit(this.requestUnsent);
    }
}
