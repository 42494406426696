import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SystemBusService, MessageObserver } from '../../../../service/system-bus.service';
import { TranslationService } from '../../../../service/translation.service';
import { Messenger, MessengerGroup } from '../../../../service/message.service';
import { Member } from '../../../../service/eqcallapi.service';

@Component({
    selector: 'app-messenger-list-element',
    templateUrl: './messenger-list-element.component.html',
    styleUrls: ['../../../../../assets/css/custom.css']
})
export class MessengerListElementComponent implements OnInit, OnDestroy, MessageObserver {
    @Input() messenger: Messenger;
    @Input() inCall: boolean;
    isAnon: boolean;
    name: string;
    isSelected = false;
    public isContact = true;
    public isGroup = false;
    public members: Messenger[] = [];
    public owner: Member;

    constructor(private systemBus: SystemBusService, public tr: TranslationService) {
        this.systemBus.subscribe(this);
    }

    ngOnInit() {
        this.isContact = this.messenger.messengerType === 'contact';
        this.isGroup = this.messenger.messengerType === 'group';
        this.isAnon = this.messenger.isAnon;
        let keyCode = this.messenger.keyCode;
        if (this.isAnon && (keyCode)) {
            this.name = keyCode + ' : ' + this.messenger.nickname;
        } else {
            this.name = this.messenger.nickname;
        }
        if (this.isGroup) {
            let group = <MessengerGroup>this.messenger;
            this.members = group.messengers;
            this.owner = group.owner;
        }
    }

    ngOnDestroy(): void {
        this.systemBus.unSubscribe(this);
        this.messenger = null;
        this.members = null;
    }

    public clicked() {
        this.systemBus.emit(this.messenger, 'messengerSelected');
        this.messenger.unreadMessageCount = 0;
    }

    public selected(event: any) {
        console.log('Selected ' + this.name + ' ' + this.isSelected, event);
        if (this.isSelected) {
            this.systemBus.emit(this.messenger, 'contactListContactIsSelected-true');
        } else {
            this.systemBus.emit(this.messenger, 'contactListContactIsSelected-false');
        }
    }

    public acceptRequest(accept: boolean) {
        this.messenger.acceptRequest(accept);
    }

    onBusMessage(_message: any, _type: string): void {
        this.isSelected = false;
    }

    busMessageFilter(messageType: string): boolean {
        return messageType === 'contactList/Selection/Cleared';
    }
}
