import {
    Component, Input, AfterViewInit, OnDestroy, ChangeDetectorRef, ViewChildren, ElementRef, ViewChild, QueryList
} from '@angular/core';
import { TranslationService } from '../../../service/translation.service';
import { MessageService, Messenger, Message } from '../../../service/message.service';

@Component({
    selector: 'app-chat-window',
    templateUrl: './chat-window.component.html',
    styleUrls: ['../../../../assets/css/custom.css']
})
export class ChatWindowComponent implements AfterViewInit, OnDestroy {

    public showEmojiPicker = false;
    public messages: Message[] = [];
    private typeingTimer: NodeJS.Timer;
    private isTyping = false;
    private inputElement: HTMLInputElement;
    @Input() messenger: Messenger;
    @Input() rtcButtons: boolean;
    @Input() inCall: boolean;
    @ViewChildren('messagesdiv') messagesdiv: QueryList<any>;
    @ViewChild('contentdiv') contentdiv: ElementRef;

    constructor(private cd: ChangeDetectorRef, private messageService: MessageService, public tr: TranslationService) {
    }

    ngAfterViewInit() {
        this.inputElement = <HTMLInputElement>document.getElementById('chatInput');
        this.messages = this.messenger.messages;
        this.cd.detectChanges();
        this.scrollToBottom();
        this.messagesdiv.changes.subscribe(this.scrollToBottom);
    }

    ngOnDestroy(): void {
        this.messages = null;
    }

    scrollToBottom = () => {
        try {
            this.contentdiv.nativeElement.scrollTop = this.contentdiv.nativeElement.scrollHeight;
        } catch (err) {
            console.error(err)
         }
    }

    sendMessageButton(message: string) {
        this.isTyping = false;
        this.showEmojiPicker = false;
        if (message && message.length > 0) {
            this.messageService.sendMessage(message, this.messenger);
        }
    }

    sendMessage(message: string, event: any) {
        this.showEmojiPicker = false;
        if (event.key === 'Enter') {
            this.sendMessageButton(message);
        } else {
            this.typeing();
        }
    }

    public toggleEmojiPicker() {
        this.showEmojiPicker = !this.showEmojiPicker;
    }

    public handleEmoji(event: any) {
        console.log('handleemoji ', event);
        let c = this.inputElement.value;
        let pos = this.inputElement.selectionStart
        c = [c.slice(0, pos), event.char, c.slice(pos)].join('');
        this.inputElement.value = c;
    }

    public handleCharDelete(event: any) {
        console.log('handleemojiDelete ', event);
    }

    private typeing() {
        if (this.typeingTimer) {
            clearTimeout(this.typeingTimer);
        }
        this.typeingTimer = setTimeout(() => {
            this.messageService.sendMessage('^NoTtYpInG', this.messenger);
        }, 10000);
        if (!this.isTyping) {
            this.isTyping = true;
            this.messageService.sendMessage('^TyPiNg', this.messenger);
        }
    }
}
