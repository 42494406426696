<div class="row no-gutters">
	<div *ngIf="canEdit" class=" multi-collapse button-overlay">
		<div class="col-auto">
			<input (change)="selected($event)" [(ngModel)]="isSelected"
				type="checkbox" class="checkbox-template mt-3" data-placement="top"
				[title]="name">
		</div>
	</div>
	<div class="col">
		<button type="button"
			class="btn-outline-contact btn-lg btn-block contact-whitespace"
			[title]="name">
			{{name}} <span *ngIf="messenger.unreadMessageCount > 0" class="badge">
				{{messenger.unreadMessageCount}} </span>
		</button>
	</div>
</div>
