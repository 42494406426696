import {KeyService, Key} from '../../../service/key.service';
import {Component} from '@angular/core';
import { TranslationService } from '../../../service/translation.service';

@Component({
  selector: 'app-keylinks',
  templateUrl: './keylinks.component.html',
  styleUrls: ['../../../../assets/css/custom.css']
})

export class KeylinksComponent {
  public keys: Key[];

  constructor( public keySvc: KeyService, public tr: TranslationService) {
   this.keys = this.keySvc.keys;
  }

  public genkey() {
    this.keySvc.createKey(1);
  }

}

