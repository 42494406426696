import { Component, OnInit, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { SystemBusService, MessageObserver } from '../../../../service/system-bus.service';
import { TranslationService } from '../../../../service/translation.service';
import { MixerService } from '../mixerService/mixer.service';
import { StreamhandlerService } from '../../../../service/streamhandler.service';
import { LivekitService } from '../../../../service/livekit.service';
@Component({
    selector: 'app-main-channel',
    templateUrl: './main-channel.component.html',
    styleUrls: ['../mixer.component.css']
})
export class MainChannelComponent implements OnInit, AfterViewInit, MessageObserver, OnDestroy {

    public volumeLevel = 0;
    public volLevelID = 'MasterVolumeLevelID';
    private volEl: JQuery<HTMLElement>;
    private volLevelEl: HTMLCanvasElement;
    private volIisDragging: any;
    private volLastPosY: number;
    private volumectx: CanvasRenderingContext2D;
    public soloed: boolean;
    public muted = false;

    @Input() vuMeterStatus: boolean;
    @Input() showVolume: boolean;
    @Input() showMixer: boolean;

    constructor(public rtcSvc: LivekitService, public systemBus: SystemBusService,
         private mixerSvc: MixerService, public tr: TranslationService, private streamHandler: StreamhandlerService) {
        this.muted = streamHandler.masterAudioMute;
    }

    ngOnInit() {
        this.systemBus.subscribe(this);
    }
    ngOnDestroy(): void {
        this.systemBus.unSubscribe(this);
    }

    ngAfterViewInit(): void {
        this.volEl = $('#volMasterChannelID');
        this.volLevelEl = <HTMLCanvasElement>document.getElementById(this.volLevelID);
        this.volumectx = this.volLevelEl.getContext('2d');
        this.streamHandler.getVuMeter().addSoundLevelListener(this);
        let vol = this.rtcSvc.getMasterVolume();
        this.setRemoteVolume(vol);
    }

    public async setSoundLevel(level: number) {
        let start = Math.round(level * 100);
        if (start === 0) {
            start = 1;
        }
        //  start = start * 0.5; // it's a little hyper
        let adjusted = start * (this.volLevelEl.height / 100);
        this.volumeLevel = adjusted;
        let diff = this.volLevelEl.height - adjusted;

        this.volumectx.clearRect(0, 0, this.volLevelEl.width, this.volLevelEl.height);
        this.volumectx.beginPath();
        this.volumectx.rect(0, diff, this.volLevelEl.width, adjusted);
        let colour = '#a01818';
        if (start < 20) {
            colour = '#164187';
        } else if (start < 90) {
            colour = '#166b27';
        }
        this.volumectx.fillStyle = colour;
        this.volumectx.fill();
    }

    faderPan(ev: any) {
        console.log(ev);
        const el = this.volEl;

        if (!this.volIisDragging) {
            this.volIisDragging = true;
            this.volLastPosY = el.position().top;
        }

        // we simply need to determine where the x,y of this
        // object is relative to where it's "last" known position is
        // NOTE:
        //    deltaX and deltaY are cumulative
        // Thus we need to always calculate 'real x and y' relative
        // to the "lastPosX/Y"

        let top = ev.deltaY + this.volLastPosY;
        if ((top >= -35) && (top <= 260)) {
            el.css('top', (top) + 'px');
            this.setRemoteVolume(top);
        }
        if (ev.isFinal) {
            this.volIisDragging = false;
        }

    }
    private setRemoteVolume(top: number): number {
        console.log('******************');
        console.log('ftop=' + top);
        // plus 35 so we have a positive range to deal with - by default the range is -35 to 260
        top += 35;
        // top top is 0 top bottom is 295
        // zero is + 65
        // volume is from 0 to 100 is normal
        // 0 == 100 295 = 0
        // make range go from low of zero to a high of 295
        top = 295 - top
        // 0 == 0, 230=100, 295 = 200
        //  console.log('adj top=' + top);
        let volume = 100;
        volume = top * 0.33898
        console.log('cal volume=' + volume);
        // TODO debounce
        if (volume > 100) {
            volume = 100;
        }
        this.rtcSvc.setMasterVolume(volume);
        return volume;
    }

    faderDblClick(event: any) {
        console.log('fader dblClick ', event);
        const el = $(event.currentTarget);
        el.css({
            'top': '30px'
        });
        el.trigger('fader', (0 + 35 + el.position()
            .top));
    }

    soloClick(event: any) {
        const el = $(event.currentTarget);
        if (el.hasClass('off')) {
            el.removeClass('off');
            this.soloed = false;
        } else {
            this.soloed = true;
            el.addClass('off');
        }
        // mute everyone else and unmute us;
        if (this.soloed) {
            this.mixerSvc.mixers.forEach((mixer) => { mixer.rtcConnection.muteRemoteAudio(true) });
            this.streamHandler.setMasterAudioMute(false);
        } else {
            this.streamHandler.setMasterAudioMute(this.muted);
            this.mixerSvc.soloEvent();
        }
    }

    muteClick(event: any) {
        console.log(event);
        this.muted = !this.muted;
        this.streamHandler.setMasterAudioMute(this.muted)
        if (this.muted && this.soloed) {
            this.soloed = false;
            this.mixerSvc.soloEvent();
        }
    }

    onBusMessage(message: any, type: string): void {
        if (type === 'rtc/masterAudioMute' && !this.soloed) {
            this.muted = message;
        }
    }

    busMessageFilter(messageType: string): boolean {
        return (messageType === 'rtc/masterAudioMute');
    }
}
