import { Injectable } from '@angular/core';
import { EqcallapiService } from './eqcallapi.service';
import { SystemBusService, MessageObserver } from './system-bus.service';
import { UserLoginService } from './cognito.service';
import { ConfigService } from './config.service';

export class KeyPair {
    public address: string;
    public keyID: string;
    constructor(public publicKey: string, public privateKey: string, public iv: string, public salt: string) { }
}

@Injectable({
    providedIn: 'root'
})
export class CryptoService implements MessageObserver {

    // look up tables
    private to_hex_array: any[] = [];
    private to_byte_map: any = {};
    private privateKey: CryptoKey;
    private ready = false;
    private publicKey: CryptoKey;
    private sourceKeyID: string;
    private pwd: string = null;
    private keyCode: string = null;
    private loadingKeys = false;
    private keyMap = new Map();
    private idAddressMap = new Map();
    private addressIdMap = new Map();
    private storeMessages: any[] = [];
    private preloadAddresses: string[];

    constructor(private api: EqcallapiService, public systemBus: SystemBusService,
        private userLoginSvc: UserLoginService, private config: ConfigService) {
        systemBus.subscribe(this);
        // populate look up tables
        for (let ord = 0; ord <= 0xff; ord++) {
            let s = ord.toString(16);
            if (s.length < 2) {
                s = '0' + s;
            }
            this.to_hex_array.push(s);
            this.to_byte_map[s] = ord;
        }
    }

    public async encryptData(destAddress: string, clearText: string) {
        if (!this.ready) {
            //  console.log('CryptoService: encryptData: waiting until ready');
            await this.loadUserKeys();
            await new Promise((resolve) => {
                this.storeMessages.push(resolve);
            });
        }
        //  console.log('CryptoService: encryptData: ', destAddress, clearText);
        let key = await this.getKeyByAddress(destAddress);
        if (!key) {
            return null;
        }
        // console.log('CryptoService: encryptData: key', key);
        let cipherObject = await this.encrypt(key, clearText);
        let wrapped = this.wrapCypherText(cipherObject);

        return wrapped;
    }

    public async decryptData(cipherObject: { c: string, i: string, id: string }): Promise<{ clearText: string; address: string; }> {
        if (!this.ready) {
            //    console.log('CryptoService: decryptData: waiting until ready');
            await this.loadUserKeys();
            await new Promise((resolve) => {
                this.storeMessages.push(resolve);
            });
        }
        //  console.log('CryptoService: decryptData ', cipherObject);
        const keyID = cipherObject.id;
        if (keyID === this.sourceKeyID) {
            console.warn('Recieving our own message', this.keyMap, cipherObject);
            return null;
        }
        let key = await this.getKeyByID(keyID);
        if (key) {
            let unwrapped = this.unWrapCypherText(cipherObject);
            let clearText = await this.decrypt(key, unwrapped.iv, unwrapped.cipherText);
            return { clearText: clearText, address: this.idAddressMap.get(keyID) };
        } else {
            console.log('Could not decrpty data, no key ', this.keyMap, cipherObject);
            return null;
        }
    }

    // public async updateCredentials(password: string) {
    //     // TODO implement
    // }

    onBusMessage(message: any, type: string): void {
        // We might need the password later
        if (type === 'user/loggedIn' && !this.pwd) {
            this.pwd = message;
        } else if (type === 'keyCodeSet') {
            this.keyCode = message;
        } else if (type === 'contacts/gotlocalContact' && this.pwd) {
             this.loadUserKeys();
        }
    }

    busMessageFilter(messageType: string): boolean {
        return (messageType === 'user/loggedIn' ||
            messageType === 'keyCodeSet' ||
            messageType === 'contacts/gotlocalContact')
    }

    /**
   * Gets our encrypted private key and public key
   * if no key, generate one and upload to server through API
   * save passPhrase in localstorge.
   * decrypt private key with passphrase
   */
    private async loadUserKeys() {
     //   console.log('CryptoSvc: loadUserKeys');
        if (this.loadingKeys) {
            return;
        } else {
            this.loadingKeys = true;
        }
        if (!this.keyCode) {
            let passPhrase = this.config.getItem('pf');

            const keyPair: KeyPair = await this.api.getKeyPair();
    //        console.log('CryptoSvc: loadUserKeys, got keypair ', keyPair);
            if (keyPair) {
                if (!passPhrase) {
                    if (this.pwd) {
                        passPhrase = await this.generatePassPhrase(this.pwd, new Uint8Array(this.hexToBuffer(keyPair.salt)));
                    } else {
                        setTimeout(() => { this.userLoginSvc.logout(true) }, 10);
                        // should force logout
                        console.error('CryptoSvc: loadUserKeys, Error can not continue');
                    }
                }
                try {
                    await this.setKeys(keyPair, passPhrase);
                    this.config.setItem('pf', passPhrase);
                } catch (err) {
                    console.log('CryptoSvc: loadUserKeys, error setting keys ', keyPair, err);
                    if (this.pwd) {
                        passPhrase = await this.generatePassPhrase(this.pwd, new Uint8Array(this.hexToBuffer(keyPair.salt)));
                        try {
                      //             console.error('passPhrase ', passPhrase, keyPair);
                            await this.setKeys(keyPair, passPhrase);
                            this.config.setItem('pf', passPhrase);
                        } catch (err) {
                            await this.generateNewKeyPair(this.pwd);
                            setTimeout(() => { this.userLoginSvc.logout(true) }, 10);
                            // should force logout
                            console.error('CryptoSvc: loadUserKeys, Error can not continue 2', err);
                        }
                    } else {
                        setTimeout(() => { this.userLoginSvc.logout(true) }, 10);
                        // should force logout
                        console.error('CryptoSvc: loadUserKeys, Error can not continue 2.5');
                    }
                }
            } else {
                if (this.pwd) {
                    await this.generateNewKeyPair(this.pwd);
                } else {
                    setTimeout(() => { this.userLoginSvc.logout(true) }, 10);
                    // should force logout
                    console.error('CryptoSvc: loadUserKeys, Error can not continue 3');
                }
            }
        //     console.log('CryptoSvc: loadUserKeys: keypair ', keyPair);
        } else {
            // we have connected with a connection key
        //     console.log('CryptoSvc: loadUserKeys, generating new ', this.keyCode);
            await this.generateNewKeyPair(this.keyCode);
        }
        this.ready = true;
        console.log('CryptoService: initiated');
        this.storeMessages.forEach((resolve: any) => {
            resolve();
        });
    }

    private async generateNewKeyPair(password: string) {
        const salt = window.crypto.getRandomValues(new Uint8Array(16));
        const passPhrase = await this.generatePassPhrase(password, salt);
        const cryptokeyPair = await this.generateKeyPair();
        this.privateKey = cryptokeyPair.privateKey;
        this.publicKey = cryptokeyPair.publicKey;
        // console.log('Crypto: generateNewKeyPair: got cryptokeyPair:', cryptokeyPair);
        const iv = window.crypto.getRandomValues(new Uint8Array(12));
        //  console.log('Crypto: wrapping private key');
        const wrappedPrivateKey = await this.wrapPrivateKey(cryptokeyPair.privateKey, iv, passPhrase, salt);
        //  console.log('Crypto: generateNewKeyPair: got warapped private key', wrappedPrivateKey);
        const wrappedPrivateKeyString = this.bufferToHex2(wrappedPrivateKey);

        const pubkey = await window.crypto.subtle.exportKey(
            'jwk',
            cryptokeyPair.publicKey
        );
        delete pubkey.key_ops;
        const publicKeyString = JSON.stringify(pubkey);
        let keyPair = new KeyPair(publicKeyString, wrappedPrivateKeyString, this.bufferToHex2(iv.buffer), this.bufferToHex2(salt.buffer));
      //   console.log('Crypto: generateNewKeyPair: keyPair', keyPair);
        if (!this.keyCode) {
            keyPair = await this.api.postnewKeyPair(keyPair);
     //        console.log('Crypto: generateNewKeyPair: posted');
            this.config.setItem('pf', passPhrase);
        } else {
            keyPair = await this.api.postKeyCryptoKeys(keyPair, this.keyCode);
     //        console.log('Crypto: generateNewKeyPair: posted by keyCode ' + this.keyCode);
        }
    //     console.log('Crypto: generateNewKeyPair: saved keyPair', keyPair);
        this.sourceKeyID = keyPair.keyID;
    }

    private async setKeys(keyPair: KeyPair, passPhrase: string) {
    //     console.log('**********************************Crypto: setKeys: ', keyPair, passPhrase);
        const hexStringPrvKey = keyPair.privateKey;
        const iv = new Uint8Array(this.hexToBuffer(keyPair.iv));
        const salt = new Uint8Array(this.hexToBuffer(keyPair.salt));

        this.privateKey = await this.unwrapPrivateKey(hexStringPrvKey, iv, passPhrase, salt);
   //     console.log('*******************************************Got private key');
        if (this.preloadAddresses) {
            try {
                let keyPairs: KeyPair[] = await this.api.getCryptoKeysByAddresses(this.preloadAddresses);
    //            console.log('*******************preloading KeyPairs ', keyPairs);
                if (keyPairs) {
                    for (let key of keyPairs) {
                        await this.saveKeyPair(key, key.address);
                    }
                }
            } catch (err) {
                console.error('Error getting keys ', err);

            }
            this.preloadAddresses = undefined;
         //    console.log('************************************CryptoService: setKeys: privateKey ', this.privateKey);
            this.publicKey = await this.importKey(keyPair.publicKey);
       //     console.log('******************************CryptoService: setKeys: publicKey ', this.publicKey);
            this.sourceKeyID = keyPair.keyID;
            this.pwd = null;
            return { privateKey: this.privateKey, publicKey: this.publicKey }
        }
    }

    private async importKey(jsonJWK: string): Promise<CryptoKey> {
        const pubKeyJSON = JSON.parse(jsonJWK);
        return window.crypto.subtle.importKey(
            'jwk',
            pubKeyJSON,
            <any>{
                name: 'ECDH',
                namedCurve: 'P-384'
            },
            true,
            []
        );
    }

    /**
     * @param cipherObject
     * returns object
     * c = hexStringEncoded cyphertext
     * i = hecStringEncoded initialization vector
     *id = empty string for key id
     */
    private wrapCypherText(cipherObject: { ciphertext: ArrayBuffer; iv: Uint8Array; }): { c: string, i: string, id: string } {
        let hexciphertext = this.bufferToHex2(cipherObject.ciphertext);
        let hexIv = this.bufferToHex2(cipherObject.iv.buffer);
        return { c: hexciphertext, i: hexIv, id: this.sourceKeyID };
    }

    private unWrapCypherText(cipherObject: { c: string, i: string, id: string }): { cipherText: ArrayBuffer; iv: Uint8Array; } {
        let cipherText = this.hexToBuffer(cipherObject.c);
        let iv = new Uint8Array(this.hexToBuffer(cipherObject.i));
        return { cipherText: cipherText, iv: iv };
    }

    private async encrypt(key: CryptoKey, clearText: string) {
        let iv = window.crypto.getRandomValues(new Uint8Array(12));
        let enc = new TextEncoder();
        let encodedClearText = enc.encode(clearText)
        let ciphertext = await window.crypto.subtle.encrypt(
            {
                name: 'AES-GCM',
                iv: iv
            },
            key,
            encodedClearText
        );
        return { ciphertext: ciphertext, iv: iv };
    }

    private async generatePassPhrase(password: string, salt: Uint8Array): Promise<string> {
        let key = await this.getKeyMaterial(password);
        const derivedBits = await window.crypto.subtle.deriveBits(
            {
                'name': 'PBKDF2',
                salt: salt,
                'iterations': 100000,
                'hash': 'SHA-256'
            },
            key,
            256
        );
        const passPhrase = this.bufferToHex2(derivedBits);
        //  console.log('Crypto: generatePassPhrase: passPrase=', passPhrase);
        return passPhrase;
    }

    private async getKeyByID(ID: string): Promise<CryptoKey> {
        // console.log('CryptoService: getKeyByID', ID);
        let key: CryptoKey = this.keyMap.get(ID);
        //  console.log('CryptoService: getKeyByID: key', key);
        if (!key) {
            let keyPair: KeyPair = await this.api.getCryptoKeyByID(ID, 'keyid');
            //  console.log('CryptoService: getKeyByID: got keyPair from api', keyPair);
            if (keyPair) {
                await this.saveKeyPair(keyPair, null);
                key = this.keyMap.get(ID);
            } else {
                console.warn('CryptoService: getKeyByID: no key for keyID ' + ID);
                return null;
            }
        }
        return key;
    }

    private async getKeyByAddress(address: string) {
        console.log('CryptoService: getKeyByAddress: address =', address);
        let ID: string = this.addressIdMap.get(address);
        if (!ID) {
            let keyPair: KeyPair = await this.api.getCryptoKeyByID(address, 'address');
            if (keyPair) {
                await this.saveKeyPair(keyPair, address);
                ID = this.addressIdMap.get(address);
                // console.log('CryptoService: getKeyByAddress ' + address + ' id = ' + ID);
            } else {
                console.warn('CryptoService: getKeyByAddress: no key for address ' + address);
                return null;
            }
        }
        console.log('CryptoService: getKeyByAddress: ID =', ID);
        return this.keyMap.get(ID);
    }

    public async addTrustedAddresses(addresses: string[]) {
        this.preloadAddresses = addresses;
    }

    private async saveKeyPair(keyPair: KeyPair, address: string) {
        console.log('CryptoService: saveKeyPair: saving ', keyPair);
        try {
            let ID = keyPair.keyID;
            let keyPairAddress = keyPair.address;
            let publicKey = await this.importKey(keyPair.publicKey);
            // console.log('CryptoService: saveKeyPair: imported publicKey ', publicKey);
            let key = await this.deriveSecretKey(this.privateKey, publicKey);
            // console.log('CryptoService: saveKeyPair: derived secret key', key);
            //  console.log('CryptoService: saveKeyPair:  derived secret key address', keyPairAddress);
            this.keyMap.set(ID, key);
            this.idAddressMap.set(ID, keyPairAddress);
            this.addressIdMap.set(keyPairAddress, ID);
            if (address && keyPairAddress !== address) {
                console.log('CryptoService: saveKeyPair: adding key to two addresses ', address, keyPairAddress);
                this.idAddressMap.set(ID, address);
                this.addressIdMap.set(address, ID);
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    private async decrypt(key: CryptoKey, iv: Uint8Array, cipherText: ArrayBuffer) {
        let encodedClearText = await window.crypto.subtle.decrypt(
            {
                name: 'AES-GCM',
                iv: iv
            },
            key,
            cipherText
        );

        let dec = new TextDecoder();
        let clearText = dec.decode(encodedClearText);
        return clearText;
    }

    // converter using lookups
    private bufferToHex2(ab: ArrayBuffer) {
        const buffer = new Uint8Array(ab);
        let hex_array = [];
        for (let i = 0; i < buffer.length; i++) {
            hex_array.push(this.to_hex_array[buffer[i]]);
        }
        return hex_array.join('');
    }

    // reverse conversion using lookups
    private hexToBuffer(s: string): ArrayBuffer {
        let length2 = s.length;
        if ((length2 % 2) !== 0) {
            throw new Error('hex string must have length a multiple of 2');
        }
        let length = length2 / 2;
        let result = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            let i2 = i * 2;
            let b = s.substring(i2, i2 + 2);
            result[i] = this.to_byte_map[b];
        }
        return result.buffer;
    }

    /**
    *Get some key material to use as input to the deriveKey method.
    *The key material is a password supplied by the user.
    */
    private async getKeyMaterial(password: string): Promise<CryptoKey> {
        const enc = new TextEncoder();
        return window.crypto.subtle.importKey(
            'raw',
            enc.encode(password),
            'PBKDF2',
            false,
            ['deriveBits', 'deriveKey']
        );
    }

    /**
    * Given some key material and some random salt
    * derive an AES-GCM key using PBKDF2.
    */
    private getWrappingKey(keyMaterial: CryptoKey, salt: Uint8Array): PromiseLike<CryptoKey> {
        return window.crypto.subtle.deriveKey(
            {
                'name': 'PBKDF2',
                salt: salt,
                'iterations': 100000,
                'hash': 'SHA-256'
            },
            keyMaterial,
            { 'name': 'AES-GCM', 'length': 256 },
            true,
            ['wrapKey', 'unwrapKey', 'encrypt', 'decrypt']
        );
    }

    /**
    * Derive an AES key, given:
    * - our ECDH private key
    * - their ECDH public key
    */
    private async deriveSecretKey(privateKey: CryptoKey, publicKey: CryptoKey): Promise<CryptoKey> {
        // console.log('CryptoService: deriveSecretKey: ', privateKey, publicKey);
        return window.crypto.subtle.deriveKey(
            {
                name: 'ECDH',
                public: publicKey
            },
            privateKey,
            {
                name: 'AES-GCM',
                length: 256
            },
            false,
            ['encrypt', 'decrypt']
        );
    }

    private async wrapPrivateKey(privateKeyToWrap: CryptoKey, iv: Uint8Array, password: string, salt: Uint8Array): Promise<ArrayBuffer> {
        // console.log('Crypto: wrapPrivateKey: exporting private key');
        const keyMaterial = await this.getKeyMaterial(password);
        const wrappingKey = await this.getWrappingKey(keyMaterial, salt);
        // console.log('Crypto: wrapPrivateKey: have key material')
        let jsonWebKey = await window.crypto.subtle.exportKey(
            'jwk',
            privateKeyToWrap
        );
        // console.log('Crypto: wrapPrivateKey: JsonWebJey', jsonWebKey);

        let enc = new TextEncoder();
        let encodedClearText = enc.encode(JSON.stringify(jsonWebKey))
        let cipherText = await window.crypto.subtle.encrypt(
            {
                name: 'AES-GCM',
                iv: iv
            },
            wrappingKey,
            encodedClearText
        );
        //  console.log('Crypto: wrapPrivateKey: encryped private key', cipherText);
        return cipherText;
    }

    /*
    Unwrap an ECDH private key from an ArrayBuffer containing
    the JWK encoded ECDH bytes.
    Takes an array containing the bytes, and returns a Promise
    that will resolve to a CryptoKey representing the private key.
    */
    private async unwrapPrivateKey(hexStringPrvKey: string, iv: Uint8Array, password: string, salt: Uint8Array): Promise<CryptoKey> {
        console.log('****************1. get the unwrapping key') ;
        const keyMaterial = await this.getKeyMaterial(password);
        const unwrappingKey = await this.getWrappingKey(keyMaterial, salt);
        // 4. unwrap the key
          console.log('Crypto: unwrapPrivateKey: unwrapping private key');
        let cipherTextPrivateKey = this.hexToBuffer(hexStringPrvKey);
        let clearTextPrivateKey = await window.crypto.subtle.decrypt(
            {
                name: 'AES-GCM',
                iv: iv
            },
            unwrappingKey,
            cipherTextPrivateKey
        );
          console.log('Crypto: unwrapPrivateKey: clearTextPrivateKey ', clearTextPrivateKey);
        let dec = new TextDecoder();
        let clearTextJSONPrivateKeyString = dec.decode(clearTextPrivateKey);
          console.log('Crypto: unwrapPrivateKey: decodec clearTextPrivateKey ', clearTextJSONPrivateKeyString);
        let privateJwtKey = JSON.parse(clearTextJSONPrivateKeyString);
          console.log('Crypto: unwrapPrivateKey: JSON wekKey ', privateJwtKey);
        // import key
        let privateKey = await window.crypto.subtle.importKey(
            'jwk',
            privateJwtKey,
            {
                name: 'ECDH',
                namedCurve: 'P-384'
            },
            true,
            ['deriveKey']
        );
          console.log('Crypto: unwrapPrivateKey: private key ', privateKey);
        return privateKey;
    }

    private async generateKeyPair(): Promise<CryptoKeyPair> {
        return window.crypto.subtle.generateKey(
            {
                name: 'ECDH',
                namedCurve: 'P-384'
            },
            true,
            ['deriveKey']);
    }
}
