import { Component } from '@angular/core';
import { CognitoCallback, UserRegistrationService } from '../../../service/cognito.service';
import { Router } from '@angular/router';
import { TranslationService } from '../../../service/translation.service';
import { EqcallapiService } from '../../../service/eqcallapi.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-criticall',
  templateUrl: './resendCode.html',
  styleUrls: ['../../../../assets/css/custom.css']

})
export class ResendCodeComponent implements CognitoCallback {

  email: string;
  errorMessage: string;
  userName: string

  constructor(private registrationService: UserRegistrationService, private router: Router,
    public tr: TranslationService, private api: EqcallapiService, private spinner: NgxSpinnerService) {
  }

  public async resendCode() {
    this.spinner.hide();
    if (this.email) {
      console.log('Updateing email addtess')
      await this.api.changeConfirmationAddress(this.userName, this.email, 'email');
      this.router.navigate(['/home/confirmRegistration', this.userName]);
    }
    this.registrationService.resendCode(this.userName, this);

  }

  cognitoCallback(error: string, result: any) {
    this.spinner.hide();
    if (error) {
      console.error(error);
      this.errorMessage = 'Something went wrong...please try again';
    } else {
      console.error(result);
      this.router.navigate(['/home/confirmRegistration', this.userName]);
    }
  }
}
