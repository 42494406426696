import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { SystemBusService, MessageObserver } from '../../../../service/system-bus.service';
import { RtcConnection } from '../../../../service/rtc.service';
import { TranslationService } from '../../../../service/translation.service';
import { LivekitService } from '../../../../service/livekit.service';

@Component({
  selector: 'app-rtcstripe',
  templateUrl: './rtcstripe.component.html',
  styleUrls: ['./rtcstripe.component.css']
})
export class RtcstripeComponent implements OnInit, MessageObserver, OnDestroy {

  @Output() fullscreenEvent = new EventEmitter();
  @Input() showControls: boolean;
  @Input() rtcButtons: boolean;
  @Input() settingsOverlay: boolean;

  public mainRtcConnection: RtcConnection = null;
  public rtcConnections: RtcConnection[] = [];
  rtcFullscreen  = false;

  constructor(rtcSvc: LivekitService, public systemBus: SystemBusService, public tr: TranslationService) {
    this.systemBus.subscribe(this);
    this.rtcConnections = [...rtcSvc.rtcConnections];
    console.log('Stripe connections 1', this.rtcConnections);
    if (this.rtcConnections.length > 0) {
      this.mainRtcConnection = this.rtcConnections.splice(0, 1)[0];
    }
    console.log('Stripe connections 2', this.rtcConnections);
  }

  ngOnInit() {
  }
  ngOnDestroy(): void {
    this.mainRtcConnection = null;
    this.rtcConnections.length = 0;
    this.systemBus.unSubscribe(this);
  }

  public switchView() {
    this.systemBus.emit('stripe', 'rtccontainer/switch');
  }

  public select(connection: RtcConnection) {
    const id = connection.getDestinationID();
    let idx = -1;
    for (let i = 0; i < this.rtcConnections.length; i++) {
      if (this.rtcConnections[i].getDestinationID() === id) {
        idx = i;
        break;
      }
    }
    if (idx !== -1) {
      let old = this.mainRtcConnection;
      let newc = this.rtcConnections.splice(idx, 1)[0];
      this.mainRtcConnection = null;
      setTimeout(() => {
        this.mainRtcConnection = newc;
        this.rtcConnections.push(old);
      });
    }
  }

  private removeConnectionByID(id: string) {
    if (this.mainRtcConnection != null && this.mainRtcConnection.getDestinationID() === id) {
      this.mainRtcConnection = null;
    } else {
      let idx = -1;
      for (let i = 0; i < this.rtcConnections.length; i++) {
        if (this.rtcConnections[i].getDestinationID() === id) {
          idx = i;
          break;
        }
      }
      if (idx !== -1) {
        this.rtcConnections.splice(idx, 1);
      }
    }
  }

  public fullscreenStatus($event: any): any {
    this.rtcFullscreen = $event;
    this.fullscreenEvent.emit(this.rtcFullscreen);
  }

  onBusMessage(message: any, type: string): void {
    if (type === 'rtc/connection/new') {
      if (!this.mainRtcConnection) {
        this.mainRtcConnection = message;
      } else {
        this.rtcConnections.push(message);
      }
    } else if (type === 'rtc/connection/removed') {
      const old = <RtcConnection>message;
      this.removeConnectionByID(old.getDestinationID());
    }
  }

  busMessageFilter(messageType: string): boolean {
    if (messageType === 'rtc/connection/new' || messageType === 'rtc/connection/removed') {
      return true;
    } else {
      return false;
    }
  }
}
