<!-- Begin eqCall Register -->
<div class="app">
	<header class="app-header">
		<div id="collapseLogout" class="panel-collapse" aria-expanded="true">
			<div class="d-flex align-items-center justify-content-end">
				<a href="https://eqcall.com"
					class="logo navbar-brand d-none d-sm-block ml-3 mt-2"> <img
					src="./assets/img/logo-light.png" alt="eqCall-logo">
				</a>
				<div class="d-flex align-items-center">
					<div class="p-2 d-none d-inline mt-3">
						<app-language> </app-language>
					</div>
					<div class="p-2 d-none d-inline mb-1 mr-1">
						<a [routerLink]="['/home/login']">{{tr.get('login','login')}}
						</a> | <a [routerLink]="['/home']">{{tr.get('login','join')}} </a>
					</div>
				</div>
			</div>
		</div>
	</header>
	<div class="app-body">
		<main class="app-content">
			<div class="h-spacer d-block d-sm-none"></div>
			<div class="container-fluid mb-5 m-background">
				<div class="modal-dialog modal-dialog-centered eqmodal"
					role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h2 class="modal-title">{{tr.get('login','register')}}</h2>
						</div>
						<div class="modal-body ta-left">
							<form name="form" (ngSubmit)="f.form.valid && onSubmit()"
								#f="ngForm" novalidate>
								<div *ngIf="errorMessage" class="alert alert-danger">{{
									errorMessage }}</div>
								<div class="form-group">
									<label for="userID">{{tr.get('register','userID')}}</label> <input
										type="text" class="form-control" name="userID"
										[(ngModel)]="model.userID" #userid="ngModel"
										[ngClass]="{ 'is-invalid': f.submitted && userid.invalid }"
										required maxLength="40" pattern="^\S+$"
										[placeholder]="tr.get('register','userID.holder')"
										autocomplete="username" />
									<div *ngIf="f.submitted && userid.invalid"
										class="invalid-feedback">
										<div *ngIf="userid.errors['required']">{{tr.get('register','userIDReq')}}
										</div>
										<div *ngIf="userid.errors['pattern']">{{tr.get('register','userIDPattern')}}
										</div>
									</div>
								</div>
								<div class=" form-group">
									<label for="displayName">{{tr.get('register','name')}}</label>
									<input type="text" class="form-control" name="displayName"
										[(ngModel)]="model.displayName" #displayName="ngModel"
										[ngClass]="{ 'is-invalid': f.submitted && displayName.invalid }"
										required [placeholder]="tr.get('register','name.holder')"
										autocomplete="name" />
									<div *ngIf="f.submitted && displayName.invalid"
										class="invalid-feedback">
										<div *ngIf="displayName.errors['required']">{{tr.get('register','nameReq')}}
										</div>
									</div>
								</div>
								<div class="form-group">
									<label for="email">{{tr.get('login','email')}}</label> <input
										type="text" class="form-control" name="email"
										[(ngModel)]="model.email" #email="ngModel"
										[ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
										required email autocomplete="email" />
									<div *ngIf="f.submitted && email.invalid"
										class="invalid-feedback">
										<div *ngIf="email.errors['required']">{{tr.get('register','emailReq')}}</div>
										<div *ngIf="email.errors['email']">{{tr.get('register','emailInv')}}
										</div>
									</div>
								</div>
								<div class="form-group">
									<label for="password">{{tr.get('login','password')}}</label> <input
										type="password" class="form-control" name="password"
										[(ngModel)]="model.password" #password="ngModel"
										[ngClass]="{ 'is-invalid': f.submitted && password.invalid }"
										required minlength="8"
										pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
										appCompareValidator="verify" parent="true"
										autocomplete="new-password" />
									<div *ngIf="f.submitted && password.invalid"
										class="invalid-feedback">
										<div *ngIf="password.errors['required']">{{tr.get('register','passwdReq')}}</div>
										<div *ngIf="password.errors['minlength']">{{tr.get('register','passwdLen')}}</div>
										<div *ngIf="password.errors['pattern']">{{tr.get('register','passwdPatt')}}</div>
									</div>
									<label class="mt-2" for="valpassword">{{tr.get('login','rpassword')}}</label>
									<input type="password" class="form-control" name="valpassword"
										[(ngModel)]="model.valpassword" #valpassword="ngModel"
										[ngClass]="{ 'is-invalid': f.submitted && valpassword.invalid }"
										required minlength="8"
										pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
										appCompareValidator="password" parent="false"
										autocomplete="new-password" />
									<div *ngIf="f.submitted && valpassword.invalid"
										class="invalid-feedback">
										<div *ngIf="valpassword.errors['required']">{{tr.get('register','passwdReq')}}</div>
										<div *ngIf="valpassword.errors['minlength']">{{tr.get('register','passwdLen')}}</div>
										<div *ngIf="valpassword.errors['pattern']">{{tr.get('register','passwdPatt')}}</div>
										<div
											*ngIf="valpassword.errors['compare'] && !valpassword.errors['required']">
											{{tr.get('register','passwdMis')}}</div>
									</div>
								</div>
								<div class="form-group">
									<button class="collapsed btn-lg btn-info btn-block">{{tr.get('login','register')}}</button>
									<span class="text-white">Or</span><a
										[routerLink]="['/home/login']" class="mt-2 ml-2"
										data-toggle="collapse" data-placement="top">Login with a
										Social Account</a>
								</div>
								<hr>
								<div class="form-group ta-center">
									<p>
										<a [routerLink]="['/home/forgotPassword']">{{tr.get('login','forgot')}}
										</a> | <a [routerLink]="['/home/resendCode']">{{tr.get('login','resend')}}
										</a>
									</p>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
	<footer class="app-footer-alt">
		<div class="container">
			<div class="row">
				<div class="col l4 offset-l2 s12 mt-3">
					<ul class="nav justify-content-center">
						<li>
							<p class="white-text text-lighten-4">© 2019 eqCall.</p>
						</li>
						<li><a href="#!" class="ml-1 footer-h">Privacy |</a></li>
						<li><a href="#!" class="ml-1 footer-h"> Terms |</a></li>
						<li><a href="#!" class="ml-1 footer-h"> GDPR</a></li>
					</ul>
				</div>
			</div>
		</div>
	</footer>
</div>