import { RtcChatMessage } from '../../../../service/rtc.service';
import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

@Component({
    selector: 'app-globalchat-message',
    templateUrl: './globalchat-message.component.html',
    styleUrls: ['../../../../../assets/css/custom.css']
})
export class GlobalchatMessageComponent implements OnInit, AfterViewInit {
    private static domParser = new DOMParser();
    @Input() message: RtcChatMessage
    public local = false;
    public id: string;
    procesedMsg: string;
    constructor() {
    }
    ngOnInit() {
        this.local = this.message.local;
        this.id = this.message.time + 'msg';
    }

    ngAfterViewInit(): void {
        this.local = this.message.local;
        this.procesedMsg = this.processRawTextToHtmlText(this.message.message);
        console.warn('processed=' + this.procesedMsg)
        this.convertHtmlTextToDom();
    }

    private processRawTextToHtmlText(text: string) {
        text = text.replace(/<[^>]+>/g, ''); // strips < > to stop html injections
        // Use HE library to clean up after stripping?
        // https://ourcodeworld.com/articles/read/376/how-to-strip-html-from-a-string-extract-only-text-content-in-javascript
        let msg = (text || '').replace(
            /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
            (match, space, url) => {
                let hyperlink = url;
                if (!hyperlink.match('^https?:\/\/')) {
                    hyperlink = 'http://' + hyperlink;
                }
                return space + '<a href="' + hyperlink + '" target="_blank">' + url + '</a>';
            }
        );
        msg = '<div>' + msg + '</div>'; // put in div so message is a single element
        return msg
    };

    private convertHtmlTextToDom() {
        let doc = GlobalchatMessageComponent.domParser.parseFromString(this.procesedMsg, 'text/html');
        console.warn(doc.body.firstChild);
        document.getElementById(this.id).appendChild(doc.body.firstChild);
    }
}
