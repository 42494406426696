<div class="collapse multi-collapse" id="multiCollapseDelete">
	<div class="d-flex">
		<div class="mr-auto p-2">
			<button type="button"
				class="btn-rtc btn-outline-light btn-lg pc-right-menu"
				data-placement="top" [title]="tr.get('contact','v.call')"
				data-toggle="collapse" data-target=".multi-collapse"
				aria-expanded="false"
				aria-controls="multiCollapseCheckboxKeys multiCollapseCheckboxDelete">
				<i class="fa fa-chevron-up"></i>
			</button>
			<button type="button"
				class="btn-rtc btn-outline-light btn-lg pc-right-menu"
				data-placement="top" [title]="tr.get('contact','v.call')">
				<i class="fa fa-trash"></i>
			</button>
		</div>
	</div>
</div>
<div class="heading">
	<p>Invite Key Codes</p>
</div>
<div class="show-keys" *ngFor="let key of keys" app-key [key]="key">
</div>
<div *ngIf="keys.length===0" id="collapseLinksOnboarding"
	class="collapse show">
	<div class="d-flex justify-content-center">
		<div class="onboarding">
			<div class="modal-header onboarding-header">
				<h5 class="modal-title mt-1">Create Invite Links & Keys</h5>
				<button type="button" class="close" data-toggle="collapse"
					data-target="#collapseLinksOnboarding" aria-expanded="true"
					aria-controls="collapseDialPadOnboarding">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="slider">
				<div class="slide">
					<h1>Share a Key Link</h1>
					<div>
						<button (click)="genkey()" class="btn btn-primary" type="button">
							<i class="material-icons">add_box</i>
						</button>
					</div>
					<p>Generating customized Invite Key Codes or Web Links which
						can be pasted into any Browser.</p>
					<p>Need to Dial out? Click the Phone icon in the menu above to
						launch the DialPad.</p>
				</div>
				<div class="slide"></div>
				<div class="slide"></div>
			</div>
		</div>
	</div>
</div>
<div class="d-flex justify-content-end close-menu"
	style="position: absolute; right: 30px; top: 32px">
	<div class="dropdown">
		<button class="btn btn-primary btn-sm" type="button" id="dropdownChat"
			data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			<i class="material-icons" style="margin-top: 3px;">add_box</i>
		</button>
		<div class="dropdown-menu" aria-labelledby="dropdownChat">
			<button class="dropdown-item" type="button" (click)="genkey()">{{tr.get('keylinks','g.key')}}</button>
			<button class="dropdown-item" type="button" data-toggle="collapse"
				data-target=".multi-collapse" aria-expanded="false"
				aria-controls="multiCollapseCheckboxKeys multiCollapseCheckboxDelete">Bulk
				Delete</button>
		</div>
	</div>
</div>

