<div #contentdiv class="chat-window-container"
	[hidden]="messenger.messages.length == 0"
	[ngStyle]="{'height':rtcButtons && inCall ?  'calc(100vh - 165px)' : 'calc(100vh - 122px)'}">
	<div class="block">
		<div class="block-body">
			<div #messagesdiv class="chat" *ngFor="let message of messages">
				<app-chat-message [message]="message" [rtcButtons]="rtcButtons"
					[inCall]="inCall"></app-chat-message>
			</div>
		</div>
	</div>
</div>

<div *ngIf="showEmojiPicker">
	<ngx-emoj style="top: 25vh; left: 25vw; position: absolute;"
		(onemojipick)="handleEmoji($event)"
		(onchardelete)="handleCharDelete($event)" [width]="'50vw'"
		[height]="'50vh'"
		[theme]="{
            martShowHeader: true,
            martShowFooter: false,
            martHeaderPadding: {x: '0', y: '0'},
            martFooterPadding: {x: '0', y: '0'},
            martHeaderFontSize: '14px',
            martHeaderBG: '#e3e7e8',
            martFooterBG: '#e3e7e8',
            martBG: '#ebeff2',
            martCategoryColor: '#94a0a6',
            martCategoryColorActive: '#455a64',
            martActiveCategoryIndicatorColor: '#00897b',
            martEmojiFontSize: '150%',
            martCategoryFontSize: '20px',
            martBorderRadius: '5px',
            martActiveCategoryIndicatorHeight: '4px',
            martEmojiPadding: {x: '40px', y: '40px'}
        }">
	</ngx-emoj>
</div>

<div class="row flex-nowrap chat-row"
	[ngStyle]="{'margin-top':rtcButtons && inCall ?  '-46px' : '-6px'}">
	<div class="col chat-col">
		<input #msg (keydown)="sendMessage(msg.value,$event) "
			(keydown.enter)="msg.value=''" type="text" autocomplete="off"
			class="form-control form-control-lg input-sm chat-window-input"
			id="chatInput"
			[attr.placeholder]="messenger.typing ? messenger.nickname+' is typing': 'Privately message '+messenger.nickname">
	</div>

	<div class="col chat-col-send">
		<button class="btn-send btn-default btn-lg btn-block chat-window-send"
			(click)="toggleEmojiPicker()">
			<i class="fa fa-smile-o"></i>
		</button>
	</div>

	<div class="col chat-col-send">
		<button class="btn-send btn-primary btn-lg btn-block chat-window-send"
			id="btnSearch" (click)="sendMessageButton(msg.value); msg.value=''">
			<i class="fa fa-send"></i>
		</button>
	</div>
</div>
