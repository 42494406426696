import { Injectable, NgZone } from '@angular/core';
import { ConfigService } from './config.service';
import { EqcallapiService } from './eqcallapi.service';
import { NetworkService, Socket } from './network.service';
import { RtcChatMessage, RtcConnection, RtcListener } from './rtc.service';
import { RtcproxyService } from './rtcproxy.service';
import { EqualizerValues, CropSettings, AudioAnalyser, StreamChangeEventListener, Visualizer, StreamhandlerService } from './streamhandler.service';
import { MessageObserver, SystemBusService } from './system-bus.service';
import {
	connect,
	RoomEvent,
	RemoteParticipant,
	RemoteTrackPublication,
	RemoteTrack,
	Participant,
	VideoPresets,
	Room,
	Track,
	LocalTrackPublication,
	LocalParticipant
} from 'livekit-client';

export class RtcConnectionImpl implements RtcConnection, StreamChangeEventListener {
	async init() {
		throw new Error('Method not implemented.');
	}
	localAudioMuted: boolean;
	localVideoMuted: boolean;
	audioContext: AudioContext;
	onHold: boolean;
	destinationID: string;
	constructor(private socket: Socket, public service: LivekitService, private streamHandler: StreamhandlerService,
		public isBeingCalled: boolean, public joinGroup: boolean, public remoteNickName: string, enableAudio: boolean, enableVideo: boolean) {
		this.localAudioMuted = !enableAudio;
		this.localVideoMuted = !enableVideo;
		this.audioContext = service.audioContext;
		this.destinationID = socket.getDestination();
		this.onHold = service.getMasterHold();

	}
	getIsRemoteVideoCropping(): CropSettings {
		throw new Error('Method not implemented.');
	}
	setRemoteVideoCrop(cropSettings: CropSettings): void {
		throw new Error('Method not implemented.');
	}
	startRemoteVisualizer(start: boolean): void {
		throw new Error('Method not implemented.');
	}
	getVisualizer(): Visualizer {
		throw new Error('Method not implemented.');
	}
	getAnalyser(): AudioAnalyser {
		throw new Error('Method not implemented.');
	}
	getOutputStream(): MediaStream {
		throw new Error('Method not implemented.');
	}
	acceptConnection(): void {
		throw new Error('Method not implemented.');
	}
	setBandwidth(bw: number): void {
		throw new Error('Method not implemented.');
	}
	muteRemoteAudio(mute: boolean): void {
		throw new Error('Method not implemented.');
	}
	muteRemoteVideo(mute: boolean): void {
		throw new Error('Method not implemented.');
	}
	masterMuteLocalVideo(mute: boolean): void {
		throw new Error('Method not implemented.');
	}
	masterMuteLocalAudio(arg0: boolean): void {
		throw new Error('Method not implemented.');
	}
	sendChatMessage(message: string): void {
		throw new Error('Method not implemented.');
	}
	requestRemoteControl(message: string): Promise<any> {
		throw new Error('Method not implemented.');
	}
	close(): void {
		throw new Error('Method not implemented.');
	}
	hold(hold: boolean): void {
		throw new Error('Method not implemented.');
	}
	streamHandlerOnStreamChangeEvent(stream: MediaStream): void {
		throw new Error('Method not implemented.');
	}
	sendTalking(talking: boolean): void {
		throw new Error('Method not implemented.');
	}
	remoteCtlDataMessage(packet: any, messageType: string): void {
		throw new Error('Method not implemented.');
	}
	sendPeerNotification(destinationID: string, remoteNickName: string): void {
		throw new Error('Method not implemented.');
	}
	remoteControlRequestSendAnswer(answer: boolean): void {
		throw new Error('Method not implemented.');
	}
	sendRawMessage(dataArray: Uint8Array, arg1: string): void {
		throw new Error('Method not implemented.');
	}
	sendEQValues(arg0: EqualizerValues): void {
		throw new Error('Method not implemented.');
	}
	listDevices(): Promise<unknown> {
		throw new Error('Method not implemented.');
	}
	removeListener(arg0: RtcListener): void {
		throw new Error('Method not implemented.');
	}
	addListener(arg0: RtcListener): void {
		throw new Error('Method not implemented.');
	}
	setRemoteNoiseGate(val: number): void {
		throw new Error('Method not implemented.');
	}
	setRemoteEq(arg0: string, val: number): void {
		throw new Error('Method not implemented.');
	}
	setRemoteVolume(volume: number): void {
		throw new Error('Method not implemented.');
	}
	getStats(): Promise<any> {
		throw new Error('Method not implemented.');
	}
	setRemoteDeviceSettings(ret: any): void {
		throw new Error('Method not implemented.');
	}
	getAreRemoteControlling(): boolean {
		throw new Error('Method not implemented.');
	}
	getIsTalking(): boolean {
		throw new Error('Method not implemented.');
	}
	getRemoteNickName(): string {
		throw new Error('Method not implemented.');
	}
	getIsRemoteControlled(): boolean {
		throw new Error('Method not implemented.');
	}
	getState(): string {
		throw new Error('Method not implemented.');
	}
	getIsBeingCalled(): boolean {
		throw new Error('Method not implemented.');
	}
	getDestinationID(): string {
		throw new Error('Method not implemented.');
	}
	setIsRemoteControlledAnswer(answer: boolean): void {
		throw new Error('Method not implemented.');
	}

}





@Injectable({
	providedIn: 'root'
})
export class LivekitService implements StreamChangeEventListener, MessageObserver {
	private setBWTimer: NodeJS.Timer;
	public sockets: Map<string, Socket> = new Map(); // key is address
	public bandwidthKb = 1024;
	public masterVolume = 0.50;
	masterHold: boolean;
	room: Room;

	public async getUserMedia(): Promise<MediaStream> {
		return this.streamHandler.getMediaStream();
	}
	public setMasterHold(hold: boolean) {
		this.masterHold = hold;
		for (let connection of this.rtcConnections) {
			connection.hold(hold);
		}
	}
	public setBandwidth(bandwidthKb: number) {
		if (bandwidthKb > 2000) {
			bandwidthKb = 2000;
		}
		this.bandwidthKb = bandwidthKb;

		if (typeof (Storage) !== 'undefined') {
			this.config.setItem('masterBandwidth', String(bandwidthKb));
		}
		clearTimeout(this.setBWTimer);
		this.setBWTimer = setTimeout(() => { this.fixBandwidth() }, 5000);
	}
	private fixBandwidth() {

		for (let connection of this.rtcConnections) {
			connection.setBandwidth(this.bandwidthKb);
		}

	}
	public shareDesktop(share: boolean, audio: boolean) {
		console.error('Reimplement');
		this.streamHandler.shareDesktop(share, audio);
	}
	public endAllCalls() {
		for (let connection of this.rtcConnections) {
			setTimeout(() => { connection.close() }, 1000);
		}
	}
	public setAudioInDevice(deviceID: string) {
		this.streamHandler.setAudioInDevice(deviceID);
	}

	public setAudioOutDevice(deviceID: string) {
		this.streamHandler.setAudioOutDevice(deviceID);
	}

	public setVideoInDevice(deviceID: string) {
		this.streamHandler.setVideoInDevice(deviceID);
	}
	public setVideoResolution(width: number, height: number) {
		this.streamHandler.setVideoResolution(width, height);
	}
	public setMasterVolume(level: number) {
		this.masterVolume = level / 100.00;
		const allVids = document.querySelectorAll('video');
		for (let i = 0; i < allVids.length; ++i) {
			allVids[i].volume = this.masterVolume;
		}
		if (typeof (Storage) !== 'undefined') {
			this.config.setItem('masterVolume', String(this.masterVolume));
		}
	}

	public getMasterVolume(): number {
		return this.masterVolume * 100;
	}

	audioContext: AudioContext;
	public rtcConnections: RtcConnection[] = [];
	public rtcChatMessages: RtcChatMessage[] = [];
	public contacts: any[];
	public contactSvc: any;
	public ourNickname = 'rtcunknown';  // value set by contact service

	constructor(public network: NetworkService, public systemBus: SystemBusService,
		public api: EqcallapiService, public ngZone: NgZone, public proxySvc: RtcproxyService,
		public streamHandler: StreamhandlerService, public config: ConfigService) {
		systemBus.subscribe(this);
	}

	onBusMessage(message: any, type: string): void {
		if (type === 'rtc/masterAudioMute') {
			this.setMasterAudioMute(message);
		} else if (type === 'rtc/masterVideoMute') {
			this.setMasterVideoMute(message);
		} else if (type === 'streamHandler/Initialized') {
			this.audioContext = this.streamHandler.audioContext;
			this.streamHandler.webAudioUnlock();
			//   this.network.listen('Rtc', new RtcSocketListenerCallback(this));
			this.updateSavedValues();
			this.streamHandler.getVuMeter().setTalkingListener(this);
			this.streamHandler.addStreamChangeEventListener(this);
		} else {
			console.error('Unhandeled messagetype ', type);
		}
	}
	updateSavedValues() {
		throw new Error('Method not implemented.');
	}
	setMasterVideoMute(message: any) {
		throw new Error('Method not implemented.');
	}
	setMasterAudioMute(message: any) {
		throw new Error('Method not implemented.');
	}
	busMessageFilter(messageType: string): boolean {
		return messageType === 'rtc/masterAudioMute' ||
			messageType === 'rtc/masterVideoMute' ||
			messageType === 'streamHandler/Initialized';
	}

	streamHandlerOnStreamChangeEvent(stream: MediaStream): void {
		throw new Error('Method not implemented.');
	}
	setContacts(contacts: import("./contacts.service").Contact[]) {
		this.contacts = contacts;
	}
	public sendChatMessageToAll(message: string) {
		const rtcMessage = new RtcChatMessage('You', message, true);
		this.rtcChatMessages.push(rtcMessage)
		for (let connection of this.rtcConnections) {
			connection.sendChatMessage(message);
		}
	}



	public getMasterHold(): boolean {
		return this.masterHold;
	}







	/**
	* Called to initiate a connection
	*/
	public async connect(destAddress: string, srcAddress: string, joinGroup: boolean, remoteNickname: string,
		keyCode: string, enableVideo: boolean): Promise<RtcConnection> {

		if (enableVideo) {
			if (this.rtcConnections.length === 0) {
				this.streamHandler.setMasterVideoMute(false);
			}
		} else {
			if (this.rtcConnections.length === 0) {
				this.streamHandler.setMasterVideoMute(true);
			}
		}

		const currentSocket = this.sockets.get(destAddress);
		if (!currentSocket) {

			let header = { joinGroup: joinGroup, nickname: this.ourNickname, keyCode: keyCode, proxyData: <any>undefined };

			// get a proxy token for this contact
			let pdata = await this.api.getcontactProxy(destAddress);
			console.error("PROXY DATA=",pdata);
			header.proxyData = pdata;

			console.warn('Proxy header =', header);
			this.initRoom(pdata.token, pdata.url);
			const socket = this.network.connect(destAddress, srcAddress, 'Rtc',
				JSON.stringify(header));

			const connection = new RtcConnectionImpl(socket, this, this.streamHandler,false, joinGroup, remoteNickname,  true, enableVideo);
			await connection.init();
			this.rtcConnections.push(connection);
			this.fixBandwidth();
			this.systemBus.emit(connection, 'rtc/connection/new');
			// client will send an accept packet which will cause webRtcInit to be called on the RtcConnection
			return connection;

			console.log('Implement');
			return null;
		} else {
			console.error('RTCService: connect: Already connected');
			this.rtcConnections.forEach((connection) => {
				if (connection.getDestinationID() === destAddress) {
					return connection;
				}
			});
		}
	}

	private async initRoom(token: any, livekiturl: string) {
		if (!this.room) {
			this.room = new Room({
				// automatically manage subscribed video quality
				adaptiveStream: true,
				// optimize publishing bandwidth and CPU for simulcasted tracks
				dynacast: true,

				// default capture settings
				videoCaptureDefaults: {
					resolution: VideoPresets.hd.resolution,
				}
			});
			// set up event listeners
			this.room
				.on(RoomEvent.TrackSubscribed, this.handleTrackSubscribed)
				.on(RoomEvent.TrackUnsubscribed, this.handleTrackUnsubscribed)
				.on(RoomEvent.ActiveSpeakersChanged, this.handleActiveSpeakerChange)
				.on(RoomEvent.Disconnected, this.handleDisconnect)
				.on(RoomEvent.LocalTrackUnpublished, this.handleLocalTrackUnpublished);
			// connect to room
			await this.room.connect(livekiturl, token, {
				// don't subscribe to other participants automatically
				autoSubscribe: false,
			});
			console.log('connected to room', this.room.name);
		}
	}

	/*
	 * Called to initialize connection after receiving connection request.
	 * Still have to call acceptConnection on rtcConnection after videoElements have been created
	 */
	public async connectSocket(socket: Socket, joinGroup: boolean, remoteNickname: string, enableVideo: boolean) {



		/*
		 if (this.haveConnection(socket.getDestination())) {
			 socket.close();
			 // TODO HAndle this better !!
			 return;
		 }
		 if (enableVideo) {
			 if (this.rtcConnections.length === 0) {
				 this.streamHandler.setMasterVideoMute(false);
			 }
		 } else {
			 if (this.rtcConnections.length === 0) {
				 this.streamHandler.setMasterVideoMute(true);
			 }
		 }
		 let mode = await this.getMode();
		 const connection = new RTC(socket, this, true, joinGroup, remoteNickname, mode, this.streamHandler, true, enableVideo);
		 await connection.init();
		 this.rtcConnections.push(connection);
		 this.fixBandwidth();
		 this.systemBus.emit(connection, 'rtc/connection/new');
		 if (connection.joinGroup) {
			 this.sendGroupPeers(connection);
		 }
		 */
		console.log('Implement');
	}

	public connectSocketDefaultVideo(socket: Socket, joinGroup: boolean, remoteNickname: string) {
		this.connectSocket(socket, joinGroup, remoteNickname, !this.streamHandler.masterVideoMute);
	}

	public answerRemoteControlRequest(answer: boolean, connection: RtcConnection) {
		connection.setIsRemoteControlledAnswer(answer);
		this.rtcConnections.forEach(c => {
			if (c.getDestinationID() !== connection.getDestinationID()) {
				c.setIsRemoteControlledAnswer(false); // only one remote controller
			}
		});
		connection.remoteControlRequestSendAnswer(answer);
	}

	private handleTrackSubscribed(
		track: RemoteTrack,
		publication: RemoteTrackPublication,
		participant: RemoteParticipant
	) {
		if (track.kind === Track.Kind.Video || track.kind === Track.Kind.Audio) {
			// attach it to a new HTMLVideoElement or HTMLAudioElement
			//const element = track.attach();
			//parentElement.appendChild(element);
		}
	}

	private handleTrackUnsubscribed(
		track: RemoteTrack,
		publication: RemoteTrackPublication,
		participant: RemoteParticipant
	) {
		// remove tracks from all attached elements
		track.detach();
	}

	private handleLocalTrackUnpublished(
		track: LocalTrackPublication,
		participant: LocalParticipant,
	) {
		// when local tracks are ended, update UI to remove them from rendering

		//track.detach();
	}

	private handleActiveSpeakerChange(speakers: Participant[]) {
		// show UI indicators when participant is speaking
	}

	private handleDisconnect() {
		console.log('disconnected from room');


		this.room = undefined;
	}
}


