import { Component, OnInit, Input } from '@angular/core';
import { TranslationService } from '../../../../../service/translation.service';

@Component({
  selector: 'app-calllog',
  templateUrl: './calllog.component.html',
  styleUrls: ['../../../../../../assets/css/custom.css']
})
export class CalllogComponent implements OnInit {
 @Input() logs: any;
     /*
        Amt: -11
        Cur: "usd"
        Desc: "Call to +18194591496"
        Duration: "17"
        Frm: "+18192827469"
        Status: "completed"
        Time: "1602793850086"
        To: "+18194591496"
        Twil:accID: "AC65a3c7cffde85c9194a7931fc8062f6f"
        Twil:callID: "CAe8b0e304e3e32327973689ef1a6739b6"
        Type: "TwilCall"
        sort: "Call:2020:10:15:20:30:50:0"
        userID: "e6b0132e-6b94-466c-99b9-6a57fbd8d6c5"
        */

  constructor(public tr: TranslationService) { }

  ngOnInit(): void {
  }

}
