import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CognitoCallback, UserLoginService } from '../../../service/cognito.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslationService } from '../../../service/translation.service';

@Component({
  selector: 'app-criticall',
  templateUrl: './forgotPassword.html',
  styleUrls: ['../../../../assets/css/custom.css']

})
export class ForgotPasswordStep1Component implements CognitoCallback {
  email: string;
  errorMessage: string;

  constructor(public router: Router,
    public userService: UserLoginService,
    private spinner: NgxSpinnerService,
    public tr: TranslationService) {
    this.errorMessage = null;
  }

  onNext() {
    this.spinner.show();
    this.errorMessage = null;
    this.userService.forgotPassword(this.email, this);
  }

  cognitoCallback(message: string, result: any) {
    this.spinner.hide();
    if (message == null && result == null) { // error
      this.router.navigate(['/home/forgotPassword', this.email]);
    } else { // success
      this.errorMessage = message;
    }
  }
}


@Component({
  selector: 'app-criticall',
  templateUrl: './forgotPasswordStep2.html',
  styleUrls: ['../../../../assets/css/custom.css']

})
export class ForgotPassword2Component implements CognitoCallback, OnInit, OnDestroy {

  verificationCode: string;
  email: string;
  password: string;
  errorMessage: string;
  private sub: any;

  constructor(public router: Router, public route: ActivatedRoute,
    public userService: UserLoginService,
    private spinner: NgxSpinnerService,
    public tr: TranslationService) {

  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params: any) => {
      this.email = params['email'];
      console.log('email from the url: ' + this.email);
    });
    this.errorMessage = null;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onNext() {
    this.spinner.show();
    this.errorMessage = null;
    console.warn(this);
    this.userService.confirmNewPassword(this.email, this.verificationCode, this.password, this);
  }

  cognitoCallback(message: string) {
    this.spinner.hide();
    if (message != null) { // error
      this.errorMessage = message;
      console.log('result: ' + this.errorMessage);
    } else { // success
      this.router.navigate(['/home/login']);
    }
  }

}
