import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { RtcConnection, RtcListener } from '../../../../service/rtc.service';
import { TranslationService } from '../../../../service/translation.service';
import { MixerService } from '../mixerService/mixer.service';
import { EqualizerValues } from '../../../../service/streamhandler.service';
export class Point {
    public x: number;
    public y: number;
    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }
}
@Component({
    selector: 'app-channel-element',
    templateUrl: './channel-element.component.html',
    styleUrls: ['../mixer.component.css']
})
export class ChannelElementComponent implements OnInit, OnDestroy, AfterViewInit, RtcListener {


    @Input() rtcConnection: RtcConnection;
    @Input() controlled: boolean;
    @Output() monitorEvent = new EventEmitter();


    private id: string;
    public fid: string;
    public eqID = '';
    public ngID = '';
    public volID = '';
    public volLevelID = '';
    public talking = false;
    public volumeLevel = 0;
    public soloed: boolean;
    public muted: boolean;
    public showMonitor = true;
    private volIisDragging: any;
    private volLastPosY: any;
    private volEl: JQuery<HTMLElement>;
    private volLevelEl: HTMLCanvasElement;
    private ngEl: JQuery<HTMLElement>;
    private ngPoint = new Point(0, 0);
    private eqEl: JQuery<HTMLElement>;
    private eqPoint = new Point(0, 0);
    private volumectx: CanvasRenderingContext2D;
    constructor(private mixerSvc: MixerService, public tr: TranslationService) { }


    ngOnInit() {
        try {
            this.id = this.rtcConnection.getDestinationID();
            console.log('Loaded mixer element ', this.id);
            this.fid = 'Y' + this.id.replace(/:/gi, '-'); // the Y is to avoid ids that start with a number.
            this.eqID = 'eqID-' + this.fid;
            this.ngID = 'ngID-' + this.fid;
            this.volID = 'volumeID-' + this.fid;
            this.volLevelID = 'levelID-' + this.fid;
            this.mixerSvc.registerMixer(this);
            this.controlled = this.rtcConnection.getAreRemoteControlling();
        } catch (error) {
            console.error(error);
        }
    }

    ngAfterViewInit(): void {
        this.volEl = $('#' + this.volID);
        this.ngEl = $('#' + this.ngID);
        this.eqEl = $('#' + this.eqID);
        this.volLevelEl = <HTMLCanvasElement>document.getElementById(this.volLevelID);
        this.volumectx = this.volLevelEl.getContext('2d');
        this.rtcConnection.addListener(this);
        this.initEQcontrols();
    }

    ngOnDestroy(): void {
        this.rtcConnection.removeListener(this);
        this.mixerSvc.unregisterMixer(this);
    }

    private initEQcontrols() {
        const eqValues: EqualizerValues = this.rtcConnection.getAnalyser().getRemoteEqValues();
        console.log('eqValues=', eqValues);
        this.setVolTop(eqValues.volume);
        this.setEQDegreeFromVal(eqValues.master);
        this.setNGDegreeFromValue(eqValues.noiseGate);
    }

    rtcTalking(talking: boolean): void {
        this.talking = talking;
    }

    rtcMessage(_message: string, _connection: RtcConnection): void {

    }

    rtcConnectionRequest(_address: string, _connection: RtcConnection): void {

    }

    rtcClosed(_connection: RtcConnection): void {

    }

    rtcStateChange(state: string): void {
        if (state === 'connected') {
            setTimeout(() => { this.initEQcontrols() }, 1000);
        }
    }

    async rtcSoundLevelChange(level: number) {
        let start = Math.round(level * 100);
        if (start === 0) {
            start = 1;
        }
        //   start = start * 0.5; // it's a little hyper
        let adjusted = start * (this.volLevelEl.height / 100);
        this.volumeLevel = adjusted;
        let diff = this.volLevelEl.height - adjusted;

        this.volumectx.clearRect(0, 0, this.volLevelEl.width, this.volLevelEl.height);
        this.volumectx.beginPath();
        this.volumectx.rect(0, diff, this.volLevelEl.width, adjusted);
        let colour = '#a01818';
        if (start < 20) {
            colour = '#37cfdc';
        } else if (start < 90) {
            colour = '#37cfdc';
        }
        this.volumectx.fillStyle = colour;
        this.volumectx.fill();
    }

      dialNGClick(degree: number) {
        this.ngEl.css('-moz-transform', 'rotate(' +
            degree + 'deg)');
        this.ngEl.css('-webkit-transform',
            'rotate(' + degree + 'deg)'
        );
        console.log('dialngPan setting degree=' + degree);
        this.setNGByDegree(degree)
    }

    dialNgPan(evt: any) {
        console.log(evt);
        if (evt.isFirst) {
            let offset = this.ngEl.offset();
            this.ngPoint.x = (offset.left) + (this.ngEl.width() / 2);
            this.ngPoint.y = (offset.top) + (this.ngEl.height() / 2);
        }

        let mouse_x = evt.deltaX;
        let mouse_y = evt.deltaY;

        let radians;
        let degree;
        radians = Math.atan2(mouse_x - this.ngPoint.x,
            mouse_y - this.ngPoint.y);
        degree = (radians * (180 / Math.PI) * -
            1);
        if (degree < 0) {
            degree = degree + 360;
        }

        if (degree >= 50 && degree <= 310) {

            this.ngEl.css('-moz-transform', 'rotate(' +
                degree + 'deg)');
            this.ngEl.css('-webkit-transform',
                'rotate(' + degree + 'deg)'
            );
            this.setNGByDegree(degree);
        }
    }

    private setNGByDegree(degree: number) {

        // ng range 0 -> 150
        // degree 50 -> 310;
        degree -= 50; // normalize
        let val = degree * 0.57692;
        this.rtcConnection.setRemoteNoiseGate(val);

    }

    private setNGDegreeFromValue(val: number) {
        let degree = (val * 1.733333) + 50;
        this.ngEl.css('-moz-transform', 'rotate(' +
            degree + 'deg)');
        this.ngEl.css('-webkit-transform',
            'rotate(' + degree + 'deg)'
        );
    }

    dialEQClick(degree: number) {
        this.eqEl.css('-moz-transform', 'rotate(' +
            degree + 'deg)');
        this.eqEl.css('-webkit-transform',
            'rotate(' + degree + 'deg)'
        );
        console.log('dialEQPan setting degree=' + degree);
        this.setEQByDegree(degree)
    }

    dialEQPan(evt: any) {
        console.log('dialEQPan', evt);
        if (evt.isFirst) {
            let offset = this.eqEl.offset();
            this.eqPoint.x = (offset.left) + (this.eqEl.width() / 2);
            this.eqPoint.y = (offset.top) + (this.eqEl.height() / 2);
        }

        let mouse_x = evt.deltaX;
        let mouse_y = evt.deltaY;

        let radians;
        let degree;
        radians = Math.atan2(mouse_x - this.eqPoint.x,
            mouse_y - this.eqPoint.y);
        degree = (radians * (180 / Math.PI) * -
            1);
        if (degree < 0) {
            degree = degree + 360;
        }

        if (degree >= 50 && degree <= 310) {

            this.eqEl.css('-moz-transform', 'rotate(' +
                degree + 'deg)');
            this.eqEl.css('-webkit-transform',
                'rotate(' + degree + 'deg)'
            );
            //  degreeRatio = ((degree - 180) / 4);

            console.log('dialEQPan setting degree=' + degree);
            this.setEQByDegree(degree)
        }
    }

    private setEQByDegree(deg: number) {
        deg = deg - 50 // 0 -> 260
        // final eq is -30 to +30
        /**
         * 260 degrees on dial
         * from 50 to 310
         * 180 is center
         *
         * 70 total values
         * each value = 4.333333 degrees
         * each degree = 0.230769 val
         *
         */
        let val = Math.round((deg / 4.3333333) - 30);
        console.log('setEQByDegree: EQ Value=' + val);
        this.rtcConnection.setRemoteEq('master', val);
    }

    private setEQDegreeFromVal(val: number) {
        console.log('setEQDegreeFromVal: Val=' + val);
        val += 30 // make range 0 -> 60
        let degree = Math.round(val * 4.333333 + 50)
        console.log('setEQDegreeFromVal: Degree=' + degree);
        this.eqEl.css('-moz-transform', 'rotate(' +
            degree + 'deg)');
        this.eqEl.css('-webkit-transform',
            'rotate(' + degree + 'deg)'
        );
    }

    soloClick(event: any) {
        const el = $(event.currentTarget);
        if (el.hasClass('off')) {
            el.removeClass('off');
            this.soloed = false;
        } else {
            this.soloed = true;
            el.addClass('off');
        }

        this.mixerSvc.soloEvent();
    }

    muteClick(event: any) {
        console.log(event);
        const el = $(event.currentTarget);
        if (el.hasClass('off')) {
            el.removeClass('off');
            this.muted = false;
        } else {
            el.addClass('off');
            this.muted = true;
        }
        if (!this.mixerSvc.haveSolo()) {
            this.rtcConnection.muteRemoteAudio(this.muted);
        }
    }

    dialDblClick(event: any, element: string) {
        const el = $(event.currentTarget);
        el.css({
            '-webkit-transform': 'rotate(180deg)'
        });
        el.trigger('change', 0)
        if (element === 'eq') {
            this.setEQByDegree(180);
        } else {
            this.setNGByDegree(180);
        }
    }

    faderPan(ev: any) {
        const el = this.volEl;

        if (!this.volIisDragging) {
            this.volIisDragging = true;
            this.volLastPosY = el.position().top;
        }

        // we simply need to determine where the x,y of this
        // object is relative to where it's "last" known position is
        // NOTE:
        //    deltaX and deltaY are cumulative
        // Thus we need to always calculate 'real x and y' relative
        // to the "lastPosX/Y"

        let top = ev.deltaY + this.volLastPosY;
        if ((top >= -35) && (top <= 260)) {
            el.css('top', (top) + 'px');
            this.setRemoteVolume(top);
        }
        if (ev.isFinal) {
            this.volIisDragging = false;
        }

    }
    private setRemoteVolume(top: number): number {
        console.log('******************');
        console.log('ftop=' + top);
        // plus 35 so we have a positive range to deal with - by default the range is -35 to 260
        top += 35;
        // top top is 0 top bottom is 295
        // zero is + 65
        // volume is from 0 to 200, 100 is normal
        // 0 == 200, 65=100, 295 = 0
        // make range go from low of zero to a high of 295
        top = 295 - top
        // 0 == 0, 230=100, 295 = 200
        //  console.log('adj top=' + top);
        let volume = 100;
        if (top > 230) {
            // above 100
            volume = ((top - 230) * 1.53845) + 100;
        } else if (top < 230) {
            volume = top * 0.434;
        }
        console.log('cal volume=' + volume);
        // TODO debounce
        this.rtcConnection.setRemoteVolume(volume);
        return volume;
    }

    private setVolTop(volume: number) {
        let top = 65; // zero wich is volume 100
        // top -35 is volume 200
        // top 260 is volume 0
        // volume is 0 -> 200
        // top 260 -> -35

        // start by revesing the volume so it matched the direction of to; 0 high 295 low
        // work with a range of top from 0 to 195 then remove 35 to make the final product -35 to 260
        // reverse volume range to match top range vol 0 => top 0, vol 200 => 295

        volume = 200 - volume;
        if (volume < 100) {
            top = volume / 1.53845
        } else if (volume > 100 && volume !== 0) {
            top = 65 + (volume - 100) * 2.3;
        } else if (volume === 0) {
            top = 295;
        }

        top = Math.round(top - 35); // set to final range of 260 to -35
        console.log(' cal top=' + top);
        if (top < -35) {
            console.warn('fixing top ' + top + ' to -35');
            top = -35;
        } else if (top > 260) {
            console.warn('fixing top ' + top + ' to 260');
            top = 260;
        }

        const el = this.volEl;
        el.css('top', (top) + 'px');
        return top
    }

    faderDblClick(event: any) {
        console.log('fader dblClick ', event);
        const el = $(event.currentTarget);
        el.css({
            'top': '30px'
        });
        el.trigger('fader', (0 + 35 + el.position()
            .top));
    }

    public control() {
        if (this.controlled) {
            this.controlled = false;
        }
        this.mixerSvc.selectionChanged(this);

    }
    public toggleMonitor() {
        this.showMonitor = true;
        this.monitorEvent.emit(this.showMonitor);
    }
}
