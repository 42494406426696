<!-- Begin eqCall Forgot1 -->
<div class="app">
	<header class="app-header">
		<div id="collapseLogout" class="panel-collapse" aria-expanded="true">
			<div class="d-flex align-items-center justify-content-end">
				<a href="https://eqcall.com"
					class="logo navbar-brand d-none d-sm-block ml-3 mt-2"> <img
					src="./assets/img/logo-light.png" alt="eqCall-logo">
				</a>
				<div class="d-flex align-items-center">
					<div class="p-2 d-none d-inline mt-3">
						<app-language> </app-language>
					</div>
					<div class="p-2 d-none d-inline mb-1 mr-1">
						<a [routerLink]="['/home/login']">{{tr.get('login','login')}}
						</a> | <a [routerLink]="['/home']">{{tr.get('login','join')}} </a>
					</div>
				</div>
			</div>
		</div>
	</header>
	<div class="app-body">
		<main class="app-content">
			<div class="h-spacer d-block d-sm-none"></div>
			<div class="container-fluid mb-5 m-background">
				<div class="modal-dialog modal-dialog-centered eqmodal"
					role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h2 class="modal-title">{{tr.get('login','reset')}}</h2>
						</div>
						<div class="modal-body ta-left">
							<form class="form-signin" method="POST" action="#" role="form">
								<div *ngIf="errorMessage!=null" class="alert alert-danger">{{
									errorMessage }}</div>
								<div class="form-group">
									<label class="control-label" for="signupEmail">Email</label> <input
										id="signupEmail" type="email" maxlength="50"
										class="form-control form-control-lg input-sm"
										[(ngModel)]="email" [ngModelOptions]="{standalone: true}">
								</div>
								<div class="form-group">
									<br />
									<button (click)="onNext()" id="nextPage" type="submit"
										class="btn-lg btn-info btn-block">Next</button>
								</div>
								<hr>
								<div class="form-group">
									<p>
										<a [routerLink]="['/home/login']"> Login </a> | <a
											[routerLink]="['/home/register']"> Register </a> | Forgot
										Password? | <a [routerLink]="['/home/resendCode']">Resend
											Confirmation code </a>
									</p>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
	<footer class="app-footer-alt">
		<div class="container">
			<div class="row">
				<div class="col l4 offset-l2 s12 mt-3">
					<ul class="nav justify-content-center">
						<li><p class="white-text text-lighten-4">© 2019 eqCall.</p></li>
						<li><a href="#!" class="ml-1 footer-h">Privacy |</a></li>
						<li><a href="#!" class="ml-1 footer-h"> Terms |</a></li>
						<li><a href="#!" class="ml-1 footer-h"> GDPR</a></li>
					</ul>
				</div>
			</div>
		</div>
	</footer>
</div>






