import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';

import { MessengerGroup, Messenger, MessageService } from '../../../../../service/message.service';
import { ContactsService } from '../../../../../service/contacts.service';
import { SystemBusService, MessageObserver } from '../../../../../service/system-bus.service';
import { TranslationService } from '../../../../../service/translation.service';

@Component({
    selector: 'app-group-members',
    templateUrl: './group-members.component.html',
    styleUrls: ['../../../../../../assets/css/custom.css']
})
export class GroupMembersComponent implements OnInit, OnDestroy, MessageObserver, AfterViewInit {

    private _group: any;
    public members: Messenger[] = [];
    public notMembers: Messenger[] = [];
    public nickname = '';
    public addRemoveModal = false;
    public isOwner = false;
    private origIsOwner = false;
    public type = 'closed';
    public canEdit = false;
    public namePlaceholder = '';
    @ViewChild('addReomveModal', { static: true }) title: ElementRef;
    @Input()
    set group(group: MessengerGroup) {
        this._group = group;
        this.update();
    }

    get group(): MessengerGroup { return this._group; }

    constructor(private ref: ChangeDetectorRef, private contactSvc: ContactsService, private messageSvc: MessageService,
        public systemBus: SystemBusService, public tr: TranslationService) { }

    ngOnInit() {
        this.systemBus.subscribe(this);
        this.namePlaceholder = this.tr.get('criticall', 'changeme');
    }

    ngAfterViewInit(): void {
        let element = this.title.nativeElement;
        $(element).on('show.bs.modal', (_e) => {
            console.log('show');
            this.addRemoveModal = true;
            // this.ref.detectChanges();
            this.update();
        })

        $(element).on('hidden.bs.modal', (_e) => {
            console.log('hide');
            this.addRemoveModal = false;
        })
    }

    ngOnDestroy(): void {
        this.systemBus.unSubscribe(this);
        this.group = null;
        this.members = null;
        this.notMembers = null;
        this._group = null;
    }

    onBusMessage(message: any, type: string): void {
        if (!this.group) {
            return;
        }
        if (type === 'MesengerGroup/changed' && message === this.group) {
            this.update();
        } else if (type === 'notice/group') {
            setTimeout(() => {
                this.group = this.messageSvc.getLocalGroup(this.group.groupID);
                this.update();
            }, 3000);
        }
    }

    busMessageFilter(messageType: string): boolean {
        return messageType === 'MesengerGroup/changed' ||
            messageType === 'notice/group';
    }

    private update() {

        if (!this.group) {
            return;
        }
        console.log('Update');
        this.nickname = this.group.nickname;
        let owner = this.group.owner;
        if (owner != null) {
            this.type = 'Closed Group';
            this.isOwner = this.contactSvc.localContact[0].srcAddress === owner.address;
            this.canEdit = true;
            if (!this.isOwner) {
                this.canEdit = false;
                let user = this.contactSvc.getContactByAddress(owner.address);
                console.log('Group owner =', owner);
                if (user) {
                    this.type = 'Group owner is ' + user.nickname;
                } else {
                    this.type = 'Closed Group';
                }
            }
        } else {
            this.type = 'Open Group';
            this.isOwner = false;
            this.canEdit = true;
        }
        this.origIsOwner = this.isOwner;
        let most = this.contactSvc.contactsList.getLists().all;
        let all = Object.assign([], most);
        all.push(this.contactSvc.localContact[0]);
        all = all.concat(this.contactSvc.contactsList.getLists().groupContacts);
        this.members.length = 0;
        this.notMembers.length = 0;
        for (let c of all) {
            if (this.isInGroup(c)) {
                this.members.push(c);
                console.error('Group pushing ', c);
            } else {
                this.notMembers.push(c);
            }
        }
        this.ref.detectChanges();
    }

    private isInGroup(messenger: Messenger): boolean {
        let ret = false;
        for (let mesg of this.group.messengers) {
            if (mesg.destAddress === messenger.destAddress) {
                ret = true;
                break;
            }
        }
        console.log('isInGroup', messenger, ret);
        return ret;
    }

    public save() {
        if (this.canEdit) {
            this.group.nickname = this.nickname;

            if (this.origIsOwner !== this.isOwner) {
                this.group.owner = null;
            }
            console.log('Save nickname=', this.group.nickname);
            if (this.group.nickname.length === 0) {
                this.group.build(true);
            }

            this.group.save();
            console.log('saved');
        }
    }
}
