import { Component, OnInit } from '@angular/core';
import { TranslationService } from '../../../service/translation.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['../../../../assets/css/custom.css']
})
export class LanguageComponent implements OnInit {

  constructor(public tr: TranslationService) { }

  ngOnInit() {
  }

}
