<div class="group-chat">
	<div class="block">
		<div class="block-body">
			<div class="chat-message-inner" *ngFor="let message of messages">
				<app-globalchat-message [message]="message"></app-globalchat-message>
				<br />
			</div>
		</div>
	</div>
</div>
<div class="row flex-nowrap chat-row" style="margin-top: -4px;">
	<div class="col chat-col">
		<input #msg (keydown.enter)="sendMessage(msg.value); msg.value=''"
			type="text"
			class="form-control form-control-lg input-sm chat-window-input"
			[placeholder]="tr.get('globalchat','inputp')"> <select
			[(ngModel)]="selectedContact"
			class="form-control form-control-lg input-sm group-chat-select"
			id="sel1" name="cnt">
			<option value="Everyone" selected="selected">Everyone</option>
			<option *ngFor="let c of connections; let i = index" [ngValue]="c"
				[attr.selected]="i == 0 ? true : null">{{c.remoteNickName}}</option>
		</select>
	</div>
	<div class="col chat-col-send">
		<button class="btn-send btn-primary btn-lg btn-block chat-window-send"
			id="btnSearch" Value="Search"
			(click)="sendMessage(msg.value); msg.value=''">
			<i class="fa fa-send"></i>
		</button>
	</div>
</div>




