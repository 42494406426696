import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { SystemBusService, MessageObserver } from '../../../../service/system-bus.service';
import { EqcallapiService } from '../../../../service/eqcallapi.service';
import { KeyService } from '../../../../service/key.service';
import { TranslationService } from '../../../../service/translation.service';
import { ContactsService } from '../../../../service/contacts.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-upgrades',
    templateUrl: './upgrades.component.html',
    styleUrls: ['../../../../../assets/css/custom.css']
})
export class UpgradesComponent implements OnInit, OnDestroy, AfterViewInit, MessageObserver {
    public HOSTEDCALLS = false;
    public HOSTEDCALLSChanged = false;
    public LOCALPHONE = false;
    public LOCALPHONEChanged = false;
    public DIALOUT = false;
    public DIALOUTChanged = false;
    public UNBRANDEDLINK = false;
    public UNBRANDEDLINKChanged = false;
    public TWILIOAPI = false;
    public TWILIOAPIChanged = false;
    public KEYS = false;
    public KEYSChanged = false;
    public PROXY = false;
    public PROXYChanged = false;
    public showkeys = false;
    public showunbranded = false;
    public showlocal = false;
    public showdialout = false;
    public showhosted = false;
    public showproxy = false;
    public domainNames: string[] = [];
    private _show = 'NONE';
    public next = 'NONE';
    private needPayment: boolean;
    @Input()
    set show(show: string) {
        this._show = show
    }

    get show(): string { return this._show; }
    constructor(private api: EqcallapiService, private systemBus: SystemBusService,
        private keySvc: KeyService, public tr: TranslationService,
        private contactSvc: ContactsService, private spinner: NgxSpinnerService) {
        this.systemBus.subscribe(this);
    }

    ngOnDestroy(): void {
        this.systemBus.unSubscribe(this);
    }

    async onBusMessage(message: any, _type: string) {
        if (message.type === 'PM_ADDED' && this.needPayment) {
            this.authorize();
        }
    }
    busMessageFilter(messageType: string): boolean {
        return messageType === 'notice/PM'
    }

    ngAfterViewInit(): void {
        if (this._show !== 'NONE') {
            this.setShow();
        }
    }

    async ngOnInit() {
        this.api.getPermissions().then((perms: any) => { this.update(perms.data) });
    }

    private setShow() {
        let parts = this._show.split(',');
        let us = parts[0];
        if (parts.length > 1) {
            this.next = this._show.substring(this._show.indexOf(',') + 1);
        }
        if (us === 'KEYS') {
            (<any>$('#collapseKeys')).collapse('show');
        } else if (us === 'NUMBERS') {
            (<any>$('#collapseNumbers')).collapse('show');
        } else if (us === 'UNBRANDED') {
            (<any>$('#collapseUnbranded')).collapse('show');
        } else if (us === 'DIALOUT') {
            (<any>$('#collapsedialout')).collapse('show');
        } else if (us === 'HOSTED') {
            (<any>$('#collapseHosted')).collapse('show');
        } else if (us === 'PROXY') {
            (<any>$('#collapseProxy')).collapse('show');
        }
    }

    private async updateDomainNames() {
        this.domainNames = await this.keySvc.getFQDNs();
    }

    private update(perms: string[]) {
        console.log('PErms ', perms);
        this.DIALOUT = false;
        this.DIALOUTChanged = false;
        this.HOSTEDCALLS = false;
        this.HOSTEDCALLSChanged = false;
        this.KEYS = false;
        this.KEYSChanged = false;
        this.PROXY = false;
        this.PROXYChanged = false;
        this.LOCALPHONE = false;
        this.LOCALPHONEChanged = false;
        this.TWILIOAPI = false;
        this.TWILIOAPIChanged = false;
        this.UNBRANDEDLINK = false;
        this.UNBRANDEDLINKChanged = false;
        if (perms.indexOf('NONE') === -1) {
            perms.forEach((p: string) => {
                switch (p) {
                    case 'HOSTEDCALLS':
                        this.HOSTEDCALLS = true;
                        break;
                    case 'LOCALPHONE':
                        this.LOCALPHONE = true;
                        break;
                    case 'DIALOUT':
                        this.DIALOUT = true;
                        break;
                    case 'UNBRANDEDLINK':
                        this.UNBRANDEDLINK = true;
                        this.updateDomainNames();
                        break;
                    case 'TWILIOAPI':
                        this.TWILIOAPI = true;
                        break;
                    case 'KEYS':
                        this.KEYS = true;
                        break;
                    case 'PROXY':
                        this.PROXY = true;
                }
            });
        }
    }

    public hostedCallsClick() {
        this.HOSTEDCALLS = !this.HOSTEDCALLS;
        this.HOSTEDCALLSChanged = true;
    }

    public toggleHostedCalls() {
        this.showhosted = !this.showhosted;
    }

    public localPhoneClick() {
        this.LOCALPHONE = !this.LOCALPHONE;
        this.LOCALPHONEChanged = true;
    }

    public toggleLocalPhone() {
        this.showlocal = !this.showlocal;
    }

    public dialoutClick() {
        this.DIALOUT = !this.DIALOUT;
        this.DIALOUTChanged = true;
    }

    public toggleDialOut() {
        this.showdialout = !this.showdialout;
    }

    public keysClick() {
        this.KEYS = !this.KEYS;
        this.KEYSChanged = true;
    }

    public proxyClick() {
        this.PROXY = !this.PROXY;
        this.PROXYChanged = true;
    }

    public toggleProxy() {
        this.showproxy = !this.showproxy;
    }

    public toggleKeys() {
        this.showkeys = !this.showkeys;
    }

    public twilioClick() {
        this.TWILIOAPI = !this.TWILIOAPI;
        this.TWILIOAPIChanged = true;
    }

    public unbrandedlinkClick() {
        this.UNBRANDEDLINK = !this.UNBRANDEDLINK;
        this.UNBRANDEDLINKChanged = true;
    }

    public toggleUnbranded() {
        this.showunbranded = !this.showunbranded;
    }

    public async saveDomain() {
        this.spinner.show();
        try {
            console.log('Setting domain name ' + this.domainNames[0]);
            await this.keySvc.createFQDN(this.domainNames[0]).then(() => this.updateDomainNames()).
                catch(err => { this.domainNames[0] = null; this.sendMessage(err.data) });
        } finally {
            this.spinner.hide();
        }
    }

    public async deleteDomain() {
        console.log('Deleting domain name ' + this.domainNames[0]);
        this.keySvc.deleteFQDN(this.domainNames[0]).
            then(() => this.domainNames[0] = null).
            catch(err => { this.domainNames[0] = null; this.sendMessage(err) });
    }

    public async authorize() {
        this.spinner.show();
        try {
            let perms: string[] = [];
            if (this.DIALOUT) {
                perms.push('DIALOUT');
            }
            if (this.HOSTEDCALLS) {
                perms.push('HOSTEDCALLS');
            }
            if (this.KEYS) {
                perms.push('KEYS');
            }
            if (this.PROXY) {
                perms.push('PROXY');
            }
            if (this.LOCALPHONE) {
                perms.push('LOCALPHONE');
            }
            if (this.TWILIOAPI) {
                perms.push('TWILIOAPI');
            }
            if (this.UNBRANDEDLINK) {
                perms.push('UNBRANDEDLINK');
            }
            if (perms.length === 0) {
                perms.push('NONE');
            }

            await this.api.setPermissions(perms).then((_result) => { this.done(); this.updateContact(perms) }).
                catch((err) => { this.error(err) });
        } finally {
            this.spinner.hide();
        }
    }

    private error(error: any) {
        let msg = error.data;
        console.error(error);
        let message = this.tr.get('account-addons', msg);
        if (!message) {
            message = msg;
        }
        this.sendMessage(message);
        if (msg === 'NO_PAYMENT_METHOD') {
            this.needPayment = true;
            this.systemBus.emit('PAYMENT,METHODS', 'showAccounts');
        } else if (msg === 'RECAHRGE') {
            this.systemBus.emit('PAYMENT,RECHARGE', 'showAccounts');
        }
    }

    private updateContact(perms: string[]) {
        this.sendMessage('Services Updated ' + perms);
        let ps = perms.reduce((r, p) => { return r + ',' + p });
        this.contactSvc.localContact[0].permissions = ps;
    }

    private done() {
        this.DIALOUTChanged = false;
        this.HOSTEDCALLSChanged = false;
        this.KEYSChanged = false;
        this.PROXYChanged = false;
        this.LOCALPHONEChanged = false;
        this.TWILIOAPIChanged = false;
        this.UNBRANDEDLINKChanged = false;
    }

    private sendMessage(message: string) {
        const mesg = {
            type: 'info',
            message: message,
            timeOut: 5,
        };
        this.systemBus.emit(mesg, 'warning');
    }
}
