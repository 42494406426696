import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { LivekitService } from '../../../../service/livekit.service';

@Component({
    selector: 'app-audiotest',
    templateUrl: './audiotest.component.html',
    styleUrls: ['../../../../../assets/css/custom.css']
})
export class AudiotestComponent implements AfterViewInit, OnDestroy {
    public buttonMSG = 'Start'
    public volumeLevel: number;
    private mediaRecorder: MediaRecorder = null;
    private volLevelEl: HTMLCanvasElement;
    private volumectx: CanvasRenderingContext2D;
    private stopTimer: NodeJS.Timeout;
    private state = 'STOPPED'; // STOPPED, RECORDING , PLAYING
    private videoEl: HTMLMediaElement;
    private vu: any;
    private stream: MediaStream = null;
    canceled: boolean;

    constructor(private rtcSvc: LivekitService) { }
    ngOnDestroy(): void {
        this.cancel();
    }

    ngAfterViewInit(): void {
        this.volLevelEl = <HTMLCanvasElement>document.getElementById('audiotestcanvas');
        this.videoEl = <HTMLMediaElement>document.querySelector('#audiotestplayer');
        this.volumectx = this.volLevelEl.getContext('2d');
        this.buttonMSG = 'Start';
    }

    public go() {
        console.log('Go', this.state);
        switch (this.state) {
            case 'STOPPED':
                this.buttonMSG = 'Stop';
                this.record();
                break;
            case 'RECORDING':
                this.stopRecording();
                break;
            case 'PLAYING':
                this.cancel();
                break;
        }
    }

    private async record() {
        try {
            console.log('Record');
            this.state = 'RECORDING';
            this.stream = await this.rtcSvc.streamHandler.getMediaStream();
            console.log('Got stream', this.stream);
            this.videoEl.srcObject = this.stream.clone();
            this.videoEl.muted = true;
            this.videoEl.play();
            this.vu = this.rtcSvc.streamHandler.getVuMeter();
            console.log('Got VU', this.vu);
            this.vu.addSoundLevelListener(this);
            console.log('MediaRecorce type = ' + typeof MediaRecorder);
            if (typeof MediaRecorder !== 'function') {
                console.log('MediaRecorder not supported on your browser');
            } else {
                let options = {mimeType: 'video/webm; codecs=vp9'};
                this.mediaRecorder = new MediaRecorder(this.stream, options);
                this.mediaRecorder.onerror = (event) => {
                    let error = (<MediaRecorderErrorEvent>event).error;
                    switch (error.name) {
                        case 'InvalidStateError':
                            alert('You can\'t record the video right ' +
                                'now. Try again later.');
                            break;
                        case 'SecurityError':
                            alert('Recording the specified source ' +
                                'is not allowed due to security ' +
                                'restrictions.');
                            break;
                        default:
                            alert('A problem occurred while trying ' +
                                'to record the video.');
                            break;
                    }
                };
                let chunks: BlobPart[] = [];

                this.mediaRecorder.ondataavailable = (e) => {
                    chunks.push(e.data);
                }

                this.mediaRecorder.onstop = () => {
                    console.log('recorder stopped');
                    let blob = new Blob(chunks, { 'type':  'video/webm; codecs=vp9' });
                    let mediaURL = window.URL.createObjectURL(blob);
                    this.play(mediaURL);
                }
                this.mediaRecorder.start();
            }
            this.buttonMSG = 'Stop';
            this.stopTimer = setTimeout(() => { this.stopRecording() }, 5000);
        } catch (error) {
            console.error('Error recording', error);
        }
    }

    private play(audioURL: string) {
        let stream = <MediaStream>this.videoEl.srcObject;
        stream.getTracks().forEach(track => { track.stop() });
        this.videoEl.srcObject = null;
        if (!this.canceled) {
            this.state = 'PLAYING';
            console.log('play');

            this.videoEl.pause();
            this.videoEl.currentTime = 0;

            this.videoEl.muted = false;
            this.videoEl.onended = () => {
                this.videoEl.src = '';
                this.end();
            }

            this.videoEl.src = audioURL;
            this.buttonMSG = 'Playing';
            if ((<any>this.videoEl).setSinkId) {
                let speakerID = this.rtcSvc.streamHandler.getDeviceIDs().audioOutDeviceID;
                (<any>this.videoEl).setSinkId(speakerID).then(this.videoEl.play());
            } else {
                console.error('Unable to set output device');
                this.videoEl.play();
            }
        }
    }

    public cancel() {
        console.log('canceld');
        this.canceled = true;
        this.stopRecording();
        this.end();
        setTimeout(() => { this.canceled = false }, 1000);
    }

    private stopRecording() {
        if (this.stopTimer) {
            clearTimeout(this.stopTimer);
            this.stopTimer = undefined;
        }
        if (this.mediaRecorder) {
            if (this.mediaRecorder.state === 'recording') {
                this.mediaRecorder.stop(); // triggers play
            }
        } else {
            this.end();
        }
    }

    private async end() {
        console.log('END');
        this.state = 'STOPPED';
        this.buttonMSG = 'Start';
        this.videoEl.src = null;
        if (this.vu != null) {
            this.vu.removeSoundLevelListener(this);
            this.vu = null;
        }
        this.mediaRecorder = null;
        this.volumectx.clearRect(0, 0, this.volLevelEl.width, this.volLevelEl.height);
        if (this.stream) {
            this.rtcSvc.streamHandler.returnMediaStream(this.stream);
            this.stream = null;
        }
        (<any>$('#tdModal')).modal('hide');
    }

    public setSoundLevel(volumeLevel: number) {
        this.rtcSoundLevelChange(volumeLevel);
    }

    private async rtcSoundLevelChange(level: number) {
        let start = Math.round(level * 100);
        if (start === 0) {
            start = 1;
        }
        //   start = start * 0.5; // it's a little hyper
        let adjusted = start * (this.volLevelEl.height / 100);
        this.volumeLevel = adjusted;
        let diff = this.volLevelEl.height - adjusted;

        this.volumectx.clearRect(0, 0, this.volLevelEl.width, this.volLevelEl.height);
        this.volumectx.beginPath();
        this.volumectx.rect(0, diff, this.volLevelEl.width, adjusted);
        let colour = '#f39c12';
        if (start < 20) {
            colour = '#000';
        } else if (start < 90) {
            colour = '#3498db';
        }
        this.volumectx.fillStyle = colour;
        this.volumectx.fill();
    }
}
