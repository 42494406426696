import { Directive, ElementRef, Renderer2, Output, Input, AfterViewInit, OnDestroy, EventEmitter } from '@angular/core';
import { CropSettings } from '../../service/streamhandler.service';

export enum CropState {
  CROPPING,
  DONE
}

export class CropEvent {
  public state: CropState = CropState.CROPPING;
  constructor(state: CropState, public settings?: CropSettings) { this.state = state; }
}

@Directive({
  selector: '[appVideocropperctl]'
})
export class VideocropperctlDirective implements AfterViewInit, OnDestroy {
  private element: HTMLElement;
  private downEvent: any;
  private downpos: { x: number; y: number; };
  private cropping = false;
  private enabled = false;

  private cropx: number;
  private cropy: number;
  private cropWidth: number;
  private cropHeight: number;

  @Output() cropEvent = new EventEmitter();

  @Input() videoWidth: number;
  @Input() videoHeight: number;
  private parentElement: HTMLElement;
  private canvasElement: HTMLCanvasElement;
  private unlisteners: (() => void)[] = [];
  private observer: MutationObserver;
  private canvasContext: CanvasRenderingContext2D;

  constructor(public elementRef: ElementRef, private renderer: Renderer2) {
    this.element = <HTMLElement>this.elementRef.nativeElement;

  }

  ngOnDestroy(): void {
    this.observer.disconnect();
    // remove listeners
    this.unlisteners.forEach((fn) => { fn() });
    this.unlisteners = null;
    this.parentElement.removeChild(this.canvasElement);
    this.parentElement = null;
    this.canvasElement = null;
    this.element = null;
    this.canvasContext = null;
  }

  ngAfterViewInit(): void {
    this.canvasElement = this.renderer.createElement('canvas');
    this.setTopCanvasStyle();
    this.parentElement = this.element.parentElement;

    this.renderer.insertBefore(this.parentElement, this.canvasElement, this.element);
    this.canvasContext = this.canvasElement.getContext('2d', { alpha: true });
    console.error('TopCanvas added');
    this.registerListeners();
  }

  private registerListeners() {
    this.unlisteners.push(this.renderer.listen(this.canvasElement, 'tap', (evt) => {
      this.tap(evt);
    }));
    this.unlisteners.push(this.renderer.listen(this.canvasElement, 'panmove', (evt) => {
      this.panmove(evt);
    }));
    this.unlisteners.push(this.renderer.listen(this.canvasElement, 'panstart', (evt) => {
      this.pan(evt);
    }));
    this.unlisteners.push(this.renderer.listen(this.canvasElement, 'panend', (evt) => {
      this.pan(evt);
    }));
    this.unlisteners.push(this.renderer.listen(this.canvasElement, 'pinch', (evt) => {
      this.pinch(evt);
    }));

    this.observer = new MutationObserver((_mutationsList, _observer) => {
      this.setTopCanvasStyle();
    });
    this.observer.observe(this.element, { attributes: true });
  }

  private setTopCanvasStyle() {
    console.log('Seting styles');
    // position: absolute;width: 130px;height:73px; right:0px;top:24px
    let canvasStyle = getComputedStyle(this.element);

    this.canvasElement.style.position = 'absolute';
    this.canvasElement.style.width = canvasStyle.width;
    this.canvasElement.style.height = canvasStyle.height;
    this.canvasElement.style.right = canvasStyle.right;
    this.canvasElement.style.top = canvasStyle.top;
    this.canvasElement.height = this.videoHeight;
    this.canvasElement.width = this.videoWidth;
    this.canvasElement.style.zIndex = this.enabled ? '1000' : '0';
  }

  private tap(evt: any) {
    console.log('VideocropperctlDirective: tap the button', evt);
    this.enableCropping(false);
    this.cropEvent.emit(new CropEvent(CropState.DONE));
  }

  private panmove(event: any) {
    //  console.log('VideocropperctlDirective: panmove the button', event);
    this.mouseMove(event.srcEvent);
  }

  private pan(event: any) {
    //  console.log('VideocropperctlDirective: pan the button', event);
    if (event.type === 'panstart') {
      const offsetX = Math.floor(event.srcEvent.offsetX - event.deltaX);
      const offsetY = Math.floor(event.srcEvent.offsetY - event.deltaY);
      const pageX = Math.floor(event.srcEvent.pageX - event.deltaX);
      const pageY = Math.floor(event.srcEvent.pageY - event.deltaY);
      const nevent = { offsetX: offsetX, offsetY: offsetY, pageX: pageX, pageY: pageY };
      //   console.log('VideocropperctlDirective: PanStart', nevent);
      this.mouseDown(nevent);
    } else if (event.type === 'panend') {
      const offsetX = Math.floor(event.srcEvent.offsetX);
      const offsetY = Math.floor(event.srcEvent.offsetY);
      const nevent = { offsetX: offsetX, offsetY: offsetY, target: event.target };
      //   console.log('VideocropperctlDirective: PanEndt ', nevent);
      this.mouseUp(nevent);
    }
  }

  private pinch(evt: any) {
    console.log('VideocropperctlDirective: pinch the button', evt);
  }
  public mouseMove(evt: any) {
    console.log('VideocropperctlDirective: MouseMove', evt);
    if (!this.downpos || this.cropping) {
      return;
    }
    let cur = this.scale(evt.pageX, evt.pageY);
    console.log('VideocropperctlDirective: MouseMove Scale:', cur);
    let width = Math.abs(cur.x - this.downpos.x);
    let height = Math.abs(cur.y - this.downpos.y);
    let startx = Math.min(this.downpos.x, cur.x);
    let starty = Math.min(this.downpos.y, cur.y);
    this.draw(startx, starty, width, height);
  }

  public mouseDown(event: any) {
    // console.log('VideocropperctlDirective: MouseDown', event.pageX, event.pageY, event.offsetX, event.offsetY);
    this.downpos = this.scale(event.pageX, event.pageY);
    this.downEvent = event;
  }

  public async mouseUp(event: any) {
    console.log('VideocropperctlDirective: MouseUp', event.offsetX, event.offsetY);
    this.downpos = undefined;
    this.draw(0, 0, 0, 0);
    let upx = event.offsetX;
    let upy = event.offsetY;

    let downx = this.downEvent.offsetX;
    let downy = this.downEvent.offsetY;

    let xchange = Math.abs(upx - downx);
    let ychange = Math.abs(upy - downy);
    let totalChange = Math.abs(ychange + xchange);

    if (totalChange > 1) {
      if (!this.cropping) {
        this.crop({ upx: event.offsetX, upy: event.offsetY });
      } else {
        this.move(downx, downy, event.offsetX, event.offsetY);
      }
    }
  }

  private scale(x: number, y: number): { x: number; y: number; } {
    let rect = this.canvasElement.getBoundingClientRect();
    x = x - rect.left;
    y = y - rect.top // scrole?
    x /= rect.width;
    y /= rect.height;
    // x *= Number(this.element.getAttribute('width'));
    // y *= Number(this.element.getAttribute('height'));

    x *= this.videoWidth;
    y *= this.videoHeight;
    x = Math.floor(x);
    y = Math.floor(y);

    return { x: x, y: y };
  }

  private crop(event: { upx: any, upy: any }) {

    let downx = this.downEvent.offsetX;
    let downy = this.downEvent.offsetY;
    let xchange = Math.abs(event.upx - downx);
    let ychange = Math.abs(event.upy - downy);
    const startx = Math.min(downx, event.upx);
    const starty = Math.min(downy, event.upy);
    //  console.log('VideocropperctlDirective: Cropping A:', downx, downy, xchange, ychange, startx, starty, event);

    let rect = this.canvasElement.getBoundingClientRect();


    const widthMultiplyer = (this.videoWidth / rect.width);
    const heightMultiplyer = (this.videoHeight / rect.height);
    //  console.log('VideocropperctlDirective: Multipliers: ', widthMultiplyer, heightMultiplyer, this.topCanvas);
    this.cropx = Math.round(startx * widthMultiplyer);
    this.cropy = Math.round(starty * heightMultiplyer);
    this.cropWidth = Math.round(xchange * widthMultiplyer);
    this.cropHeight = Math.round(ychange * heightMultiplyer);
    //  console.log('VideocropperctlDirective: Cropping', this.cropx, this.cropWidth, this.cropy, this.cropHeight);
    this.setVideoCrop();
    this.cropping = true;
  }

  private move(startXoffset: number, startYoffset: number, endXoffset: number, endYoffset: number) {
    //  console.log('VideocropperctlDirective: Moving');
    let rect = this.canvasElement.getBoundingClientRect();
    const widthMultiplyer = (this.cropWidth / rect.width);
    const heightMultiplyer = (this.cropHeight / rect.height);
    let xchange = Math.abs(endXoffset - startXoffset);
    let ychange = Math.abs(endYoffset - startYoffset);
    const right = (startXoffset < endXoffset);
    const up = (startYoffset > endYoffset);
    xchange *= widthMultiplyer;
    ychange *= heightMultiplyer;
    this.cropx = Math.round(this.cropx += right ? -xchange : xchange);
    this.cropy = Math.round(this.cropy += up ? ychange : -ychange);
    this.setVideoCrop();
  }

  public resetCropping() {
    console.log('VideocropperctlDirective: reseting cropping');
    this.cropx = 0;
    this.cropWidth = 0;
    this.cropy = 0;
    this.cropHeight = 0;
    this.setVideoCrop();
    this.cropping = false;
    this.enabled = false;
  }

  public enableCropping(enabled: boolean) {
    console.log('setCropping', enabled);
    this.enabled = enabled
    if (enabled) {
      this.canvasElement.style.zIndex = '10000';
    } else {
      this.canvasElement.style.zIndex = '0';
    }
  }

  public setCropping(cropSettings: CropSettings) {
    this.enabled = true;
    this.cropx = cropSettings.startx;
    this.cropy =  cropSettings.starty;
    this.cropWidth = cropSettings.width;
    this.cropHeight = cropSettings.height;
    this.cropping = true;
  }

  private setVideoCrop() {
    this.cropEvent.emit(new CropEvent(CropState.CROPPING, new CropSettings(this.cropx, this.cropy, this.cropWidth, this.cropHeight)));
  }

  private draw(px: number, py: number, pw: number, ph: number) {
    console.log('Draw', px, py, pw, ph);
    this.canvasContext.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
    if (pw !== 0 && py !== 0) {
      this.canvasContext.lineWidth = 5;
      this.canvasContext.strokeRect(px, py, pw, ph);
    }
  }
}

