import { EqcallapiService } from './eqcallapi.service';
import { UserLoginService, LoggedInCallback } from './cognito.service';
import { NetworkService } from './network.service';
import { Injectable } from '@angular/core';

export class Key {
    public nickname: string;
    public expiryDate: number;
    public keyCode: string;
    public address: string;
    public email: string;
    public description: string;
    public callin: boolean;
    public callout: boolean;
    public restricted: boolean;
    public presenceOnly = false;
    public userName: string = null;
    public autoConnect = false;
    public fqdn = 'NONE';

    constructor(json: any) {
        this.nickname = json.nickname;
        this.keyCode = json.keyCode;
        this.expiryDate = json.expiryDate;
        this.address = this.keyCode;
        this.email = this.keyCode;
        this.description = json.description;
        this.callin = json.callin;
        this.callout = json.callout;
        this.restricted = json.restricted;
        this.userName = json.userName;
        this.autoConnect = json.autoConnect;
        this.fqdn = json.fqdn;
    }
}

@Injectable()
export class KeyService implements LoggedInCallback {

    public keys: Key[] = [];
    public cache: Key[] = [];

    constructor(public iotSvc: NetworkService, public userSvc: UserLoginService, public api: EqcallapiService) {
        this.userSvc.onceAuthenticated(this);
    }

    public async getFQDNs() {
        return this.api.getDomains();
    }

    public async createFQDN(domainName: string) {
        return this.api.createFQDN(domainName);
    }

    public async deleteFQDN(domainName: string) {
        return this.api.deleteFQDN(domainName);
    }

    isLoggedIn(_message: string, _loggedIn: boolean): void {
        const key = this.userSvc.getKeyCode();
        if (!key) {
            this.getKeys();
        }
    }

    public getKeys() {
        const that = this;
        this.api.getKeys().then((result: any) => {
            that.keys.splice(0, that.keys.length);
            const data = result.data;

            for (let i of data) {
                const key = new Key(i);
                if (key.userName === '-') {
                    key.userName = null;
                }
                that.keys.push(key);
                that.iotSvc.addListenerKey(key, false);
            }
        }).catch((result: any) => {
            console.error('KeyService: getKeys: Exception thrown', result);
        });

    }

    public deleteKey(key: Key) {
        this.api.deleteKey(key.keyCode).then((_result: any) => {
            this.keys.splice(this.keys.indexOf(key), 1);
        }).catch((result: any) => {
            console.error('Keys: Delete Keys error = ' + JSON.stringify(result));
        });
    }

    public async getKey(keyCode: string) {
        let key: Key = null;
        for (let k of this.cache) {
            if (k.keyCode === keyCode) {
                key = k;
                break;
            }
        }
        if (!key ) {
            return this.api.getKey(keyCode).then((result: any) => {
                const data = result.data;
                if (data) {
                    key = new Key(data);
                    this.cache.push(key);
                    if (key.userName === '-') {
                        key.userName = null;
                    }
                }
                return key;
            });
        } else {
            return key;
        }
    }

    public getKeyByCode(keyCode: string): Key {
        for (let k of this.keys) {
            if (k.keyCode === keyCode) {
                return k;
            }
        }
        return null;
    }

    public createKey(hours: number) {
        this.api.createKey(hours).then((result: any) => {
            const data = result.data;
            const key = new Key(data);
            key.userName = null;
            this.keys.push(key);
            this.iotSvc.addListenerKey(key, false);
        }).catch((result: any) => {
            console.error('Exception thrown', result.stack);
            console.error('Keys: Create Keys error = ' + JSON.stringify(result));
        });
    }

    public updateKey(key: Key) {
        if (!key.userName) {
            key.userName = '-';
        }
        this.api.updateKey(key).then((_result: any) => {
        }).catch((result: any) => {
            console.error('KeyService: updateKey: error = ' + JSON.stringify(result));
        });
    }

}
