import { Injectable } from '@angular/core';
import { ChannelElementComponent } from '../channel-element/channel-element.component';
import { ChannelControllerComponent } from '../channel-controller/channel-controller.component';

@Injectable({
    providedIn: 'root'
})
export class MixerService {
    public mixers: ChannelElementComponent[] = [];
    controller: ChannelControllerComponent;

    constructor() { }
    public registerMixer(mixer: ChannelElementComponent): any {
        this.mixers.push(mixer);
        if (this.mixers.length === 1 && this.controller) {
            this.controller.setSelectedMixer(mixer);
        }
        this.soloEvent();
    }

    public registerController(controller: ChannelControllerComponent): any {
        this.controller = controller;
        if (this.controller && this.mixers.length > 0) {
            this.controller.setSelectedMixer(this.mixers[0]);
        }
    }

    public unregisterMixer(mixer: ChannelElementComponent) {
        for (let idx = 0; idx < this.mixers.length; idx++) {
            if (this.mixers[idx].fid === mixer.fid) {
                this.mixers.splice(idx, 1);
                break;
            }
        }
        if (this.controller) {
            this.controller.mixerRemoved(mixer);
        }
        this.soloEvent();
    }

    public soloEvent() {
        console.log('Solo event');
        if (this.haveSolo()) {
            this.mixers.forEach((mixer) => {
                mixer.rtcConnection.muteRemoteAudio(!mixer.soloed);
            }
            );
        } else {
            this.mixers.forEach((mixer) => {
                mixer.rtcConnection.muteRemoteAudio(mixer.muted);
            }
            );
        }
    }

    public haveSolo(): boolean {
        let ret = false;
        this.mixers.forEach((mixer) => {
            if (mixer.soloed) {
                console.log('Have solo ' + mixer.eqID);
                ret = true;
            }
        });
        return ret;
    }

    public selectionChanged(mixer: ChannelElementComponent) {
        this.controller.setSelectedMixer(mixer);
    }
}
