import { Component, Input, AfterViewInit } from '@angular/core';
import { TranslationService } from '../../../service/translation.service';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['../../../../assets/css/custom.css']
})
export class AccountComponent implements AfterViewInit {
    private _show = 'NONE';
    public next = 'NONE';
    @Input()
    set show(show: string) {
        this._show = show
        this.setShow();
    }

    get show(): string { return this._show; }
    constructor(public tr: TranslationService) {
    }

    ngAfterViewInit(): void {
        if (this._show !== 'NONE') {
            this.setShow();
        }
    }

    private setShow() {
        let parts = this._show.split(',');
        let us = parts[0];
        if (parts.length > 1) {
            this.next = this._show.substring(this._show.indexOf(',') + 1);
        }
        if (us === 'ADDONS') {
            (<any>$('#accountTab a[href="#addons"]')).tab('show')
        } else if (us === 'HISTORY') {
            (<any>$('#accountTab a[href="#history"]')).tab('show')
        } else if (us === 'PAYMENT') {
            (<any>$('#accountTab a[href="#methods"]')).tab('show')
        } else if (us === 'PROFILE') {
            (<any>$('#accountTab a[href="#profile"]')).tab('show')
        }
    }
}

