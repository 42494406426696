<!-- In Call Buttons -->
<div *ngIf="!showButtons" class="open-buttons" (click)="toggleButtons()"
	style="position: absolute; height: 10px; z-index: 1; bottom: 9px; width: 100%; border: 0px solid #fff; text-align: center;">
	<i class="fa fa-chevron-up"
		style="width: 22px; color: #ecf0f1; background: rgba(76, 119, 154, 0.59);"
		[ngStyle]="{'color':chatStatus ? '#c0392b' : '#fff'}"></i>
</div>
<div *ngIf="showButtons" class="row no-gutters"
	style="height: 43px; border: 1px solid #2c3e50; padding-bottom: 0px; text-align: center;">
	<button class="btn-rtc btn-outline-light"
		[ngClass]="{'btn-danger':muteAudio,'btn-outline-light':!muteAudio}"
		(click)="toggleAudioMute()" data-toggle="tooltip" data-placement="top"
		[title]="tr.get('mastercontrol','mute.m')"
		style="height: 41px; width: 16.66%;">
		<i class="fa"
			[ngClass]="{'fa-microphone-slash':muteAudio,'fa-microphone':!muteAudio}"></i>
	</button>
	<button class="btn-rtc btn-outline-light"
		[ngClass]="{'btn-danger':muteVideo,'btn-outline-light':!muteVideo}"
		(click)="toggleVideoMute()" data-toggle="tooltip" data-placement="top"
		[title]="tr.get('mastercontrol','mute.v')"
		style="height: 41px; width: 16.66%">
		<i class="fa"
			[ngClass]="{'fa-eye-slash':muteVideo,'fa-video-camera':!muteVideo}"></i>
	</button>
	<label (click)="toggleChat()" class="btn-rtc btn-outline-light"
		for="eqMenutoggler-GlobalChat" style="height: 41px; width: 16.66%"><i
		class="fa fa-comments"
		[ngStyle]="{'color':chatStatus ? '#c0392b' : '#fff'}"></i> <span></span>
	</label>
	<button class="btn-rtc btn-outline-light" type="button"
		data-toggle="modal" data-target="#hangupModal" id="hangup"
		data-placement="top" [title]="tr.get('mastercontrol','bandwidtht')"
		style="height: 41px; width: 16.66%">
		<i class="fa fa-eject" style="font-size: 13px;"></i>&nbsp;|&nbsp;<i
			*ngIf="!onHold" class="fa fa-pause" style="font-size: 13px;"></i><i
			*ngIf="onHold" style="color: #c0392b; font-size: 13px;"
			class="fa fa-pause blink_contacts"></i>
	</button>
	<button class="btn-rtc btn-outline-light" type="button"
		data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
		[title]="tr.get('mastercontrol','bandwidtht')"
		style="height: 41px; width: 16.66%">
		<i class="fa fa-ellipsis-h"
			[ngStyle]="{'color':onHold || shareing || showVolume || showMixer? '#c0392b' : '#fff'}"></i>
	</button>
	<div class="dropdown-menu" aria-labelledby="dropdownHow">
		<button *ngIf="!deviceStatus" (click)="toggleonDS()"
			class="dropdown-item" type="button" id="td" data-placement="top"
			[title]="tr.get('mastercontrol','bandwidtht')">Devices</button>
		<button *ngIf="deviceStatus" (click)="toggleoffDS()"
			class="dropdown-item" type="button" id="td" data-placement="top"
			[title]="tr.get('mastercontrol','bandwidtht')">Close Devices</button>
		<button *ngIf="!shareing" class="dropdown-item"
			[ngClass]="{'btn-danger':shareing,'btn-outline-light':!shareing}"
			type="button" data-toggle="modal" data-target="#screenShareModal"
			id="screenShare" data-placement="top"
			[title]="tr.get('mastercontrol','share.dt')" aria-expanded="false"
			(click)="updateShareDescktopSelectors()">Share Screen</button>
		<button *ngIf="shareing" class="dropdown-item"
			[ngClass]="{'btn-danger':shareing,'btn-outline-light':!shareing}"
			type="button" data-toggle="modal" data-target="#screenShareModal"
			id="screenShare" data-placement="top"
			[title]="tr.get('mastercontrol','share.dt')" aria-expanded="false">
			<span style="color: #c0392b">Stop Share</span>
		</button>
		<button class="dropdown-item" type="button" data-toggle="modal"
			data-target="#switchViewModal" id="switchView" data-placement="top"
			[title]="tr.get('mastercontrol','bandwidtht')">Change View</button>
		<button class="dropdown-item" type="button" data-toggle="modal"
			data-target="#bandwidthModal" id="bandwidth" data-placement="top"
			[title]="tr.get('mastercontrol','bandwidtht')">Bandwidth</button>
		<button (click)="toggleMasterVolume()" class="dropdown-item"
			[ngClass]="{'btn-danger':showVolume,'btn-outline-light':!showVolume}"
			type="button" data-placement="top"
			[title]="tr.get('mastercontrol','share.dt')" aria-expanded="false">
			<span *ngIf="!showVolume">Master Volume</span><span
				*ngIf="showVolume" style="color: #c0392b">Close Volume</span>
		</button>
		<label (click)="toggleMixer()" class="dropdown-item"
			for="eqMenutoggler-Mixer"><span *ngIf="!showMixer">Call
				Mixer</span><span *ngIf="showMixer" style="color: #c0392b">Close
				Mixer</span> <span></span> </label>
		<!-- <div class="dropdown-divider"></div>
    <button class="dropdown-item" data-toggle="modal" data-target="#AdvancedOptionsModal" id="switchView" type="button"  data-placement="top" [title]="tr.get('mastercontrol','bandwidtht')"><span>Advanced Options</span></button> -->
	</div>

	<button (click)="toggleButtons()" class="btn-rtc btn-outline-light"
		type="button" style="height: 41px; width: 16.67%">
		<i class="fa fa-chevron-down"></i>
	</button>
</div>
<!-- In Call VU Meter -->
<app-main-channel *ngIf="vuMeterStatus && showButtons && !muteAudio"
	[vuMeterStatus]="vuMeterStatus"></app-main-channel>
<!-- In Call Master Volume Dismiss -->
<div *ngIf="showVolume && showButtons"
	style="position: fixed; width: 58px; border: 4px solid #2c3e50; left: 1px; background-color: rgb(68, 104, 135); margin: 0;"
	[ngStyle]="{'z-index':showMixer ? '7' : '1', 'transform':showMixer ? 'none' : 'translateY(-50%)', '-ms-transform':showMixer ? 'none' : 'translateY(-50%)', 'top':showMixer ? '0px' : 'calc(50% - 21px)', 'height':showMixer ? '254px' : '316px' }">
	<button (click)="toggleMasterVolume()" class="btn btn-primary btn-sm"
		type="button"
		style="position: absolute; top: 0px; width: 20px; height: 21px; box-shadow: none; outline: none; border: none; left: 0px; color: #fff; background-color: #2c3e50; border-radius: 0; z-index: 8;">
		<i class="fa fa-chevron-left"
			style="margin-left: -6px; position: absolute; margin-top: -8px;"></i>
	</button>
	<app-main-channel [showVolume]="showVolume" [showMixer]="showMixer"></app-main-channel>
</div>
<!-- Hangup/Hold Warning Modal -->
<div class="modal fade" id="hangupModal" data-backdrop="false"
	tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">End or Hold the Call?</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-footer d-flex justify-content-center">
				<button (click)="endAllCalls()" type="button"
					class="btn btn-warning" data-dismiss="modal">End Call</button>
				<button (click)="toggleOnHold()" *ngIf="!onHold" type="button"
					class="btn btn-danger" data-toggle="modal"
					data-target="#hangupModal" id="hangup">Hold Call</button>
				<button (click)="toggleOnHold()" *ngIf="onHold" type="button"
					class="btn btn-danger" data-toggle="modal"
					data-target="#hangupModal" id="hangup">Resume Call</button>
				<button type="button" class="btn btn-primary" data-dismiss="modal">Cancel</button>
			</div>
		</div>
	</div>
</div>
<!-- Bandwidth Modal -->
<div class="modal fade" id="bandwidthModal" data-backdrop="false"
	tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">In Call Bandwidth Allocation</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row no-gutters"
					style="background-color: transparent; color: #bdc3c7; height: 63px; border: 1px solid #2c3e50; padding-bottom: 0px;">
					<div class="col-sm vert-auto"
						style="color: #bdc3c7; line-height: 0.5; border: 0px solid #34495e; padding-top: 10px; padding-bottom: 5px; padding-right: 20px; left: 10px; text-align: center;">
						<input id="masterbwSlider" data-slider-min="85"
							data-slider-max="2000" data-slider-step="5" data-slider-value="0"
							data-toggle="tooltip" data-placement="top"
							title="Increase/Decrease TotalBandwidth, 2000 = unlimited">
						<span id="masterbwSlider" style="padding-bottom: 0px"><small>{{tr.get("mastercontrol","bandwidth")}}&nbsp;<span
								id="masterbwValue"></span>{{tr.get("mastercontrol","bandwidth.kb")}}
						</small> </span>
					</div>
				</div>
			</div>
			<div class="modal-footer d-flex justify-content-center">
				<button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>
<!-- Share Screen Modal -->
<div class="modal fade" id="screenShareModal" data-backdrop="false"
	tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Screen Shareing Settings</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body ta-left">
				<div>
					<div class="row">
						<div class="col-auto">
							<input type="checkbox" checked [(ngModel)]="shareHiRes"
								(ngModelChange)="saveShareSettings($event)" value="Native"
								name="a" class="checkbox-template" data-placement="top"
								[title]="tr.get('mastercontrol','native.rt')">
						</div>
						<div class="col">{{tr.get("mastercontrol","native.r")}}</div>
					</div>
					<div class="row">
						<div class="col-auto">
							<input type="checkbox" [(ngModel)]="shareHiRate"
								(ngModelChange)="saveShareSettings($event)" value="Video"
								name="a" class="checkbox-template" data-placement="top"
								[title]="tr.get('mastercontrol','optimise.vt')">
						</div>
						<div class="col">{{tr.get("mastercontrol","optimise.v")}}</div>
					</div>
					<div class="row">
						<div class="col-auto">
							<input type="checkbox" [(ngModel)]="shareAudio" value="Video"
								name="a" class="checkbox-template" data-placement="top"
								[title]="tr.get('mastercontrol','share.at')">
						</div>
						<div class="col">{{tr.get("mastercontrol","share.a")}}</div>
					</div>
				</div>
			</div>
			<div class="modal-footer d-flex justify-content-center">
				<button *ngIf="!shareing" class="btn btn-outline-primary"
					(click)="toggleShareing()" data-toggle="tooltip"
					data-placement="top"
					[title]="shareing ? 'Stop Shareing Desktop' : 'Share Desktop' "
					style="border-color: #37cfdc;" data-dismiss="modal">Share</button>
				<button *ngIf="shareing" class="btn btn-outline-primary"
					(click)="toggleShareing()" data-toggle="tooltip"
					data-placement="top"
					[title]="shareing ? 'Stop Shareing Desktop' : 'Share Desktop' "
					style="border-color: #37cfdc;" data-dismiss="modal">Stop</button>
				<button type="button" class="btn btn-primary" data-dismiss="modal">Cancel</button>
			</div>
		</div>
	</div>
</div>
<!-- Advanced Options Modal -->
<!--<div class="modal fade" id="AdvancedOptionsModal" data-backdrop="false" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Advanced In-call Options
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="No">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ta-left">
        <div class="row no-gutters">
          <div class="col-sm">
            <div class="container-fluid">
              <button (click)="toggleVU()" class="btn-rtc btn-primary dropdown-item" type="button" data-placement="top" [title]="tr.get('mastercontrol','bandwidtht')"><span *ngIf="vuMeterStatus"><i class="fa fa-bell-o mr-3" style="width: 12px; color: #ecf0f1"></i>Turn Off Mic Indicator</span><span *ngIf="!vuMeterStatus"><i class="fa fa-bell mr-3" style="width: 12px; color: #ecf0f1"></i>Turn On Mic Indicator</span></button>
              <button (click)="toggleControls()" class="btn-rtc btn-primary dropdown-item" type="button" data-placement="top" [title]="tr.get('mastercontrol','bandwidtht')"><span><i class="fa fa-sliders mr-3" style="width: 12px; color: #ecf0f1"></i>Hide Overlay Controls</span></button>
              <button (click)="toggleSO()" class="btn-rtc btn-primary dropdown-item" type="button" data-placement="top" [title]="tr.get('mastercontrol','bandwidtht')"><span *ngIf="!settingsOverlay" ><i class="fa fa-sticky-note-o mr-3" style="width: 12px; color: #ecf0f1"></i>Disable Settings Overlay</span><span *ngIf="settingsOverlay" ><i class="fa fa-sticky-note mr-3" style="width: 12px; color: #ecf0f1"></i>Enable Settings Overlay</span></button>
              <button (click)="toggleMasterVolume()" class="btn-rtc btn-primary dropdown-item" type="button" data-placement="top" [title]="tr.get('mastercontrol','bandwidtht')"><span *ngIf="!showVolume"><i class="fa fa-volume-off mr-3" style="width: 12px; color: #ecf0f1"></i>Show In-call Volume</span><span *ngIf="showVolume"><i class="fa fa-volume-up mr-3" style="width: 12px; color: #ecf0f1"></i>Hide In-call Volume</span></button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>-->

