<!-- Ringing Modal -->
<div *ngIf="ringing" class="modal fade ringer" id="ringerRequest"
	tabindex="-1" role="dialog" aria-labelledby="ringerTitle"
	aria-hidden="true"
	style="background: linear-gradient(rgba(169, 219, 253, 0.8) 0%, rgb(0, 0, 0) 100%); pointer-events: none; width: 100vw; height: 100vh">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document"
		style="z-index: 201">
		<div class="modal-content"
			style="background: linear-gradient(rgb(0, 0, 0, 0.8) 0%, rgb(80, 126, 163) 100%);">
			<div class="modal-header"
				style="background-color: #2c3e50; border-bottom: 5px solid #2c3e50;">
				<h5 class="modal-title" id="ringerLongTitle">
					Incoming Call{{tr.get("criticallRP", "inc.title")}} <span class="d">&nbsp;.</span>
					<span class="d d-2">.</span> <span class="d d-3">.</span>
				</h5>
				<button (click)="hangup()" type="button" class="close"
					data-dismiss="modal" aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body"
				style="background-color: #34495e; color: #fff">
				<p>{{tr.get("criticallRP", "inc.msg")}} {{contact.nickname}}</p>
				<div *ngIf="!alreadyingroup && !joinGroup" class="row">
					<div class="col-sm">
						<button type="button" (click)="answerAudio()"
							class="btn btn-warning mr-2" data-dismiss="modal">
							<i class="material-icons" style="margin-top: 5px;">mic</i>
						</button>
						<button type="button" (click)="answerVideo()"
							class="btn btn-primary mr-2" data-dismiss="modal">
							<i class="material-icons" style="margin-top: 5px;">videocam</i>
						</button>
						<button type="button" (click)="hangup()" class="btn btn-danger"
							data-dismiss="modal">
							<i class="material-icons" style="margin-top: 5px;">call_end</i>
						</button>
					</div>
				</div>
				<div class="row mt-3" *ngIf="alreadyingroup || joinGroup">
					<div class="col-sm">
						<button *ngIf="joinGroup && !alreadyingroup" type="button"
							(click)="groupVideo()" class="btn btn-primary mr-2"
							data-dismiss="modal">Join with Video</button>
						<button *ngIf="joinGroup && !alreadyingroup" type="button"
							(click)="groupAudio()" class="btn btn-warning mr-2"
							data-dismiss="modal">Join with Audio Only</button>
						<button *ngIf="alreadyingroup" type="button"
							(click)="groupVideo()" class="btn btn-primary mr-2"
							data-dismiss="modal">Add to Call</button>
						<button type="button" (click)="hangup()"
							class="btn btn-danger mr-2" data-dismiss="modal">Decline</button>
					</div>
				</div>
				<div class="row mt-3" *ngIf="alreadyingroup">
					<div class="col-sm">
						<a href="" data-toggle="collapse" data-parent="#accordion"
							data-target="#collapseAdvanced" aria-expanded="false"
							data-placement="top">Advanced Options</a>
					</div>
				</div>
			</div>
			<div class="modal-footer justify-content-center"
				style="border-top: none; background-color: #34495e; color: #fff">
				<!-- Answering Options -->
				<div id="collapseAdvanced" class="panel-collapse collapse"
					aria-expanded="false">
					<h3>Place Current Call on Hold and Answer</h3>
					<button type="button" (click)="answerAudio()"
						class="btn btn-warning mr-2" data-dismiss="modal">
						<i class="material-icons" style="margin-top: 5px;">mic</i>
					</button>
					<button type="button" (click)="answerVideo()"
						class="btn btn-primary mr-2" data-dismiss="modal">
						<i class="material-icons" style="margin-top: 5px;">videocam</i>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
