
import { MessageObserver, SystemBusService } from '../../../service/system-bus.service';
import { Component, AfterViewInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
declare var Slider: any;
import { TranslationService } from '../../../service/translation.service';
import { StreamhandlerService } from '../../../service/streamhandler.service';
import { LivekitService } from '../../../service/livekit.service';


@Component({
    selector: 'app-mastercontrol',
    templateUrl: './mastercontrol.component.html',
    styleUrls: ['../../../../assets/css/custom.css']
})
export class MastercontrolComponent implements AfterViewInit, MessageObserver, OnDestroy {
    vuTimer: NodeJS.Timer;
    public muteAudio = false;
    public shareAudio = false;
    public muteVideo = false;
    public onHold = false;
    public openChat = false;
    public showMixer = false;
    public showControls = false;
    public shareing = false;
    public shareHiRes = false;
    public shareHiRate = false;
    showButtons = true;
    showAdvanced = false;
    vuMeterStatus = true;
    settingsOverlay = false;

    @Input() chatStatus: boolean;
    @Input() showVolume: boolean;
    @Input() showControl: boolean
    @Input() deviceStatus: boolean;

    rtcButtons = true;
    @Output() messageEvent = new EventEmitter();
    @Output() volumeEvent = new EventEmitter();
    @Output() controlEvent = new EventEmitter();
    @Output() deviceonEvent = new EventEmitter();
    @Output() deviceoffEvent = new EventEmitter();
    @Output() soverlayEvent = new EventEmitter();

    constructor(public rtcSvc: LivekitService, public systemBus: SystemBusService,
        public tr: TranslationService, private streamHandler: StreamhandlerService) {
        this.muteAudio = streamHandler.masterAudioMute;
        this.muteVideo = streamHandler.masterVideoMute;
        this.onHold = rtcSvc.masterHold;
        systemBus.subscribe(this);
    }

    ngOnDestroy(): void {
        this.systemBus.unSubscribe(this)
    }

    ngAfterViewInit() {

        const bwSlider = new Slider('#masterbwSlider');
        bwSlider.on('change', (bwValue: any) => {
            this.rtcSvc.setBandwidth(bwValue.newValue);
            document.getElementById('masterbwValue').textContent = bwValue.newValue;
        });
        bwSlider.on('slide', (bwValue: any) => {
            this.rtcSvc.setBandwidth(bwValue);
            document.getElementById('masterbwValue').textContent = bwValue;
        });
        bwSlider.setValue(this.rtcSvc.bandwidthKb);
        document.getElementById('masterbwValue').textContent = bwSlider.getValue();
    }

    public async updateShareDescktopSelectors() {
        if (this.shareing) {
            setTimeout(() => this.toggleShareing(), 1000);
            return;
        }
        let streamHandler = this.rtcSvc.streamHandler;
        this.shareHiRate = streamHandler.shareHiRate;
        this.shareHiRes = streamHandler.shareHiRes;
        console.log('Updated share settings ' + this.shareHiRate + ' ' + this.shareHiRate);
    }

    public saveShareSettings(_event: any) {
        console.log(this.shareHiRate + ' ' + this.shareHiRes);
        let sh = this.rtcSvc.streamHandler;
        sh.setShareSettings(this.shareHiRate, this.shareHiRes);
    }

    public toggleAudioMute() {
        console.log('MasterVolume: Audio Muteed ' + !this.muteAudio);
        this.muteAudio = !this.muteAudio;
        this.streamHandler.setMasterAudioMute(this.muteAudio);
    }

    public toggleVideoMute() {
        console.log('MasterVolume: Video Muteed ' + !this.muteVideo);
        this.muteVideo = !this.muteVideo;
        this.streamHandler.setMasterVideoMute(this.muteVideo);
    }

    public toggleOnHold() {
        console.log('MasterVolume: onHole ' + !this.onHold);
        this.onHold = !this.onHold;
        this.rtcSvc.setMasterHold(this.onHold);
    }

    public toggleChat() {
        this.openChat = !this.openChat;
        this.chatStatus = !this.chatStatus;
        if (this.showMixer = true) {
            this.showMixer = false;
        }
    }

    public toggleMixer() {
        this.showMixer = !this.showMixer;
        if (this.openChat = true) {
            this.openChat = false;
            this.chatStatus = false;
            this.showVolume = true;
            this.volumeEvent.emit(this.showVolume);
        }
    }

    public toggleButtons() {
        this.rtcButtons = !this.rtcButtons;
        this.showButtons = !this.showButtons;
        this.showControls = false;
        this.controlEvent.emit(this.showControls)
    }

    public toggleControls() {
        this.showControls = false;
        this.controlEvent.emit(this.showControls)
    }

    public toggleAdvanced() {
        this.showAdvanced = true;
    }

    public toggleVU() {
        this.vuMeterStatus = !this.vuMeterStatus;
    }

    public toggleonDS() {
        this.deviceStatus = true;
        this.deviceonEvent.emit(this.deviceStatus);
    }

    public toggleoffDS() {
        this.deviceStatus = false;
        this.deviceoffEvent.emit(this.deviceStatus);
    }

    public toggleMasterVolume() {
        this.showVolume = !this.showVolume;
        this.volumeEvent.emit(this.showVolume);
    }

    public toggleSO() {
        this.settingsOverlay = !this.settingsOverlay;
        this.soverlayEvent.emit(this.settingsOverlay);
    }

    public toggleShareing() {
        this.rtcSvc.shareDesktop(!this.shareing, this.shareAudio);
    }

    public endAllCalls() {
        this.rtcSvc.endAllCalls();
    }

    onBusMessage(message: any, type: string): void {
        if (type === 'rtc/masterVideoMute') {
            if (this.muteVideo !== message) {
                this.toggleVideoMute();
            }
        } else if (type === 'rtc/masterAudioMute') {
            if (this.muteAudio !== message) {
                this.toggleAudioMute();
            }
        } else if (type === 'rtc/desktopShare/unshared') {
            this.shareing = false;
            (<any>$('#collapseScreenShare')).collapse('hide');
        } else if (type === 'rtc/desktopShare/shared') {
            this.shareing = true;
            (<any>$('#collapseScreenShare')).collapse('hide');
        }
    }

    busMessageFilter(messageType: string): boolean {
        return messageType.startsWith('rtc/');
    }
}
