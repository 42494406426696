<div
	style="padding-top: 0px; background-color: transparent; border: 0px solid #2c3e50;"
	(mouseleave)="showInfo(false)" (mouseenter)="showInfo(true)">
	<div id="videoDiv" [ngStyle]="{  'display': localV ? 'block': 'none' }">
		<video id="videoInput" class="local-video" autoplay playsinline
			[ngStyle]="{  'z-index': useCanvas ? '3': '3', 'position': useCanvas ?  'fixed':'absolute', 'visibility': useCanvas ? 'collapse':'visible', 'right': rtcFullscreen || rtcSwitchview ? '0px': '0px'}"
			[attr.width]="useCanvas ? 1 : 640"
			[attr.height]="useCanvas ? 1 : 360" (playing)="videoPlaying($event)"
			[style.width]="canvasElementWidth +'px'"
			[style.height]="canvasElementHeight + 'px'" draggable="false">
		</video>
		<video class="local-video" autoplay playsinline
			style="z-index: 1; position: fixed; background: #2c3e50; visibility: collapse;"
			id="audioOut" width="1" height="1" (play)="videoPlaying($event)">
		</video>
		<span *ngIf="cropV && !cropS" class="local-video"
			[style.width]="canvasElementWidth +'px'"
			[style.height]="canvasElementHeight + 29 + 'px'"
			style="z-index: 3; position: absolute; background: #7f8c8d; border: 1px solid #7f8c8d; margin-top: -29px;">
			<span style="margin-top: 1px">Cropping Mode</span>
		</span> <span *ngIf="cropV && !cropS" class="local-video"
			[style.width]="canvasElementWidth +'px'"
			[style.height]="canvasElementHeight + 'px'"
			style="z-index: 3; position: absolute; background: rgba(220, 53, 69, 0.23); border: 5px solid #7f8c8d; margin-top: -5px;"><button
				(click)="startCrop()" type="button" class="btn btn-primary"
				style="margin-top: 50px; color: #34495e">Start Cropping</button>
			<button type="button" class="btn btn-primary ml-3"
				data-placement="top" data-toggle="modal" data-target="#ccModal"
				data-dismiss="modal" aria-label="No" style="margin-top: 50px;">
				<i class="fa fa-info" style="width: 12px; color: #34495e"></i>
			</button>
			<button (click)="stopCrop()" type="button"
				class="btn btn-warning ml-3" aria-label="No"
				style="margin-top: 50px; color: #34495e">Exit</button></span> <span
			*ngIf="!useCanvas" class="local-video" data-toggle="modal"
			data-target="#ccModal" [style.width]="canvasElementWidth +'px'"
			[style.height]="canvasElementHeight + 29 + 'px'"
			style="z-index: 3; position: absolute; margin-top: 0px;"></span> <span
			*ngIf="cropS" class="local-video blink_me"
			[style.width]="canvasElementWidth +'px'"
			[style.height]="canvasElementHeight + 29 + 'px'"
			style="z-index: 2; position: absolute; border: 5px solid #3498db; margin-top: -5px;"></span>
		<canvas appVideocropperctl (cropEvent)="cropEvent($event)"
			[videoWidth]="videoWidth" [videoHeight]="videoHeight"
			id="videoCanvas" class="local-video" *ngIf="useCanvas"
			(tap)="tap($event)" [width]="videoWidth" [height]="videoHeight"
			style="z-index: 2; background-color: #1e272e; position: absolute; border: 1px solid #2c3e50; image-rendering: pixelated"
			[ngStyle]="{  'right': cropV ? '5px': '0'}"
			[style.width]="canvasElementWidth +'px'"
			[style.height]="canvasElementHeight + 'px'" draggable="false">
    </canvas>
	</div>
	<div *ngIf="camInfo && showControls && localV">
		<div *ngIf="showControls" class="dropdown-rtc dropleft"
			style="z-index: 4; margin-top: 22px;">
			<button class="btn-rtc btn-outline-light btn-sm rtc-right-menu"
				data-toggle="modal" data-target="#coModal" title="My Video Options"
				style="box-shadow: none; outline: none; border: none;">
				<i class="fa fa-ellipsis-v" style="width: 12px; color: #fff;"></i>
			</button>
		</div>
	</div>
	<div *ngIf="!localV && showControls" class="local-video"
		style="position: absolute; z-index: 4;">
		<button type="button" class="btn-rtc btn-primary dropdown-item-alt"
			data-toggle="modal" data-target="#dcModal"
			style="background: rgba(220, 53, 69, 0.23);">
			<i class="fa fa-chevron-left"
				style="color: rgba(236, 240, 241, 0.34);"></i>
		</button>
	</div>
</div>
<!-- Cam Options Modal -->
<div class="modal fade" id="coModal" data-backdrop="false" tabindex="-1"
	role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title ml-5">My Video Options</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body ta-left">
				<div class="row no-gutters">
					<div class="col-sm">
						<div class="container-fluid">
							<button (click)="activeON()"
								class="btn-rtc btn-primary dropdown-item" data-placement="top"
								style="position: relative;" type="button" data-toggle="modal"
								data-target="#camonlyModal" data-dismiss="modal" aria-label="No">
								<i class="fa fa-cogs mr-3" style="width: 12px; color: #ecf0f1"></i>Camera
								Settings
							</button>
							<button data-placement="top" type="button" data-toggle="modal"
								data-target="#dcModal" class="btn-rtc btn-primary dropdown-item"
								[ngClass]="{'btn-danger active':!localV,'btn-default':localV}"
								data-dismiss="modal" aria-label="No">
								<i class="fa mr-3" style="width: 12px; color: #ecf0f1"
									[ngClass]="{'fa-chevron-right':localV,'fa-chevron-left':!localV}"></i>Dock
								Camera
							</button>
							<button class="btn-rtc btn-primary dropdown-item"
								data-placement="top" style="position: relative;" type="button"
								data-toggle="modal" data-target="#cbModal" data-dismiss="modal"
								aria-label="No">
								<i class="fa fa-image mr-3" style="width: 12px; color: #ecf0f1"></i>Add
								Background Image
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Crop Cam Modal -->
<div class="modal fade" id="ccModal" data-backdrop="false" tabindex="-1"
	role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">
					Camera Cropping <span *ngIf="useCanvas">(ON)</span><span
						*ngIf="!useCanvas">(OFF)</span>
				</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div *ngIf="useCanvas">Click your local video and drag to crop
					the portion visible to others.</div>
				<div *ngIf="!useCanvas">Camera cropping is disabled, please
					enable it first.</div>
			</div>
			<div class="modal-footer d-flex justify-content-center">
				<div>
					<button (click)="activeON()" *ngIf="!useCanvas" type="button"
						class="btn btn-primary" data-dismiss="modal" data-toggle="modal"
						data-target="#camonlyModal" id="td">Camera Settings</button>
					<button *ngIf="!useCanvas" type="button"
						class="btn btn-warning ml-3" data-dismiss="modal" aria-label="No">Exit</button>
				</div>
				<div>
					<button *ngIf="useCanvas" data-dismiss="modal" type="button"
						class="btn btn-primary">Got it!</button>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Dock Cam Modal -->
<div class="modal fade" id="dcModal" data-backdrop="false" tabindex="-1"
	role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Heads Up!</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div *ngIf="localV" class="modal-body">
				<div>You are about to dock your local video. Your video is
					still broadcasting and others can see you, please use the camera
					ON/OFF toggle at the bottom if you would like to disable your video
					broadcast.</div>
			</div>
			<div class="modal-footer d-flex justify-content-center">
				<button *ngIf="localV" (click)="hideLocal()" type="button"
					class="btn btn-primary" data-dismiss="modal" data-toggle="modal"
					data-target="#dcModal" id="td">Dock Local Video</button>
				<button *ngIf="!localV" (click)="hideLocal()" type="button"
					class="btn btn-primary" data-dismiss="modal" data-toggle="modal"
					data-target="#dcModal" id="td">Un-Dock Local Video</button>
				<button type="button" class="btn btn-warning ml-3"
					data-dismiss="modal" aria-label="No">Cancel</button>
			</div>
		</div>
	</div>
</div>
<!-- Cam Background Modal -->
<div class="modal fade" id="cbModal" data-backdrop="false" tabindex="-1"
	role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered eqmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Choose a Background</h5>
				<button type="button" class="close" data-dismiss="modal"
					aria-label="No">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">Feature Coming Soon!</div>
			<div class="modal-footer d-flex justify-content-center">
				<button type="button" class="btn btn-warning ml-3"
					data-dismiss="modal" aria-label="No">OK</button>
			</div>
		</div>
	</div>
</div>