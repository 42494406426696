
import { Component, AfterViewInit, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SystemBusService, MessageObserver } from '../../../service/system-bus.service';
import { StreamhandlerService } from '../../../service/streamhandler.service';
import { ConfigService } from '../../../service/config.service';
import { VideocropperctlDirective, CropEvent, CropState } from '../videocropperctl.directive';

@Component({
    selector: 'app-videocanvas',
    templateUrl: './videocanvas.component.html',
    styleUrls: ['../../../../assets/css/custom.css']
})

export class VideocanvasComponent implements AfterViewInit, OnInit, MessageObserver {

    @Input() rtcFullscreen: boolean;
    @Input() rtcSwitchview: boolean;
    @Input() showControls: boolean;

    @Output() activeEvent = new EventEmitter();
    @Output() controlsEvent = new EventEmitter();
    @Output() overlayEvent = new EventEmitter();
    @ViewChild(VideocropperctlDirective) child!: VideocropperctlDirective;
    videoHeight: number;
    videoWidth: number;
    private defaultCanvasWidth = 128;

    public canvasElementWidth = 128;
    public canvasElementHeight = 73;
    private canvastoggle = false;
    public useCanvas = false;
    public cropV = false;
    public cropS = false;
    public overlayCropping = false;
    public cropModal = true;
    public localV = true;
    public camActive = true;
    canvas: HTMLCanvasElement;
    camInfo: any;

    constructor(private sysBus: SystemBusService, private streamHandler: StreamhandlerService, public config: ConfigService) {
        this.sysBus.subscribe(this);
    }

    onBusMessage(_message: any, messageType: string): void {
        if (messageType === 'rtc/setting/useCanvas') {
            console.error('UseCanvas message', this.canvas);
            this.useCanvas = this.streamHandler.getUseCanvasSetting();
            if (this.useCanvas) {


            } else {
                this.canvasElementWidth = this.defaultCanvasWidth;
                this.canvasElementHeight = 73;
                this.canvas = undefined;
            }
        } else if (messageType === 'config/init') {
            this.useCanvas = this.config.getItem('useCanvas') === 'true';
        }
    }

    busMessageFilter(messageType: string): boolean {
        return messageType === 'rtc/setting/useCanvas' ||
            messageType === 'config/init';
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        const videoDiv = document.getElementById('videoDiv');

        const observer = new MutationObserver((mutationsList, _observer) => { this.processDivMutationEvent(mutationsList) });
        const config = { attributes: false, childList: true, subtree: true };
        observer.observe(videoDiv, config);
    }

    private processDivMutationEvent(mutationsList: MutationRecord[]) {
     console.log('mutation', mutationsList);
        let canvasChange = 'false';
        for (let i = 0; i < mutationsList.length; i++) {
            if (mutationsList[i].addedNodes.length > 0) {
                if ((<HTMLElement>mutationsList[i].addedNodes[0]).id === 'videoCanvas') {
                    canvasChange = 'added';
                    break;
                }
            } else if (mutationsList[i].removedNodes.length > 0) {
                if ((<HTMLElement>mutationsList[i].removedNodes[0]).id === 'videoCanvas') {
                    canvasChange = 'removed';
                    break;
                }
            }
        }
       console.error('VideocanvasComponent: canvas element ' + canvasChange);
        if (canvasChange === 'added') {
            this.initCanvas();
        }
        return canvasChange;
    }

    private initCanvas() {
        if (this.useCanvas && !this.canvas) {

            this.canvas = <HTMLCanvasElement>document.getElementById('videoCanvas');
            //  console.error('Setting up canvas', this.canvas);
            const context = this.canvas.getContext('2d');
            const img = new Image();
            img.onload = () => {
                context.drawImage(img, 0, 0);
            }
            img.src = './assets/img/noVideo.png';
            this.streamHandler.setCropCanvas(this.canvas);
        }
    }

    public videoPlaying(event: any) {

        // console.log('VideoPlaying:', event, <HTMLCanvasElement>document.getElementById('videoCanvas'));
        this.videoWidth = event.target.videoWidth;
        this.videoHeight = event.target.videoHeight;
        const ratio = this.videoWidth / this.videoHeight;
        //  console.warn('ratio=' + ratio);
        this.defaultCanvasWidth = Math.round(73 * ratio);
        this.canvasElementWidth = this.defaultCanvasWidth;
    }

    public async click() {
        this.canvasTgl();
        this.cropV = !this.cropV;
        this.cropS = false;
        this.showControls = !this.showControls;
        if (!this.showControls) {
            this.child.resetCropping();
        }
        this.controlsEvent.emit(this.showControls);
        this.cropVid();
    }

    private async canvasTgl() {
        this.canvastoggle = !this.canvastoggle;

        if (this.canvastoggle) {
            if (this.streamHandler.masterVideoMute) {

                this.streamHandler.muteVideo(true);
            }
            this.canvasElementWidth = (this.canvas.width / 2);
            this.canvasElementHeight = (this.canvas.height / 2);
        } else {
            if (this.streamHandler.masterVideoMute) {
                setTimeout(() => { this.streamHandler.muteVideo(false) }, 500);
            }

            this.canvasElementWidth = this.defaultCanvasWidth;
            this.canvasElementHeight = 73;
        }
    }

    public tap(event: any) {
        console.log('Tap', event);
        this.click();
    }

    public toggleInfo() {
        this.camInfo = !this.camInfo;
    }

    public hideLocal() {
        this.localV = !this.localV;
    }

    public showInfo(show: boolean) {
        this.camInfo = show;
    }

    public toggleModal() {
        this.cropModal = !this.cropModal;
    }

    public cropVid() {
        this.overlayCropping = !this.overlayCropping;
        this.overlayEvent.emit(this.overlayCropping);
    }

    public startCrop() {
        this.cropS = !this.cropS;
        console.log('Start Crop');
        this.child.enableCropping(true);
    }

    public stopCrop() {
        this.click();
        this.cropV = false;
        //  this.cropS = false;
        console.log('Stop crop');
        this.child.enableCropping(false);
    }

    public activeON() {
        this.camActive = true;
        this.activeEvent.emit(this.camActive);
    }

    // events from cropping directive
    cropEvent(event: CropEvent) {
        console.log('CropEvent called', event);
        if (event.state === CropState.CROPPING) {
            this.streamHandler.setVideoCrop(event.settings);
        } else {
            this.click();
        }
    }
}
