import { UserLoginService} from '../service/cognito.service';
import { KeyService, Key } from '../service/key.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from '../service/translation.service';

@Component({
    selector: 'app-public-landing',
    templateUrl: './landinghome.html',
    styleUrls: ['../../assets/css/custom.css']
})
export class HomeLandingComponent {
    public key = '';

    constructor(private router: Router, private loginSvc: UserLoginService,
        private keySvc: KeyService, public tr: TranslationService) {
        console.log('HomeLandingComponent constructor');
    }

    public login() {
        if (this.key !== '') {
            this.checkKey(this.key);
        } else {
            this.router.navigate(['/home/login'], { replaceUrl: true });
        }
    }
    private checkKey(keyCode: string) {
        this.keySvc.getKey(keyCode).then((key: Key) => {
            if (!key) {
                console.error('Could not retrieve key ' + keyCode);
                alert('Could not validate key or invalid key');
            } else {
                this.loginSvc.setKeyCode(key.keyCode);
                this.loginSvc.anonNickName = key.userName;
                if (key.autoConnect) {
                    this.loginSvc.autoConnect = true;
                }
                setTimeout(() => this.router.navigate(['/home/anonentry']), 1000);
                console.log('Key enterd ' + key.keyCode);
            }
        });
    }

    public signin() {
        this.router.navigate(['/home/register']);
    }
}

@Component({
    selector: 'app-public-home',
    templateUrl: './home.html',
    styleUrls: ['../../assets/css/custom.css']
})

export class HomeComponent {

    constructor() {

    }
}


