
import { Injectable, } from '@angular/core';

@Injectable()
export class SystemBusService {

    private observers = new Set<MessageObserver>();
    constructor() {
    }

    private sendMessageAsync(observer: MessageObserver, message: any, type: string) {
        try {
            observer.onBusMessage(message, type);
        } catch (err) {
            console.error('System-Bus: SendMessageAsync: error ', message, observer, err);
        }
    }

    public emit(message: any, type: string) {
        console.log('SystemBus ', message, type);
        let consumed = false;
        this.observers.forEach((observer) => {
            try {
                if (observer.busMessageFilter(type)) {
                    this.sendMessageAsync(observer, message, type);
                    consumed = true;
                }
            } catch (err) {
                console.error('System-Bus: emit: error ', observer, err);
            }
        });
        if (!consumed) {
            console.log('System-Bus: no listeners for message', message, type);
        }
    }

    public subscribe(observer: MessageObserver) {
        this.observers.add(observer);
    }

    public unSubscribe(observer: MessageObserver) {
        this.observers.delete(observer);
    }

}
export interface MessageObserver {
    onBusMessage(message: any, type: string): void;
    busMessageFilter(messageType: string): boolean;
}

export class InputRequest {
    title: string;
    message: string;
    buttonTxt = 'Submit';
    resolve: Function;
    public clear() {
        this.title = null;
        this.message = null;
        this.buttonTxt = 'Submit';
        this.resolve = null;
    }
}
