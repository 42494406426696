import { UserLoginService } from '../../../service/cognito.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from '../../../service/translation.service';

@Component({
  selector: 'app-anonentry',
  templateUrl: './anonentry.component.html',
  styleUrls: ['../../../../assets/css/custom.css']
})
export class AnonentryComponent implements OnInit {
  cname: string;
  constructor(private router: Router, private userSvc: UserLoginService, public tr: TranslationService) {
  }

  ngOnInit(): void {
    this.cname = this.userSvc.anonNickName;
    if (this.cname) {
      this.onConnect();
    }
  }

  public onConnect() {
    console.log('connect as ' + this.cname);
    this.userSvc.anonNickName = this.cname;
    this.userSvc.anonAuthenticate().then(_ret => this.router.navigate(['/eqCallHome']));
  }

  getURLParameter(name: string) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) ||
      [null, ''])[1].replace(/\+/g, '%20')) || null;
  }

}
