import { Injectable } from '@angular/core';
import { BroadcastSocket, BroadcastListenerCallback, NetworkService, BroadcastListener } from './network.service';
import { LoggedInCallback, UserLoginService, CognitoUtil } from './cognito.service';
import { MessageObserver, SystemBusService } from './system-bus.service';
import { Router } from '@angular/router';
import { EqcallapiService, Group, Member } from './eqcallapi.service';
import { TranslationService } from './translation.service';

export class Message {
    public time: number;
    constructor(public messenger: Messenger, public message: string, public local: boolean) {
        this.time = new Date().getTime();
    }
}

export class MessageListener implements BroadcastListener {
    private messenger: Messenger;
    constructor(public messageService: MessageService) {
    }

    /**
     * Called when we recieve a new message from the NetworkService
     * @param data
     * @param socket
     */
    broadcastOnRecieve(data: any, socket: BroadcastSocket): void {
        if (!this.messenger) {
            this.messenger = this.messageService.getContactByAddress(socket.getDestination());
        }
        if (this.messenger == null) {
            console.error('MessageListener: Error: message contact not in user contacts: ', data);
        } else {
            if (data.type === 'msg') {
                if (data.message === '^TyPiNg') {
                    this.messenger.isTyping(true);
                } else if (data.message === '^NoTtYpInG') {
                    this.messenger.isTyping(false);
                } else {
                    this.messenger.isTyping(false);
                    const message = new Message(this.messenger, data.message, false);
                    this.messenger.addMessage(message);
                    const focused = document.hasFocus();
                    if (!focused) {
                        console.log('messageService: broadcastOnRecieve: sending Notification:', message);
                        navigator.serviceWorker.ready.then(registration => {
                            registration.showNotification('Message', {
                                body: message.message,
                                badge: '/assets/img/iconWhite.png',
                                icon: '/favicon.ico',
                                tag: 'eqcall'
                            });
                        });
                    }
                }
            } else if (data.type === 'update') {
                if (this.messenger.messages.length <= data.messages.length) {
                    this.messenger.messages.length = 0;
                    for (let msg of data.messages) {
                        const message: Message = msg;
                        if (message.local) {
                            message.messenger = this.messageService.localContact;
                        } else {
                            message.messenger = this.messenger;
                        }
                        this.messenger.messages.push(message);
                    }
                } else {
                    console.warn('MessageListener: update: messages not empty');
                }
            } else {
                console.error('MessageListener: invalid message type ', data);
            }
        }
    }
}

export interface Messenger {
    messengerType: string;
    messages: Message[];
    srcAddress: string;
    destAddress: string;
    nickname: string;
    typing: boolean;
    isAnon: boolean;
    awayable: boolean
    keyCode: any;
    unreadMessageCount: number;
    messageSocket: BroadcastSocket;
    isRequest: boolean;
    type: string; // contact || request || requester
    isBlocked: boolean;
    state: string[];
    dateTime: string;
    isTyping(typing: boolean): void;
    sendMessage(message: Message): void;
    addMessage(message: Message): void;
    acceptRequest(accept: boolean): void;
    connectRtc(enableVideo: boolean, joinGroup: boolean): void;
}

export abstract class AbstractMessenger implements Messenger {
    abstract dateTime: string;
    public messengerType = 'messenger';
    public abstract isRequest: boolean;
    public abstract type: string; // contact || request || requester
    public messages: Message[] = [];
    public unreadMessageCount = 0;
    public messageSocket: BroadcastSocket;
    public state: string[];
    public abstract destAddress: string;
    public abstract isAnon: boolean;
    public abstract keyCode: any;
    public typing: boolean;
    public abstract srcAddress: string;
    public abstract nickname: string;
    public abstract isBlocked: boolean;
    public abstract awayable: boolean;

    constructor(private msgSvc: MessageService) {
    }

    abstract connectRtc(enableVideo: boolean, joinGroup: boolean): void

    abstract acceptRequest(accept: boolean): void;

    protected destroy() {
        this.messageSocket = null;
    }

    public setMessages() {
        this.messages = this.msgSvc.getContactMessageArray(this);
    }

    public changeState(state: string) {
        if (state !== 'disconnected') {
            if (this.messageSocket && this.messages.length > 0) { // send old messages to other who just came online
                setTimeout(() => {
                    const msgs: Message[] = [];
                    let m: Message;
                    for (let msg of this.messages) {
                        m = new Message(null, msg.message, !msg.local);
                        m.time = msg.time;
                        msgs.push(m);
                    }
                    const packet = { type: 'update', messages: msgs }
                    this.messageSocket.send(packet);
                    console.log('Contact: ChangState: Sent Messages');
                }, 100 + (Math.random() * 100));
            }
        }
    }

    public addMessage(message: Message) {
        if (this.isRequest || this.type !== 'contact') {
            console.error('Contacts: Contact: addMessage: this contact is a request, action not supported')
            return;
        }
        if (message.message === '^ClEaRmEsSaGeS') {
            this.messages.length = 0;
        } else
            if (message.message !== '^TyPiNg' && message.message !== '^NoTtYpInG') {
                this.messages.push(message);
                this.unreadMessageCount++;
                this.msgSvc.systemBus.emit(this, 'newChatMessage');
            }
        this.notifyObservers('message');
    }

    protected abstract notifyObservers(arg0: string): void;

    public isTyping(typing: boolean) {
        this.typing = typing;
    }

    public sendMessage(message: Message) {
        this.unreadMessageCount = 0;
        if (this.isRequest || this.type !== 'contact') {
            console.error('this contact is a request, action not supported')
            return;
        }

        if (!this.messageSocket) {
            this.messageSocket = this.msgSvc.networkSvc.
                connectBroadcast(this.destAddress, this.srcAddress, 'Chat', new MessageListener(this.msgSvc));
        }

        if (this.messageSocket) {
            this.messageSocket.send({ type: 'msg', message: message.message });
            if (message.message === '^ClEaRmEsSaGeS') {
                this.messages.length = 0;
            } else
                if (message.message !== '^TyPiNg' && message.message !== '^NoTtYpInG') {
                    if (!message.message.startsWith('^CalLMe')) {
                        this.messages.push(message);
                    }
                    if (this.state[0] !== 'connected') {
                        this.msgSvc.api.sendPushMessage(this.destAddress, 'message', message.message, undefined);
                    }
                }
        } else {
            console.error('Contacts: no socket to send mesage');
        }
    }
}

export class GroupMessageListenerCallback implements BroadcastListenerCallback, BroadcastListener {
    constructor(private msgSvc: MessageService) {
    }
    broadcastOnRecieve(data: any, socket: BroadcastSocket): void {
        let groupID = data.groupID;
        let group = this.getGroup(data);
        if (data.type === 'msg') {
            if (group) {
                group.isTyping(false);
                const messenger = this.msgSvc.getContactByAddress(socket.getDestination());
                const message = new Message(messenger, data.message, false);
                group.addMessage(message);
                const focused = document.hasFocus();
                if (!focused) {
                    navigator.serviceWorker.ready.then(registration => {
                           console.log('messageService: GroupMessageListenerCallback: sending Notification:', message);
                        registration.showNotification('Message', {
                            body: message.message,
                            badge: '/assets/img/iconWhite.png',
                            icon: '/favicon.ico',
                            tag: 'eqcall'
                        });
                    });
                }
            } else {
                console.error('Missing group');
            }
        } else if (data.type === 'INIT') {
            if (!group) {
                let addrs = data.message.split(',');
                let messengers: Messenger[] = [];
                for (let i = 0; i < addrs.length; i++) {
                    const messenger = this.msgSvc.getContactByAddress(addrs[i]);
                    if (messenger) {
                        messengers.push(messenger);
                    }
                }
                group = this.msgSvc.createGroup(messengers);
                group.messageSocketMap.set(socket.getDestination(), socket);
                group.groupID = groupID;
            } else {
                console.error('Group not null');
            }
        } else {
            console.error('NotMatched');
        }
    }
    getGroup(data: any): MessengerGroup {
        let groups = this.msgSvc.groups;
        let messengerGroup = null;
        for (let i = 0; i < groups.length; i++) {
            if (groups[i].groupID === data.groupID) {
                messengerGroup = groups[i];
                break;
            }
        }
        return messengerGroup;
    }

    broadcastListenCallback(socket: BroadcastSocket): void {
        socket.addListener(this);
    }
}

export class MessengerGroup implements Messenger {
    dateTime = '-';
    public owner: Member = null;
    isOwner = false;
    state: string[] = [];
    isBlocked = false;
    isRequest = false;
    type = 'contact' // contact || request || requester
    awayable = false;
    isAnon = false;
    keyCode: any;
    unreadMessageCount = 0
    messengerType = 'group';
    messages: Message[] = [];
    srcAddress: string;
    destAddress: string;
    nickname = '';
    typing: boolean;
    messageSocket: BroadcastSocket;
    messageSocketMap = new Map();
    public groupID = Date.now() + '';

    public messengers: Messenger[] = [];

    constructor(messengers: Messenger[], private msgSvc: MessageService) {
        this.messengers = messengers;
        this.srcAddress = msgSvc.localContact.srcAddress;
        this.destAddress = msgSvc.localContact.destAddress;
        this.state.push('connected');
        this.build(true);
    }

    public build(rename: boolean) {
        this.destAddress = '';
        if (rename) { this.nickname = ''; }
        this.messengers.forEach(messenger => {
            if (rename) {
                if (this.nickname.length !== 0) {
                    this.nickname += ', ';
                }
                this.nickname += messenger.nickname;
            }
            if (this.destAddress.length !== 0) {
                this.destAddress += ',';
            }
            this.destAddress += messenger.destAddress
        });

        this.isOwner = this.owner != null && this.msgSvc.localContact.srcAddress === this.owner.address;
    }

    public save() {
        let group = new Group();
        group.id = this.groupID;
        group.name = this.nickname;
        group.owner = this.owner;
        let members: Member[] = [];
        for (let member of this.messengers) {
            members.push(new Member(member.nickname, member.destAddress));
        }
        group.members = members;
        this.msgSvc.api.updateGroup(group);
    }

    public connectRtc(enableVideo: boolean, _joinGroup: boolean): void {
        this.messengers.forEach(messenger => messenger.connectRtc(enableVideo, true));
    }

    public acceptRequest(_accept: boolean): void {
        throw new Error('Method not implemented.');
    }

    addMessenger(messenger: Messenger) {
        this.messengers.push(messenger);
        this.build(false);
        this.msgSvc.systemBus.emit(this, 'MesengerGroup/changed');
    }

    removeMessenger(messenger: Messenger) {
        // eslint-disable-next-line guard-for-in
        for (const idx in this.messengers) {
            let i: number = Number(idx);
            const msgr = this.messengers[i];
            if (msgr.destAddress === messenger.destAddress) {
                this.messengers.splice(i, 1);
                break;
            }
        }
        this.build(false);
        this.msgSvc.systemBus.emit(this, 'MesengerGroup/changed');
    }

    isTyping(_typing: boolean): void {

    }

    public sendMessage(message: Message): void {
        if (message.message === '^ClEaRmEsSaGeS') {
            this.messages.length = 0;
        } else if (message.message !== '^TyPiNg' && message.message !== '^NoTtYpInG' && (!message.message.startsWith('^CalLMe'))) {
            this.messages.push(message);
        }
        this.messengers.forEach(messenger => this.send(message, messenger));
    }

    private send(message: Message, messenger: Messenger) {
        this.unreadMessageCount = 0;
        let messageSocket = this.messageSocketMap.get(messenger.destAddress);
        if (!messageSocket) {
            messageSocket = this.msgSvc.networkSvc.
                connectBroadcast(messenger.destAddress, messenger.srcAddress,
                    'GroupChat', new GroupMessageListenerCallback(this.msgSvc));
            this.messageSocketMap.set(messenger.destAddress, messageSocket);

            messageSocket.send({ groupID: this.groupID, type: 'INIT', message: this.destAddress });
        }
        messageSocket.send({ groupID: this.groupID, type: 'msg', message: message.message });
        if (messenger.state[0] !== 'connected' && message.message !== '^TyPiNg' &&
            message.message !== '^NoTtYpInG' && message.message !== '^ClEaRmEsSaGeS') {
            this.msgSvc.api.sendPushMessage(messenger.destAddress, 'message', message.message, undefined);
        }
    }

    addMessage(message: Message): void {
        if (message.message === '^ClEaRmEsSaGeS') {
            this.messages.length = 0;
        } else
            if (message.message !== '^TyPiNg' && message.message !== '^NoTtYpInG' && !message.message.startsWith('^CalLMe')) {
                this.messages.push(message);
                this.unreadMessageCount++;
                this.msgSvc.systemBus.emit(this, 'newChatMessage');
            }

        if (message.message.startsWith('^CalLMe')) {
            console.error('Implement call me');
        }
    }
}

export class MessageListenerCallback implements BroadcastListenerCallback {

    constructor(public chatService: MessageService) {
    }

    broadcastListenCallback(socket: BroadcastSocket) {
        const messenger = this.chatService.getContactByAddress(socket.getDestination());
        if (messenger == null) {
            console.error('MessageListenerCallback: socketListenCallback: Error: Message for user not in contacts list');
            socket.ignore();
        } else {
            messenger.messageSocket = socket;
            socket.addListener(new MessageListener(this.chatService));
        }
    }
}

@Injectable({
    providedIn: 'root',
})
export class MessageService implements LoggedInCallback, MessageObserver {
    public localContact: Messenger;
    public groups: MessengerGroup[] = [];
    private contactsService: any;
    private contactMessagesMap = new Map<string, Message[]>();

    constructor(public systemBus: SystemBusService, public router: Router, public networkSvc: NetworkService,
        public userService: UserLoginService, public cognitoUtil: CognitoUtil, public api: EqcallapiService,
        public tr: TranslationService) {
        this.systemBus.subscribe(this);
        this.userService.onceAuthenticated(this);
    }

    public createGroup(messengers: Messenger[]): MessengerGroup {
        messengers.push(this.localContact);
        let group = new MessengerGroup(messengers, this);
        group.owner = new Member(this.localContact.nickname, this.localContact.destAddress);
        group.isOwner = true;
        group.nickname = '';
        return group;
    }

    public async updateGroup(group: MessengerGroup) {
        let gp = new Group();
        gp.id = group.groupID;
        gp.name = group.nickname;
        if (group.owner != null) {
            gp.owner = new Member(group.owner.nickname, group.owner.address);
        }
        let members: Member[] = [];
        for (let messenger of group.messengers) { // TODO do not update messengers nicknames
            members.push(new Member(messenger.nickname, messenger.destAddress));
        }
        return this.api.updateGroup(gp);
    }

    public getContactMessageArray(messenger: Messenger): Message[] {
        // console.log('getting messenger array for ' + messenger.destAddress);
        let messages = this.contactMessagesMap.get(messenger.destAddress);
        if (!messages) {

            messages = [];
            this.contactMessagesMap.set(messenger.destAddress, messages)
        }
        return messages;
    };

    isLoggedIn(_message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            this.router.navigate(['/home/login']);
        } else {
            this.networkSvc.listenBroadcast('Chat', new MessageListenerCallback(this));
            this.networkSvc.listenBroadcast('GroupChat', new GroupMessageListenerCallback(this));
        }
    }

    private async getGroups() {
        let grpdata = await this.api.getGroups();
        // console.log('Server group data =', grpdata);
        if (grpdata) {
            for (let grp of grpdata) {
                this.addGrpFromData(grp);
            }
        }
    }

    private async refreshGroups() {
        let grpdata: Group[] = await this.api.getGroups();
        if (!grpdata) {
            this.groups.length = 0;
            return;
        }
        let currentgroups: string[] = [];
        for (let grp of grpdata) {
            currentgroups.push(grp.id);
            let group = this.getLocalGroup(grp.id);
            if (!group) {
                this.getGroup(grp.id);
                continue;
            }
            group.owner = grp.owner;
            group.groupID = grp.id;
            group.nickname = grp.name;
            group.messengers.length = 0;
            if (grp.members) {
                for (let mbr of grp.members) {
                    let address = mbr.address;
                    let messenger = this.getContactByAddress(address);
                    if (!messenger) {
                        if (address === this.localContact.destAddress) {
                            messenger = this.localContact;
                        } else {
                            console.warn('Messenge service: refresh groups: unknown messenger ' + mbr);
                            messenger = this.contactsService.addGroupContact(mbr.nickname, mbr.address);
                        }
                    }
                    group.messengers.push(messenger);
                    group.build(false);
                }
            }
            this.removeMissingGroups(currentgroups);
        }
    }

    private removeMissingGroups(newset: string[]) {
        for (let idx = this.groups.length - 1; idx >= 0; --idx) {
            let keep = newset.some((e) => {
                return this.groups[idx].groupID === e;
            });
            if (!keep) {
                this.groups.splice(idx, 1);
            }
        }
    }

    private async getGroup(groupID: string) {
        let grpData = await this.api.getGroup(groupID);
        this.addGrpFromData(grpData);
    }

    private addGrpFromData(grp: any) {

        let messengers: Messenger[] = [];
        if (grp.members != null) {
            for (let mbr of grp.members) {
                let messenger = this.getContactByAddress(mbr.address);
                if (!messenger) {
                    if (mbr.address === this.localContact.destAddress) {
                        // messengers.push(this.localContact);
                        messenger = this.localContact;
                    } else {
                        messenger = this.contactsService.addGroupContact(mbr.nickname, mbr.address);
                    }
                }
                messengers.push(messenger);
            }
        }
        let group = this.getLocalGroup(grp.id);
        if (group) {
            group.messengers.length = 0;
            messengers.forEach(m => { group.messengers.push(m) });
        } else {
            group = new MessengerGroup(messengers, this)
            group.groupID = grp.id;
            this.groups.push(group);
        }
        group.owner = grp.owner;
        group.nickname = grp.name;
    }

    public getLocalGroup(groupID: string): MessengerGroup {
        let group: MessengerGroup = null;
        for (let grp of this.groups) {
            if (grp.groupID === groupID) {
                group = grp;
                break;
            }
        }
        return group;
    }

    public async deleteGroup(group: MessengerGroup) {
        for (let idx = 0; idx < this.groups.length; idx++) {
            if (this.groups[idx].groupID === group.groupID) {
                this.groups.splice(idx, 1);
                break;
            }
        }
        return this.api.deleteGroup(group.groupID);
    }

    public async leaveGroup(_group: MessengerGroup) {
        console.error('Not Implemented yet');
    }

    onBusMessage(message: any, type: string): void {
        if (type.startsWith('sendChatMessage')) {
            let msgDetail: { messenger: Messenger; message: string; } = message;
            this.sendMessage(msgDetail.message, msgDetail.messenger);
        } else if (type === 'pushMessageClick') {
            // { data: contact address, body: the message , tag: hex timstamp in ms }
            let messenger = this.contactsService.getContactByAddress(message.data);
            if (messenger) {
                const msg = new Message(messenger, message.body, false);
                messenger.addMessage(msg);
            }
        } else if (type === 'contacts/gotlocalContact') {
            this.localContact = message;
        } else if (type === 'contacts/gotContacts') {
            this.contactsService = message;
            this.getGroups();
        } else if (type === 'notice/group') {
            if (message === 'refresh') {
                // TODO refresh changed group, not all
                this.refreshGroups();
            } else {
                console.error('MessageService: recieved unsupported notice');
            }
        }
    }

    busMessageFilter(messageType: string): boolean {
        return (messageType.startsWith('sendChatMessage') ||
            messageType === 'pushMessageClick' ||
            messageType === 'contacts/gotlocalContact' ||
            messageType === 'notice/group' ||
            messageType === 'contacts/gotContacts');
    }

    public getContactByAddress(address: string): Messenger {
        return this.contactsService.getContactByAddress(address);
    }

    public sendMessage(message: string, messenger: Messenger) {
        const msg = new Message(this.localContact, message, true);
        messenger.sendMessage(msg);
    }
}
