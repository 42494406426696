import { EqcallapiService, QueueMsg } from '../../../../service/eqcallapi.service';
import { SystemBusService, MessageObserver } from '../../../../service/system-bus.service';
import { Component, OnDestroy, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Input, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigService } from '../../../../service/config.service';
import { TranslationService } from '../../../../service/translation.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ContactsService, Contact } from '../../../../service/contacts.service';

declare const Stripe: any;

@Component({
    selector: 'app-credit',
    templateUrl: './credit.component.html',
    styleUrls: ['../../../../../assets/css/custom.css']
})
export class CreditComponent implements OnDestroy, AfterViewInit, OnInit, MessageObserver {
    private static stripe: any;
    private static loadingStripe = false;
    private static elements: any;

    public selectedCard = '';
    public selectedValue = '2500';
    public chargedValue = 0;
    public autoRechargeAmount = 2500;
    public autoRechargeLevel = 1000;
    public autoRecharge = false;
    public receiptUrl: SafeUrl
    public iframeUrl: SafeUrl;
    public showIframe = false;
    public errorMessage = '';
    public ccCompleted = false;
    public contact: Contact;

    public paymentMethods: any[] = [];
    private _show = 'NONE';
    public next = 'NONE';
    public showCountryRegion = false;

    public rechargeBTNDisable = false;
    @ViewChild('cardInfo', { static: true }) cardInfo: ElementRef;

    card: any = null;
    cardHandler = this.onChange.bind(this);
    error: string;

    style = {
        base: {
            color: '#bdc3c7',
            lineHeight: '18px',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    };

    private static async loadStripe(stripeKey: string) {

        console.error('loading stripe called');
        if (CreditComponent.stripe) {
            return;
        }
        if (this.loadingStripe) {
            await new Promise(res => setTimeout(res, 250));
            console.error('load stripe waiting');
            await this.loadStripe(stripeKey);
            return;
        }
        console.error('Actually loading stripe');
        this.loadingStripe = true;
        await new Promise(resolve => {
            const scriptElement = document.createElement('script');
            scriptElement.src = 'https://js.stripe.com/v3/'
            scriptElement.onload = resolve;
            document.body.appendChild(scriptElement);
        });
        CreditComponent.stripe = Stripe(stripeKey);
        CreditComponent.elements = CreditComponent.stripe.elements();
    }

    @Input()
    set show(show: string) {
        this._show = show
        this.setShow();
    }

    get show(): string { return this._show; }

    constructor(private cd: ChangeDetectorRef, private api: EqcallapiService,
        private systemBus: SystemBusService,
        private spinner: NgxSpinnerService,
        private config: ConfigService,
        public tr: TranslationService,
        private contactSvc: ContactsService,
        private sanitizer: DomSanitizer
    ) {

    }

    ngOnInit() {
        this.updateCards(true);
        this.systemBus.subscribe(this);
        this.contact = this.contactSvc.localContact[0];
        console.error(this.contact);
        this.showCountryRegion = this.contact.mailingAddress.region ? false : true;
    }
    ngAfterViewInit(): void {
        console.log('view init ', this.contact);
        this.processqueuq();
    }

    async onBusMessage(msg: any, _type: string) {
        console.log('Msg', msg);
        await this.processMsg(msg);
        this.api.delteQueueMessage(msg.QMessageKey);
    }

    private processqueuq() {
        this.api.getQueueMessages().
            then((msgs: QueueMsg[]) => {
                console.log(msgs);
                msgs.forEach(async (msg) => {
                    await this.processMsg(JSON.parse(msg.message));
                    this.api.delteQueueMessage(msg.date);
                });
            });
    }

    busMessageFilter(messageType: string): boolean {
        return messageType === 'notice/PM' || messageType === 'notice/PI'
    }

    private async processMsg(msg: any) {
        console.log('Credit: processMsgs: messages = ', msg);
        this.spinner.hide();
        try {
            console.log(msg);
            if (msg.type === 'PI_SUCCEEDED') {
                let url = msg.url;
                this.chargedValue = Number(msg.subtotal);
                if (this.chargedValue > 0) {
                    this.chargedValue /= 100;
                    this.receiptUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                    console.log('Charge completed ' + url);
                    (<any>$('#receiptUrlModal')).appendTo('body').modal('show');
                    (<any>$('#iframeModal')).appendTo('body').modal('hide');
                    this.showIframe = false;
                    if ('RECHARGE' === msg.TYPE) {
                        this.contactSvc.localContact[0].twilioBalance += this.chargedValue;
                    }
                } else {
                    console.log('Zero charge');
                }
                this.rechargeBTNDisable = false;
            } else if (msg.type === 'PI_FAILED') {
                let code = msg.error_message;
                console.error('Error code:' + code);
                this.errorMessage = code; // ToDo translated message
                (<any>$('#pmfailModal')).appendTo('body').modal('show');
                (<any>$('#iframeModal')).appendTo('body').modal('hide');
                this.showIframe = false;
                this.rechargeBTNDisable = false;
            } else if (msg.type === 'PM_ACTION') {
                console.log('Action reqired');
                let clientSecret = msg.secret;
                if (!CreditComponent.stripe) {
                    await CreditComponent.loadStripe(this.config.stripeKey);
                }
                const { paymentIntent, error } = await CreditComponent.stripe.handleCardAction(clientSecret);

                if (error) {
                    this.errorMessage = error.message; // ToDo translated message
                    (<any>$('#pmfailModal')).appendTo('body').modal('show');
                    (<any>$('#iframeModal')).appendTo('body').modal('hide');
                    this.showIframe = false;
                    this.rechargeBTNDisable = false;
                } else {
                    this.spinner.show();
                }
                console.log(paymentIntent);
            } else if (msg.type === 'PM_CREATED') {
                this.paymentMethods.length = 0
                await this.updateCards(false);
                // this.processqueuq();
            } else if (msg.type === 'PI_METHOD') {
                console.log('Payment Method reqired');
                await this.updateCards(true);
                if (this.paymentMethods.length > 0) {
                    let clientSecret = msg.secret;
                    if (!CreditComponent.stripe) {
                        await CreditComponent.loadStripe(this.config.stripeKey);
                    }
                    let pm = this.selectedCard ? this.selectedCard : this.paymentMethods[0];
                    const { paymentIntent, error } = await CreditComponent.stripe.confirmCardPayment(clientSecret,
                        { payment_method: pm });
                    if (error) {
                        console.error(error);
                        let status = error.payment_intent.status;
                        if (status !== 'succeeded' && status !== 'canceled') {
                            this.errorMessage = error.message; // ToDo translated message
                            (<any>$('#pmfailModal')).appendTo('body').modal('show');
                            (<any>$('#iframeModal')).appendTo('body').modal('hide');
                            this.showIframe = false;
                            this.rechargeBTNDisable = false;
                            throw new Error('PI_METHOD');
                        }
                    } else {
                        this.spinner.show();
                    }
                    console.log(paymentIntent);
                } else {
                    this.showAddCard();
                    this.errorMessage = 'Please add a payment method'; // ToDo translated message
                    (<any>$('#pmfailModal')).appendTo('body').modal('show');
                    throw new Error('No card');
                }
            } else if (msg.type === 'PM_ADDED') {
                this.paymentMethods.length = 0;
                this.updateCards(true).then(() => { setTimeout(() => { this.processqueuq() }, 5000) });
            } else {
                console.log('Unknown type', msg);
            }
        } finally {
            // this.api.delteQueueMessage(m.date);
            // this.messages.delete(m.date);
            console.log('Credit: Done processMsgs: messages = ', msg);
        }
    }

    private setShow() {
        let parts = this._show.split(',');
        let us = parts[0];
        if (parts.length > 1) {
            this.next = this._show.substring(this._show.indexOf(',') + 1);
            console.log('next=', this.next);
        }
        if (us === 'METHODS') {
            (<any>$('#collapseAddCards')).collapse('show');
        } else if (us === 'RECHARGE') {
            (<any>$('#collapseRecharge')).collapse('show');
        }
    }

    clicked() {
        if (!this.card) {
            this.mountCard();
        } else {
            this.unmountCard();
        }
    }

    public mountCard() {
        if (!this.card) {
            console.log('mount');
            if (!CreditComponent.elements) {
                CreditComponent.loadStripe(this.config.stripeKey).then(() => {
                    this.card = CreditComponent.elements.create('card', { style: this.style });
                    this.card.mount(this.cardInfo.nativeElement);
                    this.card.addEventListener('change', this.cardHandler);
                });
            } else {
                this.card = CreditComponent.elements.create('card', { style: this.style });
                this.card.mount(this.cardInfo.nativeElement);
                this.card.addEventListener('change', this.cardHandler);
            }
        }
    }

    public unmountCard() {
        if (this.card) {
            console.log('Unmount');
            this.card.removeEventListener('change', this.cardHandler);
            this.card.destroy();
            this.card = null;
        }
    }

    ngOnDestroy() {
        this.systemBus.unSubscribe(this);
        this.paymentMethods.length = 0;
        this.unmountCard();
    }

    onChange(event: any) {
        console.log('onchange', event);
        if (event.error) {
            this.error = event.error.message;
        } else {
            this.error = null;
        }
        this.ccCompleted = event.complete;
        this.cd.detectChanges();
    }

    async onSubmit(_form: NgForm) {
        this.spinner.show();
        this.api.addCreditCard({ id: 'NEEDCS' }).then((sec) => {
            console.log(sec);
            CreditComponent.stripe.handleCardSetup(
                sec.data, this.card, {
                payment_method_data: {
                    billing_details: { name: (<any>$('#CardHolderName')).value }
                }
            }
            ).then((result: any) => {
                if (result.error) {
                    let error = result.error;
                    // Display error.message in your UI.
                    console.error('Something is wrong:', error);
                    this.error = error.message;
                    this.sendMessage(error.message);
                    this.spinner.hide();
                } else {
                    this.cardAdded();
                }
            });
        }).catch((error) => {
            console.error('Something is wrong:', error);
            this.error = error.data;
            this.sendMessage(error.data);
            this.spinner.hide();
        });
    }

    private async cardAdded() {
        console.log('CC added');
        this.showCountryRegion = false;
        this.error = 'Successfull';
        this.card.clear();
        // this.paymentMethods.length = 0;
        this.sendMessage('Card Added');
        // await this.updateCards(true);
        this.closeAddCard();
        // this.showRecharge();
        this.spinner.hide();
    }

    async updateCards(showSpinner: boolean) {
        await CreditComponent.loadStripe(this.config.stripeKey);
        console.log('stripe loaded');
        if (this.paymentMethods.length === 0) {
            // this.updateAutoRecharge();
            if (showSpinner) {
                this.spinner.show();
            }
            await this.api.getCreditCards().then((paymentMethods: any) => {
                console.log(paymentMethods);
                this.paymentMethods.length = 0;
                if (paymentMethods.data) {
                    if (paymentMethods.data.length > 0) {
                        for (let pm of paymentMethods.data) {
                            console.log(pm);
                            this.paymentMethods.push(pm)
                            if (pm.metadata.Default === 'true') {
                                this.selectedCard = pm.id;
                                console.log('Set selected card ', this.selectedCard);
                            }
                        }
                        this.showRecharge();
                    } else {
                        this.showAddCard();
                    }
                } else {
                    this.showAddCard();
                }
                this.spinner.hide();
            }).catch(err => {
                if (err.data) {
                    this.sendMessage(err.data);
                }
                this.spinner.hide();
            });
        }
    }

    private showAddCard() {
        console.log('showing add card');
        let addcardsEL = document.querySelector('#collapseAddCards');
        let exp = addcardsEL.getAttribute('aria-expanded');
        console.log('exp= ' + exp);
        if (exp !== 'true') {
            (<any>$(addcardsEL)).collapse('show');
        }
        addcardsEL = document.querySelector('#collapseAddNewCard');
        exp = addcardsEL.getAttribute('aria-expanded');
        console.log('exp= ' + exp);
        if (exp !== 'true') {
            (<any>$(addcardsEL)).collapse('show');
        }
        if (!this.card) {
            this.mountCard();
        }
    }

    private closeAddCard() {
        setTimeout(() => {
            console.log('Closing add Cards')
            let addcardsEL = document.querySelector('#collapseAddNewCard');
            (<any>$(addcardsEL)).collapse('hide');
            if (this.card) {
                console.log('unmounting card');
                this.unmountCard();
            }
        }, 1000);
    }

    private showRecharge() {
        console.log('showing recharge');
        this.updateAutoRecharge();
        let rechargeEL = document.querySelector('#collapseRecharge');
        let exp = rechargeEL.getAttribute('aria-expanded');
        console.log('exp= ' + exp);
        if (exp !== 'true') {
            (<any>$(rechargeEL)).collapse('show');
        }
    }

    public save_auto_recharge() {
        this.spinner.show();
        let level = this.autoRechargeLevel;
        let amount = this.autoRechargeAmount;
        if (!this.autoRecharge) {
            level = 0;
            amount = 0;
        }
        this.api.setAutoRecharge(amount, level).
            then(_ret => { this.sendMessage('AutoRecharge saved'); this.spinner.hide() }).
            catch(err => {
                this.sendMessage('Error: AutoRecharge failed:' + err); this.spinner.hide()
            });
        console.log('AutoRecharge ' + amount + ' ' + level + ' ' + this.autoRecharge);
    }

    public async updateAutoRecharge() {
        await this.api.getAutoRecharge().then(ret => {
            console.log('AutoRecharge=', ret);
            if (ret.data) {
                if (ret.data.amount !== 0) {
                    this.autoRechargeAmount = ret.data.amount;
                    this.autoRechargeLevel = ret.data.level;
                    this.autoRecharge = true;
                } else {
                    this.autoRecharge = false;
                }
            } else {
                this.autoRecharge = false;
            }
        }).catch(err => { console.error('Error getting recharge', err) });
    }

    public setDefaultCard(card: any) {
        console.log('setting default card', card);
        this.spinner.show();
        this.api.setDefaultCard(card.id).then(() => {
            this.paymentMethods.length = 0;
            this.updateCards(true);
            this.spinner.hide();
        }).catch((error: any) => {
            this.paymentMethods.length = 0;
            this.updateCards(true);
            this.spinner.hide();
            console.error(error);
            this.sendMessage('Something is wrong setting default card:' + error.data);
        });
    }

    public removeCard(card: any) {
        this.spinner.show();
        console.log(card);
        this.api.deleteCard(card.id).then(() => {
            this.paymentMethods.length = 0;
            this.updateCards(true);
            this.spinner.hide();
        }).catch(err => {
            this.paymentMethods.length = 0;
            this.updateCards(true);
            this.spinner.hide();
            this.sendMessage('Error deleting card: ' + err.data);
        });
    }

    public recharge() {
        this.rechargeBTNDisable = true;
        this.spinner.show();
        console.log('value=', this.selectedValue);
        console.log('pm=', this.selectedCard);
        this.api.rechargeAccount(this.selectedCard, this.selectedValue).then((data) => {
            console.error(data);
            this.chargedValue = parseInt(this.selectedValue, 10) / 100;
            // this.receiptUrl = data.data;
            this.ShowPI(data.data);
            //   (<any>$('#receiptUrlModal')).appendTo('body').modal('show');

            //  this.contactSvc.updateLocalContact();
        }).catch((error: any) => {
            this.spinner.hide();
            this.sendMessage('Error recharging account: ' + error.data);
            this.rechargeBTNDisable = false;
        });
    }

    private async ShowPI(pisecret: any) {
        console.log('pisecret=', pisecret);

        if (pisecret && pisecret.type === 'three_d_secure_redirect') {
            this.spinner.hide();
            this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pisecret.stripe_js);
            console.log(this.iframeUrl);
            // this.receiptUrl = pisecret.stripe_js;
            this.showIframe = true;
            (<any>$('#iframeModal')).appendTo('body').modal('show');
        }
    }

    private sendMessage(message: string) {
        console.log('sending message' + message);
        const mesg = {
            type: 'info',
            message: message,
            timeOut: 5,
        };
        this.systemBus.emit(mesg, 'warning');
    }

    public countryChange(country: string) {
        console.log('CC: ', country);
        this.contact.mailingAddress.country = country;
    }

    public regionChange(region: string) {
        console.log('CC: ', region);
        this.contact.mailingAddress.region = region;
        this.api.updateMailingAddress(JSON.stringify(this.contact.mailingAddress)).
            then(() => { $('body').css('cursor', 'default') }).catch(err => {
                $('body').css('cursor', 'default');
                this.sendMessage('Error updating address: ' + err.data);
            });
    }
}
