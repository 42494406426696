import { Component, OnInit, Input } from '@angular/core';
import { Messenger, MessengerGroup } from '../../../../../../service/message.service';

@Component({
    selector: 'app-group-members-element',
    templateUrl: './group-members-element.component.html',
    styleUrls: ['../../../../../../../assets/css/custom.css']
})
export class GroupMembersElementComponent implements OnInit {
    @Input() messenger: Messenger;
    @Input() group: MessengerGroup;
    @Input() canEdit: boolean;
    public name = '';
    public isSelected = false;
    constructor() { }

    ngOnInit() {
        if (this.messenger.srcAddress === this.messenger.destAddress) {
            this.name = 'Me'
        } else {
            this.name = this.messenger.nickname;
        }
        this.isInGroup();
    }

    private isInGroup() {
        this.isSelected = false;
        for (let msngr of this.group.messengers) {
            if (msngr.destAddress === this.messenger.destAddress) {
                this.isSelected = true;
                break;
            }
        }
    }

    public selected(_event: any) {
        console.error('Selected');
        if (this.isSelected) {
            this.group.addMessenger(this.messenger);
        } else {
            this.group.removeMessenger(this.messenger);
        }
    }
}
