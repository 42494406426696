import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timer'
})
export class TimerPipe implements PipeTransform {

  transform(value: number): string {
    value = value / 1000;
    const hours: number = Math.floor(value / 3600)
    const minutes: number = Math.floor((value - (hours * 3600)) / 60);
    const seconds: number = Math.floor(value - ((hours * 3600) + (minutes * 60)));
    const hoursString = hours ? hours.toString().padStart(2, '0') + ':' : '';
    const minuteString = minutes ? minutes.toString().padStart(2, '0') + ':' : '';
    return hoursString +
      minuteString +
      seconds.toString().padStart(2, '0');
  }
}
