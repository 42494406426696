import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserLoginService, LoggedInCallback, CognitoUtil } from '../../../service/cognito.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslationService } from '../../../service/translation.service';

@Component({
    selector: 'app-criticall',
    templateUrl: './login.html',
    styleUrls: ['../../../../assets/css/custom.css']

})
export class LoginComponent implements LoggedInCallback, OnInit {
    userID: string;
    password: string;
    errorMessage: string;

    constructor(public router: Router,
        public userService: UserLoginService,
        public cognitoUtil: CognitoUtil,
        public spinner: NgxSpinnerService,
        public tr: TranslationService) {
        console.log('LoginComponent constructor');
    }

    ngOnInit() {
        this.spinner.hide();
        this.errorMessage = null;
        console.log('Checking if the user is already authenticated. If so, then redirect to the secure site');
        this.userService.checkSavedAuthenticated(this);
    }

    onLogin() {
        this.spinner.show();
        if (!this.userID  && !this.password) {
            this.userService.anonAuthenticate().then(_result => this.sucess()).catch(err => this.fail(err));
            this.errorMessage = null;
            return;
        }
        this.errorMessage = null;
        this.userService.authenticate(this.userID.toLowerCase(), this.password).then(_result => this.sucess()).catch(err => this.fail(err));
    }

    private sucess() {
        console.log('Login: sucess');
        this.spinner.hide();
        this.router.navigate(['/eqCallHome'], { replaceUrl: true });
    }
    private fail(message: any) {
        console.warn('Login: fail', message);
        this.spinner.hide();
        this.errorMessage = message.message;
        console.log('result: ' + this.errorMessage);
        if (this.errorMessage === 'User is not confirmed.') {
            console.log('redirecting');
            this.router.navigate(['/home/confirmRegistration', this.userID.toLowerCase()]);
        }
    }

    isLoggedIn(_message: string, isLoggedIn: boolean) {
        if (isLoggedIn) {
            if (this.userService.getKeyCode() != null) {
                this.router.navigate(['/home/anonentry']);
            } else {
                this.router.navigate(['/eqCallHome'], { replaceUrl: true });
            }
        } else {
            console.log('is not logged in');
        }
    }

    public googLogin() {
        this.userService.CognitoUIlogin();
    }
}
