import {Component} from '@angular/core';

@Component({
  selector: 'app-home-landing',
  templateUrl: './eqCallHome.html',
  styleUrls: ['../../../assets/css/custom.css']
})
export class EqCallHomeComponent {

}
